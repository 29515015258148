/*labels file for defining label information for presentation view*/
export const URL = "http://logintest.agile24x7.com/" //testing
//  export const URL = "https://www.agile24x7.com/" //production
 export const BaseUrl = "http://preprod.agile24x7.com"//testing
//  export const BaseUrl = "https:///backend.agile24x7.com" //Production
/*Projects*/
export const PROJECT = "Epic"
export const PROJECTS = "Epics"
export const PROJECT_DESCRIPTION = "Description"
export const PROJECT_REQUESTED_BY = "Requested by"
export const PROJECT_APPROVED_BY = "Approved By"
export const PROJECT_APPROVAL_STATUS = "Approval Status"
export const PROJECT_STATUS = "Epic Status"
export const MANAGEPROJECTS = "Epics"
export const NEWPROJECT = "Add Epic"
export const PROJECTNO = "Epic Id"
export const PROJECTNAME = "Epic Name"
export const PROJECT_SUCCESS = "Epic Added Successfully"
export const PROJECT_MODIFY = "Epic Modified Successfully"
export const PROJECT_APPROVE = "Epic Approved Successfully"
export const PROJECT_REJECT = "Epic Is Rejected"
export const PROJECT_DELETE = "Epic Deleted"
export const PROJECT_VERIFY ="Epic Verified Successfully"
export const COMPLETED_PROJECTS = "Completed Epics"
export const PROJECT_APPROVED_DATE ="Approved Date"
export const PROJECT_COMPLETED_DATE = "Completed Date"
export const PROJECT_REOPEN = "Reopen"


/*Modules*/
export const MODULES="Sprints";
export const MODULE ="Sprints";
export const NEWMODULE = "Add Sprint"
export const VIEWMODULES = "View Sprint"
export const ADDMODULE = "Add Sprint"
export const MODULENAME = "Sprint Name"
export const MODIFYMODULE = "Modify Sprint"
export const MODULE_ADD = "Sprint Added Successfully"
export const MODULE_MODIFY = "Sprint Modified Successfully"
export const MODULE_DELETE = "Sprint Deleted Successfully"


/*Maintasks */
export const MAINTASKS = "User Stories";
export const MAINTASK ="UserStory"
export const NEWMAINTASK ="Add User Story";
export const VIEWMAINTASKS ="View User Story";
export const MAINTASKID = "User Story Id"
export const MAINTASKNAME = "User Story Name"
export const MAINTASK_DESCRIPTION = "Description"
export const MAINTASKPROGRESS ="User Story Progress"
export const MODIFYMAINTASK = "Modify User Story"
export const MAINTASK_ADD = "User Story added successfully"
export const MAINTASK_MODIFY = "User Story Modified Successfully"
export const MAINTASK_REASSIGN = "User Story Reassigned Successfully"
export const MAINTASK_DELETE = "User Story Delete Successfully"
export const MAINTASK_VERIFY = "User Story Verified Successfully"
export const MAINTASK_VERIFY_FAIL = "You can not verify User Story untill tasks are verified"


/*Subtasks*/
export const SUBTASKS = "Tasks";
export const SUBTASK ="Task"
export const PENDING_SUBTASKS = "Backlog"
export const SUBTASKID = "Task Id"
export const SUBTASKTITLE = "Task Name"
export const SUBTASK_DESCRIPTION = "Task Description"
export const SUBTASK_PROGRESS = "Task Progress"
export const NEWSUBTASK = "Add Task";
export const VIEWSUBTASKS = "View Tasks";
export const MODIFYSUBTASK = "Modify Task"
export const SUBTASK_ADD = "Task Added Successfully"
export const SUBTASK_MODIFY = "Task Modified Successfully"
export const SUBTASK_DELETE = "Task Deleted Successfully"
export const SUBTASK_VERIFY = "Task Verified Successfully"
export const SUBTASK_ACTIVE = "Task Activated successfully"
// export const SUBTASK_VERIFY_FAIL = "you can not verify module untill subtasks are verified"



/*Roadblocks*/
export const ROADBLOCK = "Blocked"
export const ROADBLOCKS = "Roadblocks"
export const USERS_ROADBLOCKS = "Users Road Blocks"
export const TO_BE_ASSIGN = "To Be Assign"
export const ASSIGNED = "Assigned"
export const ROADBLOCK_DESCRIPTION = "Roadblock Description"
export const ASSIGNED_TO = "Assigned To"
export const ASSIGNED_BY = "Assigned By"
export const ASSIGNED_DATE = "Assigned Date"
export const TARGET_DATE = "Target Date"
export const ROADBLOCK_DATE = "Roadblock Date"
export const REQUESTED_BY = "Raised By"
export const ROADBLOCK_PERCENTAGE = "Roadblock Percentage"
export const ROADBLOCK_STATUS = "Status"
export const ROADBLOCK_LIST = "Roadblock List"
export const PENDING_ROADBLOCKS = "Pending Roadblocks"
export const ASSIGN_ROADBLOCK = "Assign RoadBlock"
export const MODIFY_ROADBLOCK = "Modify RoadBlock"
export const PRIORITY = "Priority"
export const SEVERITY = "Severity"


/*Common Lables*/
export const RELEASE_OWNER = "RO"
export const ARCHIVE="Archive"
export const EDIT = "Modify"
export const VIEW_DETAILS = "View Details"
export const ADD_TO_SPRINT = "Add to Sprint"
export const ADD_TO_EPIC = "Add to Epic"


export const REASSIGN = "Reassign"
export const DELETE = "Delete"
export const APPROVE = "Approve"
export const VERIFY = "Verify"
export const REJECT = "Reject"
export const ACTION = "Action"
export const ESTIMATEDTIME = "Estimation Time"
export const DAYS = "Days"
export const HOURS = "Hours"
export const CHAT ="Chat"
export const STATUS ="Status"
export const ASSIGNED_ON = "Assigned On"
export const CREATED_BY = "Created By"
export const CREATED_DATE = "Created Date"
export const TIME_LEFT = "Time Left"

/*Employees */
export const EMPLOYEES = "Agile Squad"

/*Teams and Designation*/
export const TEAM = "Department"
export const DESIGNATION = "Title"
export const TEAM_NAME = "Department Name"
export const DESIGNATION_NAME = "Title Name"
