/* 
filename:network.js
purpose:Api calls for backlog page
developers:G Naveen Kumar[N.K],Saty Siddha[S.S]

*/
import { isLoaded, isLoading, pendingTasks, sprints, unAssignedTasks, projects } from "./actions";
import Alert from "../../Common/Alert";
import API from "../../Common/Network/API";
import { allMessages, currentSptintTasks, activeSprint, involvedEmployees, modules } from "./actions";
import Moment from 'moment';


export async function getAllTaskMessages(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("mainTaskChat.php", {
      corp_code: getUser.corp,
      action: "getAllTaskMessages",
    }, {}, false);
    if (response.status === 'True') {
      dispatch(allMessages(response.data));
    } else {
      dispatch(allMessages([]));
    }
  } catch (error) {
    Alert('error', error.message)
  }
  dispatch(isLoaded());
}
//for getting involved scrum team in active sprint
export async function getInvolvedEmployees(dispatch, getUser, moduleId) {
  dispatch(isLoading());
  try {
    var response = await API.post("getModules.php", {
      "crop": getUser.corp,
      "moduleId": moduleId,
      "action": "getInvolvedEmployees"
    }, {}, false);
    if (response.status === 'True') {
      dispatch(involvedEmployees(response.data))
      // console.log(response.data)
    }
    else {
      dispatch(involvedEmployees([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}
//getting current and future modules
export async function getModules(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("getModules.php", {
      "crop": getUser.corp,
      "userType": getUser.role,
      "empId": getUser.empId,
      "action": "getModules"
    }, {}, false);
    // console.log(response)
    if (response.status === 'True') {
      dispatch(modules(response.data))
      // getToDo(dispatch, getUser);
    }
    else {
      dispatch(modules([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}

//for getting active sprint id
export async function getActiveSprints(dispatch, getUser) {
  dispatch(isLoading());
  try {
    // Current Active Sprint Name with epic by -->GNK -->01 on version 1.0.4 start
    // var response = await API.post("getSprints.php", {
    //   "crop": getUser.corp,
    //   "userType": getUser.role,
    //   "empId": getUser.empId,
    //   "action": "getActiveSprint"
    // }, {}, false);
    // Current Active Sprint Name with epic by -->GNK -->01 on version 1.0.4 end

    // Current Active Sprint witout epic by -->SS -->01 on version 1.0.6 start
    var response = await API.post("getUpdateSprint.php", {
      "crop": getUser.corp,
      "action": "get_sprints"
    }, {}, false);
    // Current Active Sprint witout epic by -->SS -->01 on version 1.0.6 end
    if (response.status === 'True') {
      const moduleId = response.data[0].moduleId
      dispatch(activeSprint(response.data[0]))
      getInvolvedEmployees(dispatch, getUser, moduleId);
      getCurrentSprint(dispatch, getUser, moduleId)
    }
    else {
      // getUnactiveSprintTasks(dispatch, getUser)
      dispatch(activeSprint([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}

//for getting current sprint user stories

export async function getCurrentSprint(dispatch, getUser, sprintId) {
  dispatch(isLoading());
  try {
    var response = await API.post("getManageMaintasks.php", {
      "crop": getUser.corp,
      "userType": getUser.role,
      "empId": getUser.empId,
      "sprintId": sprintId,
      "action": "currentsprint"
    }, {}, false);
    if (response.status === 'true') {

      dispatch(currentSptintTasks(response.data))
    }
    else {
      dispatch(currentSptintTasks([]))
    }
  } catch (error) {
    Alert('error', error.message);
    dispatch(isLoaded());
  }
  dispatch(isLoaded());

}

//for getting recent sprint userstories
export async function getPending(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("getManageMaintasks.php", {
      "crop": getUser.corp,
      "userType": getUser.role,
      "empId": getUser.empId,
      "action": "pending"
    }, {}, false);
    if (response.status === 'true') {
      dispatch(pendingTasks(response.data))
    }
    else {
      dispatch(pendingTasks([]))
    }
  } catch (error) {
    Alert('error', error.message);
    dispatch(isLoaded());
  }
}
//for getting unassigned user stories
export async function getUnassigned(dispatch, getUser) {

  dispatch(isLoading());
  try {
    var response = await API.post("getManageMaintasks.php", {
      "crop": getUser.corp,
    }, {}, false);
    if (response.status === 'true') {
      dispatch(unAssignedTasks(response.data))
    }
    else {
      dispatch(unAssignedTasks([]))

    }
  } catch (error) {
    Alert('error', error.message);
    dispatch(isLoaded());
  }
}


//For Completed MainTasks
// export async function getCompleted(dispatch, getUser, pending) {
//   dispatch(isLoading());
//   try {
//     var response = await API.post("getManageMaintaskstest.php", {
//       "crop": getUser.corp,
//       "userType": getUser.role,
//       "empId": getUser.empId,
//       action: "completed",
//     }, {}, false);
//     if (response.status === 'true') {
//       getManageTasks(dispatch, pending, response.data) //Call MainTask List
//       // dispatch(completedTasks(response.data))
//     }
//     else {
//       getManageTasks(dispatch, pending, [])
//     }
//   } catch (error) {
//     Alert('error', error.message);
//     dispatch(isLoaded());
//   }
// }
//For ManageTasks
// export async function getManageTasks(dispatch, pending, unassigned) {
//   dispatch(isLoading());
//   try {
//     dispatch(manageTasks(pending.concat(unassigned)))
//   } catch (error) {
//     Alert('error', error.message);
//   }
//   dispatch(isLoaded());
// }
export async function getProjects(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("getIdeas.php", {
      "crop": getUser.corp,
      "userType": getUser.role,
      // "empId": getUser.empId,
      "action": "getIdeas"
    }, {}, false);
    if (response.status === 'True') {
      dispatch(projects(response.data))
    }
    else {
      dispatch(projects([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}

// to get active sprints in add to sprint model class
export async function getSprints(dispatch, getUser) {

  dispatch(isLoading());
  try {
    var response = await API.post("getUpdateSprint.php", {
      "crop": getUser.corp,
      "action": "get_sprints"
    }, {}, false);
    if (response.status === 'True') {
      dispatch(sprints(response.data))
      // getToDo(dispatch, getUser);
    }
    else {
      dispatch(sprints([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}

//Add userstory to sprint
export async function addSprint(sprintTargetDate,targetDate,taskId, state, dispatch, getUser, handleClose) {
  dispatch(isLoading());
  if (targetDate < Moment(Date.now()).format("YYYY-MM-DD")) {
    Alert('warning', 'Select valid date');

  }
  else if(targetDate > sprintTargetDate){
    Alert('warning', 'Target Date shouild not exceed Sprint target date');
  }

 else if (state.sprintSelected !== "") {
    try {
      var response = await API.post("getUpdateSprint.php", {
        crop: getUser.corp,
        action: "sprint_update",
        sprintId: state.sprintSelected.value,
        updatedBy: getUser.empId,
        userStoryId: taskId,
        targetDate: targetDate

      }, {}, false);
      if (response.status === 'True') {
        Alert("success", "UPDATED");
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  } else {
    Alert("warning", "Something went wrong")
  }
}
//adding userstory to kanban
export async function addToKanban(sprintTargetDate,targetDate, taskId, sprintId, state, dispatch, getUser, handleClose) {
  dispatch(isLoading());

  if (targetDate < Moment(Date.now()).format("YYYY-MM-DD")) {
    Alert('warning', 'Select valid date');

  }
  else if(targetDate > sprintTargetDate){
    Alert('warning', 'Target Date shouild not exceed Sprint target date');
  }
  else if (targetDate !== "") {

    try {
      var response = await API.post("getUpdateSprint.php", {
        crop: getUser.corp,
        action: "sprint_update",
        sprintId: sprintId,
        userStoryId: taskId,
        targetDate: targetDate
      }, {}, false);
      if (response.status === 'True') {
        Alert("success", "UPDATED");
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  }
  else {
    Alert('warning', 'Please update target date');
  }
}
//remove from sprint

export async function removeFromSprint(taskId, sprintId, state, dispatch, getUser, handleClose) {
  dispatch(isLoading());

  try {
    var response = await API.post("getUpdateSprint.php", {
      crop: getUser.corp,
      action: "remove_from_sprint",
      sprintId: sprintId,
      userStoryId: taskId
    }, {}, false);
    if (response.status === 'True') {
      Alert("success", "UPDATED");
    }
  } catch (error) {
    Alert('error', error.message);
    dispatch(isLoaded());
  }
  handleClose()
}
export async function addEpic(taskId, state, dispatch, getUser, handleClose) {
  dispatch(isLoading());
  if (state.sprintSelected !== "") {
    try {
      var response = await API.post("getUpdateSprint.php", {
        crop: getUser.corp,
        action: "epic_update",
        epicId: state.epicSelected.value,
        updatedBy: getUser.empId,
        userStoryId: taskId
      }, {}, false);
      if (response.status === 'True') {
        Alert("success", "UPDATED");
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  } else {
    Alert("warning", "Something went wrong")
  }
}

export async function setDoing(dispatch, getUser, subtaskId, mainTaskId, handleClose) {
  console.log(subtaskId)
  dispatch(isLoading());
  try { //manageSubtasks.php
    var response = await API.post("getSubtasks.php", {
      crop: getUser.corp,
      action: 'setSubtaskDoing',
      task_id: subtaskId,
      maintaskId: mainTaskId
    }, {}, false);
    // console.log(response)
    if (response.status === 'True') {

      Alert("success", 'status changed')
      handleClose()
    } else if (response.status === "false") {

      handleClose()
      Alert("warning", response.message)
      console.log(response.status)
    }
  } catch (error) {
    Alert('error', error.message);
    handleClose()
  }
  dispatch(isLoaded());
}
export async function setDone(dispatch, getUser, subtaskId, mainTaskId, handleClose) {
  dispatch(isLoading());
  try { //manageSubtasks.php
    var response = await API.post("getSubtasks.php", {
      crop: getUser.corp,
      action: 'setSubtaskDone',
      task_id: subtaskId,
      maintaskId: mainTaskId
    }, {}, false);
    // console.log(response)
    if (response.status === 'True') {
      Alert("success", 'status changed')
      handleClose()
    } else if (response.status === "false") {
      handleClose()
      Alert("warning", response.message)
    }
  } catch (error) {
    Alert('error', error.message);
    handleClose()
  }
  dispatch(isLoaded());
}