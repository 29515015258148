import { isLoaded, isLoading, manageTasks, users, allMessages, projects } from "./actions";
import Alert from "../../Common/Alert";
import API from "../../Common/Network/API";
import { MAINTASK_ADD, MAINTASK_MODIFY, MAINTASK_DELETE, MAINTASK_REASSIGN } from "../Headers";
import Moment from 'moment';


export async function getProjects(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("getIdeas.php", {
      "crop": getUser.corp,
      "userType": getUser.role,
      "empId": getUser.empId,
      "action": "getIdeas"
    }, {}, false);
    if (response.status === 'True') {
      dispatch(projects(response.data))
    }
    else {
      dispatch(projects([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}
export async function getAllTaskMessages(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("mainTaskChat.php", {
      corp_code: getUser.corp,
      action: "getAllTaskMessages",
    }, {}, false);
    if (response.status === 'True') {
      dispatch(allMessages(response.data));
    } else {
      dispatch(allMessages([]));
    }
  } catch (error) {
    Alert('error', error.message)
  }
  dispatch(isLoaded());
}

//For Getting Users List(Team)
export async function getUsers(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("getEmployees.php", {
      "crop": getUser.corp,
    }, {}, false);
    if (response.status === 'True') {
      dispatch(users(response.data))
    }
    else {
    }
  } catch (error) {
    Alert('error', error.message);
    dispatch(isLoaded());
  }
}

//For Get MainTask List From Modules
export async function getModuleMainTasks(dispatch, getUser, moduleId) {
  dispatch(isLoading());
  try {
    var response = await API.post("getModuleMaintasks.php", {
      crop: getUser.corp,
      moduleId: moduleId,
    }, {}, false);
    if (response.status === 'True') {
      dispatch(manageTasks(response.data))
    }
    else {
      dispatch(manageTasks([]))
    }
  } catch (error) {
    Alert('error', error.message);
    dispatch(isLoaded());
  }
  dispatch(isLoaded());
}
//For Add MainTask
export async function addMainTask(state, dispatch, getUser, ideaId, moduleId, handleClose) {
  dispatch(isLoading());
  if (state.userSelected === "") {
    Alert("warning", "Please select squad member ")
    dispatch(isLoaded());
  }
  else if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value.trim() !== "") {
    try {
      var response = await API.post("manageMaintasks.php", {
        crop: getUser.corp,
        action: "add",
        title: state.taskTitle.value,
        description: state.taskDescription.value,
        module_id: moduleId,
        added_to: state.userSelected,
        added_by: getUser.empId,
        acceptanceCriteria: state.acceptanceCriteria.value,
        storyPoints: state.storySelected,
        priorityLevel: state.prioritySelected,
        idea_id: state.epicSelected,
        // targetDate:state.targetDate.value,

      }, {}, false);
      if (response.status === 'True') {
        // dispatch(getModuleMainTasks(dispatch, getUser, moduleId))
        Alert("success", MAINTASK_ADD);
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  }
 
  else {
    Alert("warning", "Please enter required fields ")
    dispatch(isLoaded());
  }
}
//For Modify MainTask
export async function modifyMainTask(sprintTargetDate,state, dispatch, getUser, ideaId, moduleId, handleClose) {
  dispatch(isLoading());
  if (state.targetDate.value < Moment(Date.now()).format("YYYY-MM-DD")) {
    dispatch(isLoaded());
    Alert('error', 'Select valid date');

  }
  else if(state.targetDate.value  > sprintTargetDate){
    dispatch(isLoaded());
    Alert('error', 'Target Date shouild not exceed Sprint target date');
  }
  else if (state.taskTitle.value !== "" && state.taskDescription.value !== "" && state.acceptanceCriteria.value !== "") {
    try {
      var response = await API.post("manageMaintasks.php", {
        crop: getUser.corp,
        action: "modify",
        title: state.taskTitle.value,
        description: state.taskDescription.value,
        acceptanceCriteria: state.acceptanceCriteria.value,
        targetDate: state.targetDate.value,
        module_id: state.moduleId.value,
        idea_id: state.epicSelected === "" ? state.ideaId.value : state.epicSelected,
        added_to: state.userSelected === "" ? state.id.value : state.userSelected,
        added_by: getUser.empId,
        mainTaskId: state.taskId.value,
        storyPoints: state.storySelected === "" ? state.storyPoints.value : state.storySelected,
        priorityLevel: state.prioritySelected === "" ? state.priorityLevel.value : state.prioritySelected


      }, {}, false);
      if (response.status === 'True') {
        dispatch(getModuleMainTasks(dispatch, getUser, moduleId))
        Alert("success", MAINTASK_MODIFY);
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  } else {
    Alert("warning", "please give all the details")
  }
}
//For Modify MainTask
export async function reassignMainTask(state, dispatch, getUser, ideaId, moduleId, handleClose) {
  dispatch(isLoading());
  if (state.taskTitle.value !== "" && state.taskDescription.value !== "" && state.acceptanceCriteria.value !== "") {
    try {
      var response = await API.post("manageMaintasks.php", {
        crop: getUser.corp,
        action: "reassign",
        title: state.taskTitle.value,
        description: state.taskDescription.value,
        acceptanceCriteria: state.acceptanceCriteria.value,
        module_id: state.moduleId.value,
        idea_id: state.epicSelected === "" ? state.ideaId.value : state.epicSelected,
        added_to: state.userSelected === "" ? state.id.value : state.userSelected,
        added_by: getUser.empId,
        mainTaskId: state.taskId.value,
        storyPoints: state.storySelected === "" ? state.storyPoints.value : state.storySelected,
        priorityLevel: state.prioritySelected === "" ? state.priorityLevel.value : state.prioritySelected


      }, {}, false);
      if (response.status === 'True') {
        addCommentUserStory(dispatch, getUser, state.taskId.value, "User story Reassigned", "5")
        dispatch(getModuleMainTasks(dispatch, getUser, moduleId))
        // Alert("success", MAINTASK_REASSIGN);
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  } else {
    Alert("warning", "please give all the details")
  }
}


export async function deleteMainTask(dispatch, getUser, maintaskId, moduleId, handleClose, handleModalClose) {
  dispatch(isLoading());
  console.log(maintaskId, moduleId)
  try {
    var response = await API.post("tasksDelete.php", {
      "crop": getUser.corp,
      action: 'maintaskdelete',
      maintaskId: maintaskId,
      moduleId: moduleId
    }, {}, false);
    if (response.status === 'True') {
      Alert("success", MAINTASK_DELETE)
      handleClose()
    } else if (response.status === "false") {
      handleModalClose()
      Alert("warning", response.message)
    }
    else {
      console.log(response)
    }
  } catch (error) {
    Alert('error', error.message);
    handleClose()
  }
  dispatch(isLoaded());
}
//Add Comments for UserStory  Where We Have and Who can Add comments for that
export async function addCommentUserStory(dispatch, getUser, userStoryId, message,cardId) {
  console.log(userStoryId)
  console.log(getUser.empId+getUser.corp)

  dispatch(isLoading());
  try {
    const response = await API.post("storyComments.php", {
      action: "addStoryComment",
      storyId: userStoryId,
      corp: getUser.corp,
      userId: getUser.empId,
      message: message,
      cardId: cardId
    }, {}, false);
    console.log(response)
    if (response.status === "True") {
      Alert("success", MAINTASK_REASSIGN);

    } else {
      Alert('warning', response.message)
    }
  }
  catch (error) {
    Alert('error', error.message)
  }
  dispatch(isLoaded());
}