import React, { useReducer } from 'react';
import { updateFirstUser } from './network';
import { useSelector } from 'react-redux';
import { empReducer, initialState } from './empReducer';
import * as actions from './actions';
// import Select from 'react-select';
// import Header from '../../Common/TopNav';
import { Redirect } from 'react-router-dom';
import { setRoleCount } from '../../Common/LocalStorage';
// import AdminSideBar from '../Utility/SideNav';
// import AdminTopNav from '../Utility/TopNav';
// import TopNav from '../../UserModule/Utility/TopNav';
import TopNavWithOutProject from '../../UserModule/Utility/TopNav/topnav';
// import AdminSideBar from '../Utility/SideNav';
//For Add New Employee
export default function Instruction() {
    const getUser = useSelector(state => state.auth)
    const [state, dispatch] = useReducer(empReducer, initialState)
    // useEffect(() => {
        // empRoles(dispatch, getUser.user);
        // empDesignation(dispatch, getUser.user);
        // getManagers(dispatch, getUser.user)
        // getEmployeesRoles(dispatch,getUser.user);
        // eslint-disable-next-line
    // }, [])
    // var rolesList = [];
    // var designationList = [];
    // var managerList = [];
    // console.log(state.rolesList)
    // console.log(state)
    console.log(state.rolesList.length)
    if (state.rolesList.length >= 1) {
        setRoleCount('roleCount',state.rolesList.length)
        return <Redirect to = '/projects' />
    }
    // state.roles.map((roles) => {
    //     return (
    //         rolesList.push({ 'value': roles.value, 'label': roles.value })
    //     );
    // })
    // state.designations.map((designations) => {
    //     return (
    //         designationList.push({ 'value': designations.value, 'label': designations.value })
    //     );
    // })
    // state.managers.map((users) => {
    //     return (
    //         managerList.push({ 'value': users.name, 'label': users.name })
    //     );
    // })
    return (

        <div className="container-scroller">
            {/* <Header /> */}
            {/* <div className="container-fluid page-body-wrapper"> */}
            <TopNavWithOutProject />
            <div className="container-scroller d-flex justify-content-center">
                <div className="container-fluid page-body-wrapper d-flex justify-content-center">
                    <div className="main-panel d-flex justify-content-center">
                        <div className="d-flex justify-content-center">

                            <div className="col-12 grid-margin stretch-card d-flex justify-content-center">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">REGISTRATION</h4>


                                        {/* <div className="infonote" style={{marginBottom:'15px',padding:'4px 12px'}}>
                                            <p style={{paddingTop:"6px"}}><strong>Info!</strong> Register atleast one Approver, Manager or Employee</p>
                                        </div> */}

                                        <form className="forms-sample" >

                                            {/* <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <label for="recipient-ID" className="col-form-label pt-4 control-label'" style={{ width: "90px", color: 'black' }}>Emp ID <span style={{ color: "red" }} >*</span></label>
                                                </div>
                                                <input type="text" required="required" className="form-control col-10 ml-2 " id="recipient-empid" name="Emp ID" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.empId.value}
                                                    onChange={(event) => dispatch(actions.empId(event.target.value))} />
                                            </div> */}


                                            {/* <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <label for="recipient-name" className="col-form-label pt-4" style={{ width: "90px", color: 'black' }}>FullName <span style={{ color: "red" }} >*</span></label>
                                                </div>
                                                <input type="text" className="form-control col-10 ml-2 " id="recipient-fullName" name="Name" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.fullName.value}
                                                    onChange={(event) => dispatch(actions.fullName(event.target.value))} />
                                            </div> */}



                                            {/* <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <label for="recipient-email" className="col-form-label pt-4" style={{ width: "90px", color: 'black' }}>Email <span style={{ color: "red" }} >*</span></label>
                                                </div>
                                                <input type="text" className="form-control col-10 ml-2 " id="recipient-email" name="Email" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.email.value}
                                                    onChange={(event) => dispatch(actions.email(event.target.value))} />
                                            </div> */}




                                            {/* <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <label for="recipient-uname" className="col-form-label pt-4" style={{ width: "90px", color: 'black' }}>Mobile <span style={{ color: "red" }} >*</span></label>
                                                </div>
                                                <input type="number" className="form-control col-10 ml-2 " id="recipient-mobile" name="mobile" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.mobile.value}
                                                    onChange={(event) => dispatch(actions.mobile(event.target.value))} />

                                            </div> */}


                                            {/* <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <label for="recipient-password" className="col-form-label pt-4" style={{ width: "90px", color: 'black' }}>Password <span style={{ color: "red" }} >*</span></label>
                                                </div>
                                                <input type="password" className="form-control col-10 ml-2 " id="recipient-password" name="Password" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.password.value}
                                                    onChange={(event) => dispatch(actions.password(event.target.value))} />

                                            </div> */}

                                            {/* <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                    <label for="recipient-password" className="col-form-label pt-4" style={{ width: "90px", color: 'black', }}>Username <span style={{ color: "red" }} >*</span></label>
                                                </div>
                                                <input type="text" className="form-control col-10 ml-2 " id="recipient-name" name="Username" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.email.value}
                                                    readonly />

                                            </div> */}




                                            {/* <div className="form-group">
                                                <div className="form-group row pl-2" >
                                                    <label for="recipient-name" className="col-form-label pt-2" style={{ fontSize: '16px' }}>Select Employee Status <span style={{ color: "red" }} >*</span></label>
                                                    <div className="form-check form-check-inline pl-2">
                                                        <input className="form-check-input" type="radio" name="userStatus" id="Active" value="Active"
                                                            onChange={(event) => dispatch(actions.userStatus(event.target.value))} checked={state.userStatus.value === "Active" ? true : false} />
                                                        <label>Active</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="userStatus" id="Inactive" value="Inactive"
                                                            onChange={(event) => dispatch(actions.userStatus(event.target.value))} />
                                                        <label>In-Active</label>
                                                    </div>

                                                </div>
                                            </div> */}
                                            <div className="form-group">
                                                <div className="form-group row pl-2" style={{ marginTop: '-1px' }}>
                                                    <label for="recipient-name" className="col-form-label pt-2" style={{ fontSize: '16px',}}>Select Role  <span style={{ color: "red" }} >*</span>:</label>
                                                    <div className="form-check form-check-inline pl-2">
                                                        <input className="form-check-input" type="radio" name="userType" id="Emp" value="Emp"
                                                            onChange={(event) => dispatch(actions.userType(event.target.value))} checked={state.userType.value === "Emp" ? true : false} />
                                                        <label>Contributor</label>
                                                    </div>
                                                    <div className="form-check form-check-inline" style={{marginLeft:"20px"}}>
                                                        <input className="form-check-input" type="radio" name="userType" id="Manager" value="Manager"
                                                            onChange={(event) => dispatch(actions.userType(event.target.value))} checked={state.userType.value === "Manager" ? true : false} />
                                                        <label>Scrum Master</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="userType" id="Approver" value="Approver"
                                                            onChange={(event) => dispatch(actions.userType(event.target.value))} checked={state.userType.value === "Approver" ? true : false} />
                                                        <label>Product Owner</label>
                                                    </div>
                                                   
                                                </div>
                                            </div>

                                            {/* <div className="input-group mb-3" style={{ marginTop: '-40px' }}>
                                                <div className="input-group-prepend">
                                                    <label for="recipient-name" className="col-form-label pt-4" style={{ width: "160px", color: 'black' }}>Department</label>
                                                </div>
                                                <Select
                                                    className="form-control col-10 m-1"
                                                    placeholder="Select Department"
                                                    value={rolesList.value}
                                                    onChange={(selectedOption) => {
                                                        dispatch(actions.roleSelected(selectedOption.value))
                                                    }}
                                                    options={rolesList}
                                                />

                                            </div>


                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <label for="recipient-name" className="col-form-label pt-4" style={{ width: "160px", color: 'black' }}>Title</label>
                                                </div>
                                                <Select
                                                    className="form-control col-10 m-1"
                                                    placeholder="Select Title"
                                                    value={designationList.value}
                                                    onChange={(selectedOption) => {
                                                        dispatch(actions.designationSelected(selectedOption.value))
                                                    }}
                                                    options={designationList}
                                                />

                                            </div>


                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <label for="recipient-name" className="col-form-label pt-4" style={{ width: "160px", color: 'black' }}>Reporting Manager</label>
                                                </div>
                                                <Select
                                                    className="form-control col-10 m-1"
                                                    placeholder="Select Reporting Manager"
                                                    value={managerList.value}
                                                    onChange={(selectedOption) => {
                                                        dispatch(actions.reportingManagerSelected(selectedOption.value))
                                                    }}
                                                    options={managerList}
                                                />

                                            </div>


                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <label for="recipient-name" className="col-form-label pt-4" style={{ width: "160px", color: 'black' }}>Functional Manager</label>
                                                </div>
                                                <Select
                                                    className="form-control col-10 m-1"
                                                    placeholder="Select Functional Manager"
                                                    value={managerList.value}
                                                    onChange={(selectedOption) => {
                                                        dispatch(actions.functionalManagerSelected(selectedOption.value))
                                                    }}
                                                    options={managerList}
                                                />

                                            </div> */}
                                        {/* <div className="note" style={{marginBottom:'15px',padding:'4px 12px'}}>
                                            <p style={{paddingTop:"6px"}}><strong>Note * :</strong> For further settings go to Admin -- Settings</p>
                                        </div> */}

                                            <div className="d-flex justify-content-end mt-5">
                                                <button type="button" className="btn btn-success mr-5 "  onClick={()=>updateFirstUser(state,dispatch,getUser.user)}>Submit</button>
                                                {/* <button className="btn btn-light">Cancel</button> */}
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // </div>
    );
}