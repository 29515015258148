import React, { useEffect, useState, useReducer } from "react";
import Board from "react-trello";
import TopNav from "../Utility/TopNav";
import SideBar from "../Utility/SideNav";
import { useSelector } from "react-redux";
import { getActive, getAllTaskMessages, getModules, getActiveSprints, getToDo, getRoadBlock, getDone, getInvolvedEmployees, updateUserStory, addCommentUserStory, getCurrentSprintUserStories } from "./network";
import ChatBox from "../../Common/ChatMainTask";
import Alert from "../../Common/Alert";
import RoadBlock from "../../Common/RoadBlock";
import RootLoader from "../../Common/Loader/RootLoader";
import { ROADBLOCK, NEWMODULE, } from "../../Common/Headers";
import RoadBlockModal from "../UserDashboard/roadblockModal";
import TaskInfo from "../UserDashboard/taskInfo";
import UpdateTask from "../UserDashboard/updateTask";
import { moduleReducer, initialState } from "./moduleReducer";
import Moment from 'moment';
import Select from 'react-select';
import { sprintSelected, activeSprint, activeUserStory, roadblockFilter, todoFilter, doingFilter, doneFilter } from "./actions";
import store from "../../Common/Store";
import { Link } from "react-router-dom";
import AddModule from "../../Common/Modules/addModule";

const CustomCard = ({
    onClick,
    className,
    laneId,
    title,
    id,
    subTaskDesc,
    status,
    taskStatus,
    mainTaskid,
    activeStatus,
    dependencyId,
    timeLeft,
    extraHours,
    mainTaskTitle,
    taskDesc,
    ideaTitle,
    moduleDesc,
    storyPoints,
    acceptanceCriteria,
    assignedDate,
    modifiedDate,
    targetDate,
    completeStatus,
    assignedto,
    blockedId,
    userStoryId,
    cardStyle,
    body,
    dueOn,
    cardColor,
    description,
    label,
    escalationText,
    tags,
    showDeleteButton,
    onDelete,
}) => {
    const [name] = assignedto.split('@');
    const [chatOpen, setChatOpen] = useState(false)
    const [updateOpen, setUpdateOpen] = useState(false)
    const [infoOpen, setInfoOpen] = useState({ status: false })
    const [cardInfo, setCardInfo] = useState()
    // eslint-disable-next-line 
    const [state, dispatch] = useReducer(moduleReducer, initialState)
    const getUser = useSelector(state => state.auth)
    // eslint-disable-next-line
    const info = useSelector(state => state.subtask)
    const sprint = useSelector(state => state.sprint) //updated with sprintId for user stories updated in sprint cycle -->GNK --> Version1.0.6.03
    //For Open Chat
    const handleOpenChat = (action, id, sno) => {
        setChatOpen(true)
        // updateChat(sno,dispatch,getUser.user)
        var info = { action: action, id: id, sno: sno }
        setCardInfo(info)
    }
    //for Update Task

    const handleOpenUpdate = (title, description, id, mainTaskId, dependencyId, sprintId) => { //updated with sprintId for user stories updated in sprint cycle -->GNK --> Version1.0.6.03
        setUpdateOpen(true)
        var info = { title: title, description: description, id: id, mainTaskId: mainTaskId, dependencyId: dependencyId, sprintId: sprintId }
        setCardInfo(info)
    }
    const handleChatClose = () => {
        setChatOpen(false);
        getAllTaskMessages(dispatch, getUser.user);
    };

    const handleUpdateClose = () => {
        setUpdateOpen(false);
    };

    //for Update Task
    const handleOpenTaskInfo = (title, description, id, mainTaskId, dependencyId, timeLeft, completeStatus, extraHours, mainTaskTitle, taskDesc, ideaTitle, laneId, moduleDesc, storyPoints, acceptanceCriteria, assignedDate, targetDate, modifiedDate, action) => {
        setInfoOpen({ status: true, action: action })
        var info = {
            title: title, description: description, id: id, mainTaskId: mainTaskId,
            mainTaskTitle: mainTaskTitle, dependencyId: dependencyId,
            status: completeStatus, timeLeft: timeLeft, extraHours: extraHours, taskDesc: taskDesc,
            ideaTitle: ideaTitle, laneId: laneId, moduleDesc: moduleDesc, storyPoints: storyPoints, acceptanceCriteria: acceptanceCriteria, assignedDate: assignedDate, targetDate: targetDate, modifiedDate: modifiedDate, action: action, openStatus: true
        }
        setCardInfo(info)
        store.dispatch(activeUserStory([info]))
    }
    const handleTaskInfoClose = () => {
        setInfoOpen(false);
    };
    const handleModalClose = () => {
        setChatOpen(false);
        getAllTaskMessages(dispatch, getUser.user);//For Chat Auto Refresh
    }
    const getMessagesCount = (id, msg, empId) => {
        const msgCount = msg.filter(message => message.readBy.split(",").indexOf(empId) === -1 && message.messagedBy !== empId && message.groupId === id).map((messages, i) => {
            // eslint-disable-next-line
            return i, messages
            // eslint-disable-next-line
        })
        return (
            <i>


                {/* {msgCount.length > 0 ? msgCount.length : null} */}
                {
                    msgCount.length > 0 ?
                        <div className="row">

                            <img src="images/common/chat.svg" alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-8px", }} onClick={() => handleOpenChat("maintask", id, msgCount)} />
                            <span style={{ color: 'red', fontWeight: "bold" }}>{msgCount.length}</span>
                        </div>
                        :
                        <div className="row">
                            <img src="images/common/chat.svg" alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-8px" }} onClick={() => handleOpenChat("maintask", id, msgCount)} />
                        </div>
                }
            </i>
        )
    }
    return (
        <div
            onClick={onClick}
            // style={cardStyle}
            style={{ border: '0.2px solid grey', borderRadius: '10px', padding: '10px', marginBottom: 2 }}
        // className={title === "Dispose Garbage" ? "bg-white" :"bg-primary"}
        >
            <header
                style={{
                    display: 'flex',
                    color: cardColor,
                    width: 260,
                    padding: 0
                }}>
                {laneId === "RoadBlock" ?
                    <div style={{ fontSize: 8, fontWeight: 'bold', cursor: 'pointer', flex: 1 }} className="column "
                        onClick={() => handleOpenTaskInfo(title, subTaskDesc, id, mainTaskid, dependencyId, timeLeft, completeStatus, extraHours, mainTaskTitle, taskDesc, ideaTitle, laneId, moduleDesc, storyPoints, acceptanceCriteria, assignedDate, targetDate, modifiedDate, "roadblockInfo")}>
                        <div className="row d-flex justify-content-between">
                        <div className="row pl-4">
                            <u className="text-dark">{(getUser.user.corp).substring(0, 3).toUpperCase()} - {id} </u>
                            {ideaTitle !== null ? <p className="mb-0 ml-4" style={{ marginRight: '2px', color: 'orange' }} data-toggle="tooltip" data-placement="left" title={ideaTitle}>{(ideaTitle).substring(0, 5).toUpperCase()}</p> : null}
                            </div>
                            <div>
                            <p className="mr-4" style={{ marginRight: '2px', color: 'green' }}>{name}</p>
                            </div>
                        </div>
                        <p className="text" style={{ width: 250 }}>{title}</p>
                    </div> :

                    <div style={{ fontSize: '30', fontWeight: 'bold', cursor: 'pointer', flex: 1 }} className="column"
                        onClick={() => handleOpenTaskInfo(title, subTaskDesc, id, mainTaskid, dependencyId, timeLeft, completeStatus, extraHours, mainTaskTitle, taskDesc, ideaTitle, laneId, moduleDesc, storyPoints, acceptanceCriteria, assignedDate, targetDate, modifiedDate, "taskInfo")}>

                        <div className="row d-flex justify-content-between">
                            <div className="row pl-4">
                                <u className="text-dark">{(getUser.user.corp).substring(0, 3).toUpperCase()} - {id} </u>
                                {ideaTitle != null ? <p className="mb-0 ml-4" style={{  color: 'orange' }} data-toggle="tooltip" data-placement="left" title={ideaTitle}>{(ideaTitle).substring(0, 5).toUpperCase()}</p> : null}
                            </div>
                            <div>
                                <p className="mr-4 mb-0" style={{ marginRight: '2px', color: 'green' }}>{name}</p>
                            </div>
                        </div>
                        <p className="text mb-0" style={{ width: 250 }}>{title}</p>
                    </div>}


                {/* <div style={{ fontSize: 11 }}>{dueOn}</div>
        {showDeleteButton && <button onClick={clickDelete} />} */}
            </header>
            <div className="column" style={{ fontSize: 12, color: '#BD3B36', margin: 0, padding: 0, flex: 1, }}>
                {/* {laneId === "RoadBlock" ? <div style={{ color: '#4C4C4C', fontWeight: 'bold', width: '260px', overflowWrap: "break-word" }}>{taskDesc}</div> : <div style={{ color: '#4C4C4C', fontWeight: 'bold', width: '260px', overflowWrap: "break-word" }}>{subTaskDesc}</div>} */}
                <div className="row d-flex justify-content-between">
                    <div className="row ml-2">
                        {/* <p style={{ marginTop: 10, textAlign: 'right', color: cardColor, fontSize: 15, fontWeight: 'bold' }}>{taskStatus}</p> */}

                        {/* <button style={{ border: '0', width: '10px', height: '40px', backgroundColor: 'transparent',  }} type="button" onClick={() => handleOpenChat("subtask", id)}> <img src="images/common/chat.svg" alt="logo" style={{ width: '20px', height: '20px',marginLeft:"-8px" }} /></button> */}
                        <button type="button" style={{ border: '0', width: '8px', height: '30px', backgroundColor: 'transparent', }} >
                            {
                                getMessagesCount(id, info.allMessages, getUser.user.empId)
                            }
                        </button>
                        {completeStatus === "pending" && activeStatus !== "0" ?

                            <button style={{ border: '0', width: '8px', height: '30px', backgroundColor: 'transparent' }} type="button" onClick={() => handleOpenUpdate(title, subTaskDesc, id, mainTaskid, dependencyId, sprint.activeSprint.moduleId)}><img src="images/common/update.svg" alt="logo" style={{ width: '17px', height: '20px', marginLeft: "-17px" }} /></button>
                            : null}
                        {/*UserStory Comment  Box start GNK -->01 version 1.0.6 Start */}
                        {activeStatus !== "0" ?
                            <button style={{ border: '0', width: '8px', height: '30px', backgroundColor: 'transparent' }} type="button" onClick={() => handleOpenTaskInfo(title, subTaskDesc, id, mainTaskid, dependencyId, timeLeft, completeStatus, extraHours, mainTaskTitle, taskDesc, ideaTitle, laneId, moduleDesc, storyPoints, acceptanceCriteria, assignedDate, targetDate, modifiedDate, "commentInfo")}><img src="images/common/comments.svg" alt="logo" style={{ width: '20px', height: '25px', marginLeft: "-17px" }} /></button>
                            : null}
                        {completeStatus === "completed" ?
                            <button style={{ border: '0', width: '8px', height: '30px', backgroundColor: 'transparent' }} type="button" onClick={() => handleOpenTaskInfo(title, subTaskDesc, id, mainTaskid, dependencyId, timeLeft, completeStatus, extraHours, mainTaskTitle, taskDesc, ideaTitle, laneId, moduleDesc, storyPoints, acceptanceCriteria, assignedDate, targetDate, modifiedDate, "commentInfo")}><img src="images/common/comments.svg" alt="logo" style={{ width: '20px', height: '25px', marginLeft: "-17px" }} /></button>
                            : null}
                        {/*UserStory Comment  Box start GNK -->01 version 1.0.6 End */}
                        {activeStatus !== "0" ?


                            <div className="d-flex justify-content-center mt-2" style={{ backgroundColor: "#EDCB77", width: 30, height: 20, textAlign: 'center', justifyContent: 'center' }}> <i className="text" style={{ color: 'white', fontSize: 10, fontWeight: 'bold', paddingTop: 5 }}>{taskStatus} %</i> </div> : null}
                        {laneId !== "RoadBlock" ? blockedId !== '0' ? <p className="mt-2 ml-4" style={{ marginRight: '2px', color: 'red' }} data-toggle="tooltip" data-placement="left" title={userStoryId}>{(getUser.user.corp).substring(0, 3).toUpperCase()}-{userStoryId}</p> : null:null}

                    </div>

                    <div className="d-flex justify-content-center mt-2 mr-2" style={{ backgroundColor: "#6DAE8C", width: 20, height: 20 }}> <i className="text" style={{ color: 'white', fontSize: 10, fontWeight: 'bold', paddingTop: 5 }}>{storyPoints} </i></div>

                </div>
                {tags && (
                    <div
                        style={{

                            display: 'flex',
                            justifyContent: 'flex-end',
                            flexDirection: 'row',
                        }}>
                        {/* {tags.map(tag => (
                <Tag key={tag.title} {...tag} tagStyle={tagStyle} />
              ))} */}
                    </div>
                )}
            </div>
            {
                chatOpen ? <ChatBox open={chatOpen} handleClose={handleChatClose} data={cardInfo} handleModalClose={handleModalClose}
                /> : null
            }
            {
                updateOpen ? <UpdateTask open={updateOpen} handleClose={handleUpdateClose} data={cardInfo} handleModalClose={handleModalClose}
                /> : null
            }
            {
                infoOpen.status && infoOpen.action === "commentInfo" ? <TaskInfo open={infoOpen} handleClose={handleTaskInfoClose} data={cardInfo} handleModalClose={handleModalClose}
                /> : null
            }
            {
                infoOpen.status && infoOpen.action === "roadblockInfo" ? <RoadBlockModal open={infoOpen} handleClose={handleTaskInfoClose} data={cardInfo} handleModalClose={handleModalClose}
                /> : null
            }
        </div>
    )
}

export default function UserSprints() {

    const openmodal = {
        backgroundColor: 'white', height: 570, marginLeft: '-10px', marginRight: '-10px', width: 1000, margin: 20
    };

    const closemodal = {
        backgroundColor: 'white', height: 570, marginLeft: '-10px', marginRight: '-10px', margin: 20
    };

    const [state, dispatch] = useReducer(moduleReducer, initialState)
    const getUser = useSelector(state => state.auth)
    // const loaderStatus = useSelector(state => state.loading.status)
    const [open, setOpen] = useState(false)
    const [cardInfo, setCardInfo] = useState()
    const [openModule, setOpenModule] = useState({ status: false, index: 0 })
    const [moduleInfo, updateModuleInfo] = useState()
    //   const [open, setOpen] = useState({ status: false, index: 0 })
    //   const dispatch = useDispatch();
    useEffect(() => {
        getActiveSprints(dispatch, getUser.user)
        getModules(dispatch, getUser.user);
        // getToDo(dispatch, getUser.user); //for todo and doing information
        // getRoadBlock(dispatch, getUser.user);
        // getDone(dispatch, getUser.user);
        getAllTaskMessages(dispatch, getUser.user);
        store.dispatch(activeUserStory([{ openStatus: false }]))
        // eslint-disable-next-line
    }, [])
    // console.log(state.sprint)
    const info = useSelector(state => state.sprint)
    var data = {

        "lanes": [
            {
                "id": "ToDo",
                "title": "To Do",
                "style": {
                    "width": 310,
                    "height": 550,
                    "fontSize": 15,
                    "fontWeight": "bold",
                    "backgroundColor": "white",
                    "borderRadius": "15px",
                    "boxShadow": "3px 3px 20px 10px #f1f1f1",
                    "margin": "10px"

                },
                "cards": info.todoFilter
            },
            {
                "id": "Doing",
                "title": "In Progress",
                // "label": "10/20",
                "style": {
                    "width": 310,
                    "height": 550,
                    "fontSize": 15,
                    "fontWeight": "bold",
                    "backgroundColor": "white",
                    "borderRadius": "15px",
                    "boxShadow": "3px 3px 20px 10px #f1f1f1",
                    "margin": "10px"
                },
                "cards": info.doingFilter
            },
            {
                "id": "RoadBlock",
                "title": ROADBLOCK,
                // "label": "0/0",
                "style": {
                    "width": 310,
                    "height": 550,
                    "fontSize": 15,
                    "fontWeight": "bold",
                    "backgroundColor": "white",
                    "borderRadius": "15px",
                    "boxShadow": "3px 3px 20px 10px #f1f1f1",
                    "margin": "10px"
                },
                "cards": info.roadblockFilter
            },
            {
                "id": "Completed",
                "title": "Done",
                "style": {
                    "width": 310,
                    "height": 550,
                    "fontSize": 15,
                    "fontWeight": "bold",
                    "backgroundColor": "white",
                    "borderRadius": "15px",
                    "boxShadow": "3px 3px 20px 10px #f1f1f1",
                    "margin": "10px"
                },
                // "label": "2/5",
                "cards": info.doneFilter
            },
        ]
    }
    // console.log(info.todo)
    function handleCardMove(from, to, data, sprintId) { //updated with "sprintId" for user stories updated in sprint cycle -->GNK --> Version1.0.6.03
        // setOpen(true);
        // console.log(from, to, data)
        const check = from + to;
        console.log(sprintId)
        var message;
        if (check === "ToDoDoing") {
            message = "User story in progress";
            getActive(dispatch, getUser.user, data, sprintId,handleClose) //updated with "sprintId" for user stories updated in sprint cycle -->GNK --> Version1.0.6.03
            addCommentUserStory(dispatch, getUser.user, data, message, "1")
        } else if (check === "ToDoRoadBlock") {
            Alert("warning", "You are not able to add RoadBlock from To Do")
            // Alert("warning", "ToDoRoadBlock"+from + to + data);
        } else if (check === "DoingRoadBlock") {
            message = "User story is blocked";
            var info = { "taskId": data, "message": message }
            setCardInfo(info)
            setOpen(true)
        } else if (check === "DoingCompleted") {
            message = "User story is done/completed";
            updateUserStory(dispatch, getUser.user, data, sprintId,handleClose) //updated with sprintId for user stories updated in sprint cycle -->GNK --> Version1.0.6.03
            addCommentUserStory(dispatch, getUser.user, data, message, "2")
        }
        else {
            // Alert("warning", from + to + data);
        }
    }
    const handleOpen = (action) => {
        setOpenModule({ status: true, action: action });
        var info = { action: action, id: "0" }
        updateModuleInfo(info)
    };
    const handleClose = () => {
        setOpen(false);
        setOpenModule({ status: false });
        getCurrentSprintUserStories(dispatch, getUser.user, info.activeSprint.moduleId) //updated with "sprintId" and method name changed from getActiveSprint() for user stories updated in sprint cycle -->GNK --> Version1.0.6.03
        getModules(dispatch, getUser.user);
        getAllTaskMessages(dispatch, getUser.user);
    };
    const handleModalClose = () => {
        setOpen(false);
        setOpenModule({ status: false });

    }
    // console.log(state.modules)
    var sprints = []
    state.modules.map((module) => {
        return (
            sprints.push({
                'value': module.moduleId, 'label': module.moduleDesc, assignbyId: module.assignbyId,
                average: module.average,
                createdBy: module.createdBy,
                created_on: module.created_on,
                ideaId: module.ideaId,
                ideaTitle: module.ideaTitle,
                moduleDesc: module.moduleDesc,
                moduleId: module.moduleId,
                startDate: module.startDate,
                status: module.status,
                targetDate: module.targetDate
            })
        );
    })
    const selectSprint = (selectedOption) => {
        // console.log(selectedOption.moduleId)
        dispatch(sprintSelected(selectedOption.value))
        store.dispatch(activeSprint(selectedOption))
        getToDo(dispatch, getUser.user, selectedOption.moduleId);
        getAllTaskMessages(dispatch, getUser.user)
        getRoadBlock(dispatch, getUser.user, selectedOption.moduleId);
        getDone(dispatch, getUser.user, selectedOption.moduleId);
        getInvolvedEmployees(dispatch, getUser.user, selectedOption.moduleId)
        store.dispatch(activeUserStory([{ openStatus: false }]))
    }
    const allUserBacklogs = () => {
        store.dispatch(todoFilter(info.todo));
        store.dispatch(doingFilter(info.doing));
        store.dispatch(doneFilter(info.done));
        store.dispatch(roadblockFilter(info.roadblock));
        store.dispatch(activeUserStory([{ openStatus: false }]))
    }
    const userBacklogs = (assignedToId, moduleId) => {
        // console.log(assignedToId + "   " + moduleId)
        var todoList = [];
        var doingList = [];
        var doneList = [];
        var roadBlockList = [];
        store.dispatch(activeUserStory([{ openStatus: false }]))
        info.todo.map((todo) => {
            // console.log(todo)
            // console.log(assignedToId)
            return (
                (todo.assignedTo === assignedToId) ? todoList.push(todo) : []
            );
        })
        info.doing.map((doing) => {
            return (
                (doing.assignedTo === assignedToId) ? doingList.push(doing) : []
            );
        })
        info.done.map((done) => {
            return (
                (done.assignedTo === assignedToId) ? doneList.push(done) : []
            );
        })
        info.roadblock.map((roadblock) => {
            return (
                (roadblock.assignedTo === assignedToId) ? roadBlockList.push(roadblock) : []
            );
        })

        store.dispatch(todoFilter(todoList));
        store.dispatch(doingFilter(doingList));
        store.dispatch(doneFilter(doneList));
        store.dispatch(roadblockFilter(roadBlockList));
    }
    // console.log(info.activeUserStory[0])
    return (
        <div className="container-scroller">
            <TopNav />
            <div className="container-fluid page-body-wrapper">
                <SideBar />
                <div className="main-panel" >
                    {/* <div className="content-wrapper"> */}
                    {state.modules.length > 0 ?

                        <div style={{ flexDirection: 'row' }}>
                            <div className="col-12 row p-2">
                                <h5 className="col-6" style={{ overflowWrap: "break-word", color: 'blue', backgroundColor: 'transparent' }}><b>{(getUser.user.corp).substring(0, 3).toUpperCase()} {info.activeSprint.moduleDesc}</b> [{Moment(info.activeSprint.startDate).format('MM.DD.YYYY')}-{Moment(info.activeSprint.targetDate).format('MM.DD.YYYY')}] -  Scrum-Board</h5>

                                <label className="p-2" for="story" >Sprints</label>
                                <Select
                                    className="form-control col-3"
                                    style={{ width: 10, boarderRadius: 2 }}
                                    // placeholder="Select Active Points"
                                    placeholder={info.activeSprint.moduleDesc}
                                    value={sprints.value}
                                    onChange={(selectedOption) =>
                                        selectSprint(selectedOption)
                                    }
                                    options={sprints}
                                />
                                <div className="mb-2 ">
                                    <button style={{ backgroundColor: 'transparent', border: '0',}} type="button" onClick={() => handleOpen("addSprint")}> <img src="images/common/add.png" alt="logo" style={{ width: '20px', height: '20px' }} /><span sclassName="m-1">{NEWMODULE}</span></button>
                                    {
                                        openModule.action === "addSprint" ? <AddModule open={openModule.status} handleClose={handleClose} data={moduleInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                </div>
                            </div>

                            <h6 style={{ marginTop: -20, flexDirection: 'row', marginLeft: 10 }}>

                                <input type="image" src="images/common/teams.png" style={{ textTransform: "capitalize", padding: 1, textAlign: 'center', marginTop: 20 }} alt="logo" width="25" height="25" onClick={() => allUserBacklogs()} />

                                {state.involvedEmployees.map((involved, index) => {
                                    const input = involved.assignedTo;
                                    const [name] = input.split('@');
                                    return <Link  style={{ textTransform: "capitalize", padding: '1px', textAlign: 'center', marginLeft: '10px' ,color:'green'}} onClick={() => userBacklogs(involved.assignedToId, info.activeSprint.moduleId)} >{name}-{involved.points} </Link>
                                })} </h6>
                        </div> : <div className="col-12 row p-2">
                            <h5 className="col-6" style={{ overflowWrap: "break-word", color: 'blue', backgroundColor: 'transparent' }}>Scrum-Board</h5>

                            <label className="p-2" for="story" >Sprints</label>
                            <Select
                                className="form-control col-3"
                                style={{ width: 10, boarderRadius: 2 }}
                                // placeholder="Select Active Points"
                                placeholder={info.activeSprint.moduleDesc}
                                value={sprints.value}
                                onChange={(selectedOption) =>
                                    selectSprint(selectedOption)
                                }
                                options={sprints}
                            />
                            <div className="mb-2 ">
                                <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" onClick={() => handleOpen("addSprint")}> <img src="images/common/add.png" alt="logo" style={{ width: '20px', height: '20px' }} /><span className="m-1">{NEWMODULE}</span></button>
                                {
                                    openModule.action === "addSprint" ? <AddModule open={openModule.status} handleClose={handleClose} data={moduleInfo} handleModalClose={handleModalClose}
                                    /> : null
                                }
                            </div>
                        </div>}
                    {state.isLoading ? <RootLoader /> :
                        <div className="row" style={{ flexDirection: "row" }}>
                            <div className={info.activeUserStory[0].openStatus && info.activeUserStory[0].action === "taskInfo" ? "col-9" : "col-12"}>
                                <Board
                                    tagStyle={{ fontSize: '20%', textAlign: 'left' }}
                                    data={data}
                                    draggable
                                    onCardMoveAcrossLanes={(cardId, metadata, card) => handleCardMove(cardId, metadata, card, info.activeSprint.moduleId)}
                                    components={{ Card: CustomCard }} //custom cards
                                    cardDragClass="draggingCard"
                                    laneDragClass="draggingLane"
                                    // onCardMoveAcrossLanes={onCardMoveAcrossLanes}
                                    style={info.activeUserStory[0].openStatus && info.activeUserStory[0].action === "taskInfo" ? openmodal : closemodal} //,height:400
                                // laneStyle={{backgroundColor: '#666'}} style={{backgroundColor: '#eee'}}
                                />
                            </div>
                            {info.activeUserStory[0].openStatus && info.activeUserStory[0].action === "taskInfo" ?
                                <div className={info.activeUserStory[0].openStatus ? "col-3 mt-4" : null} style={{ padding: 10, borderLeft: '1px solid grey', borderBottom: '1px solid grey', borderRight: '1px solid grey', borderTop: '1px solid grey', backgroundColor: "#f8f8f8", height: 550, overflowY: 'scroll' }} >
                                    <div style={{ flexDirection: 'row' }}>
                                        <text style={{ fontWeight: "bold", fontSize: 14, width: 30 }}>{(getUser.user.corp).substring(0, 3).toUpperCase()} - {info.activeUserStory[0].id} </text>
                                        <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" data-dismiss="modal" onClick={() => store.dispatch(activeUserStory([{ openStatus: false }]))}><i class="mdi mdi-close text-black"></i></button>

                                    </div>
                                    {/* <hr /> */}
                                    {/* <div>
                                    <text style={{fontSize:12}}>{info.activeUserStory[0].id}</text>
                                </div> */}

                                    <div >
                                        <text style={{ fontWeight: "bold", fontSize: 12 }}> Epic Name</text>
                                    </div>
                                    <div>
                                        <text style={{ fontSize: 12 }}>{info.activeUserStory[0].ideaTitle}</text>
                                    </div>

                                    <div className="pt-2">
                                        <text style={{ fontWeight: "bold", fontSize: 12 }}> User Story Title</text>
                                    </div>
                                    <div>
                                        <text style={{ fontSize: 12 }}>{info.activeUserStory[0].title}</text>
                                    </div>

                                    <div className="pt-2">
                                        <text style={{ fontWeight: "bold", fontSize: 12 }}>Description</text>
                                    </div>
                                    <div>
                                        <text style={{ fontSize: 12 }}>{info.activeUserStory[0].description}</text>
                                    </div>

                                    <div className="pt-2">
                                        <text style={{ fontWeight: "bold", fontSize: 12 }}>Story Points</text>
                                    </div>
                                    <div>
                                        <text style={{ fontSize: 12 }}>{info.activeUserStory[0].storyPoints}</text>
                                    </div>


                                    <div className="pt-2">
                                        <text style={{ fontWeight: "bold", fontSize: 12 }}>Acceptance Criteria/Definition of Done</text>
                                    </div>
                                    <div>
                                        <text style={{ fontSize: 12 }}>{info.activeUserStory[0].acceptanceCriteria}</text>
                                    </div>

                                    <div>
                                        <div className="pt-2">
                                            <text style={{ fontWeight: "bold", fontSize: 12 }}>Assigned Date</text>
                                        </div>
                                        <div>
                                            <text style={{ fontSize: 12 }}>{Moment(info.activeUserStory[0].assignedDate).format('MM.DD.YYYY hh:mm:ss')}</text>
                                        </div>
                                    </div>
                                    {info.activeUserStory[0].status === "completed" ?
                                        <div>
                                            <div className="pt-2">
                                                <text style={{ fontWeight: "bold", fontSize: 12 }}>Completed Date</text>
                                            </div>
                                            <div>
                                                <text style={{ fontSize: 12 }}>{Moment(info.activeUserStory[0].modifiedDate).format('MM.DD.YYYY hh:mm:ss')}</text>
                                            </div>
                                        </div> : null
                                    }

                                </div> : null}
                        </div>
                    }
                    {/* </div> */}
                    {
                        open ? <RoadBlock open={open} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                        /> : null
                    }

                </div>

            </div>
        </div>
    );
}
