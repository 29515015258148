import React, { useEffect, useState, useReducer } from 'react'
import { Bar , Line } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ReportsAPI from '../../Common/AgileNetwork/ReportsAPI';
import { useSelector } from 'react-redux';
import Header from '../../Common/TopNav';
import SideNavigation from '../../Common/SideNav';
import { getEmployees } from '../Team/network';
import { empReducer, initialState } from '../Team/empReducer';
// import { Link } from 'react-router-dom';
import Select from 'react-select';

export default function Reports() {
    const getUser = useSelector(state => state.auth)
    const [state, dispatch] = useReducer(empReducer, initialState)
    const [sprintVelocity, setSprintVelocity] = useState([])
    // const [individual, setIndividual] = useState([])
    const [sprint, setSprint] = useState([])
    // const [selectedSprint, setSelectedSprint] = useState([])
    const [indVelocity, setIndVelocity] = useState([])
    const [burnUpChart, setBurnUpChart] = useState([])//burn up chart version 1.0.11
    const [selectedIndVelocity, setSelectedIndVelocity] = useState([])
    const [indWorkload, setIndWorkload] = useState([])

    async function Network(data, state) {
        // console.log(data)
        // console.log(state)

        try {
            const response = await ReportsAPI.get(`/api/${data}`, {}, {}, false)
            state(response)
        }
        catch (error) {
            console.log('error', error.message)
        }
    }
    useEffect(() => {
        getEmployees(dispatch, getUser.user); //Get Squad List
        Network('sprintVelocity?databaseName=' + getUser.user.corp, setSprintVelocity)
        Network('sprint?databaseName=' + getUser.user.corp, setSprint)
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        // console.log(selectedSprint)
        // console.log(sprintData)
        // console.log(indVelocity)

    })
    //Get SquadList
    var squadsList = []
    state.employees.map((squads) => {
        return (
            squadsList.push({
                'value': squads.id, 'label': squads.name
            })
        );
    })
    const sprintData = {
        labels: sprintVelocity.map(x => x.sprint),
        datasets: [
            {
                label: "Committed",
                backgroundColor: "rgba(108, 122, 137, 1)",
                data: sprintVelocity.map(x => x.estimated)
            },

            {
                label: "Completed",
                backgroundColor: "rgba(30, 130, 76, 1)",
                data: sprintVelocity.map(x => x.completed)
            }
        ]

    };
    const individualVelocity = {
        labels: indVelocity.map(x => x.fullName),
        datasets: [
            {
                label: "Committed",
                backgroundColor: "rgba(108, 122, 137, 1)",
                data: indVelocity.map(x => x.estimated)
            },

            {
                label: "Completed",
                backgroundColor: "rgba(30, 130, 76, 1)",
                data: indVelocity.map(x => x.completed)
            }
        ]

    };
    //Individual Workload Selection start by Naveen in version 1.0.9 start
    const individualWorkload = {
        labels: indWorkload.map(x => x.sprintName),
        datasets: [
            {
                label: "Committed",
                backgroundColor: "rgba(108, 122, 137, 1)",
                data: indWorkload.map(x => x.estimated)
            },

            {
                label: "Completed",
                backgroundColor: "rgba(30, 130, 76, 1)",
                data: indWorkload.map(x => x.completed)
            }
        ]

    };
     //BurnUP Chart Workload Selection start by Naveen in version 1.0.11 start
     const sprintBurnUpChart = {
        labels: burnUpChart.map(x => x.load_date),
        datasets: [
            {
              label: "Estimated",
              data: burnUpChart.map(x => x.estimated),
              fill: false,
              borderColor: "rgba(108, 122, 137, 1)"
            },
            {
              label: "Completed",
              data: burnUpChart.map(x => x.completed),
              fill: false,
              borderColor: "rgba(30, 130, 76, 1)",
            }
          ]
    };
    //BurnUP Chart Workload Selection start by Naveen in version 1.0.11 end

    //Individual Workload Selection start by Naveen in version 1.0.9 end
    // const individualData = {
    //     labels: individual.map(x => x.fullName),
    //     datasets: [
    //         {
    //             label: "Story Points",
    //             data: individual.map(x => x.total),
    //             fill: true,
    //             backgroundColor: "rgba(30, 130, 76, 1)",
    //             borderColor: "rgba(75,192,192,1)"
    //         },
    //     ]
    // };
    //Individual Workload Selection start by Naveen in version 1.0.9 start
    function handleSelectChangeIndWorkload(event) {
        Network(`individualWorkload?assignedTo=${event}&databaseName=` + getUser.user.corp, setIndWorkload)
        // setSelectedIndWorkload(event);
    }
    //Individual Workload Selection start by Naveen in version 1.0.9 end
    function handleSelectChangeInd(event) {
        Network(`individualSprintVelocity?moduleId=${event.target.value}&databaseName=` + getUser.user.corp, setIndVelocity)
        setSelectedIndVelocity(event.target.value);
        Network(`burnUp?moduleId=${event.target.value}&databaseName=` + getUser.user.corp, setBurnUpChart)//Sprint burnUpChart
    }

    return (
        <div className="container-scroller">
            <Header />
            <div className="container-fluid page-body-wrapper">
                <SideNavigation />
                <div className="main-panel">
                    <div>
                        <div className="mt-2 ml-2">
                            <h6>Sprint Velocity</h6>
                            {/* add charts here */}
                            <Bar
                                data={sprintData}
                                width={"100%"}
                                height={"300"}
                                options={
                                    {
                                        responsive: true,
                                        maintainAspectRatio: false,

                                        plugins: {
                                            datalabels: {
                                                display: true,
                                                color: 'rgba(30, 130, 255, 4)'
                                            }
                                        },
                                        legend: {
                                            display: true
                                        },
                                        type: "bar",
                                        scales: {
                                            yAxes: [{
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: 'Story Points',
                                                    fontStyle: 'bold'
                                                }
                                            }],
                                            xAxes: [{
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: 'Sprint Cycle',
                                                    fontStyle: 'bold'
                                                }
                                            }],
                                        }

                                    }
                                }
                            />

                        </div>
                        {/* <br /> */}
                        {/* <div className="mt-2 ml-2 mb-3">
                            <h6>Individual Workload</h6>
                            <select value={selectedSprint} onChange={handleSelectChange}>
                                {sprint.map(x =>

                                    <option key={x.moduleId} value={x.moduleId}>
                                        {x.sprint}

                                    </option>

                                )}
                            </select>
                            <Bar
                                width={"100%"}
                                height={"300"}
                                options={
                                    {
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        type: "bar",
                                        scales: {
                                            yAxes: [{
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: 'Story Points',
                                                    fontStyle:'bold'
                                                }
                                            }],
                                            xAxes: [{
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: 'Agile Squad',
                                                    fontStyle:'bold'
                                                }
                                            }],
                                        }
                                    }
                                }
                                data={individualData} />
                        </div>
                        */}
                        <div className="mt-2 ml-2 mb-3">
                            {/* add charts here */}
                            <h6>Individual Velocity</h6>
                            <select value={selectedIndVelocity} onChange={handleSelectChangeInd}>
                                {sprint.map(x =>

                                    <option key={x.moduleId} value={x.moduleId}>
                                        {x.sprint}

                                    </option>

                                )}
                            </select>
                            <Bar
                                data={individualVelocity}
                                width={"100%"}
                                height={"300"}
                                options={
                                    {
                                        responsive: true,
                                        maintainAspectRatio: false,

                                        plugins: {
                                            datalabels: {
                                                display: true,
                                                color: 'rgba(30, 130, 255, 4)'
                                            }
                                        },
                                        legend: {
                                            display: true
                                        },
                                        type: "bar",
                                        scales: {
                                            yAxes: [{
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: 'Story Points',
                                                    fontStyle: 'bold'
                                                }
                                            }],
                                            xAxes: [{
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: 'Agile Squads',
                                                    fontStyle: 'bold'
                                                }
                                            }],
                                        }

                                    }
                                }
                            />
                        </div>
                         {/* BurnUpChart start by Naveen in version 1.0.11 start*/}
                         <div className="mt-2 ml-2 mb-3">
                            <h6>Burn Up Chart</h6>
                            <Line
                                data={sprintBurnUpChart}
                                width={"100%"}
                                height={"300"}
                                options={
                                    {
                                        responsive: true,
                                        maintainAspectRatio: false,

                                        plugins: {
                                            datalabels: {
                                                display: true,
                                                color: 'rgba(30, 130, 255, 4)'
                                            }
                                        },
                                        legend: {
                                            display: true
                                        },
                                        type: "bar",
                                        scales: {
                                            yAxes: [{
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: 'Story Points',
                                                    fontStyle: 'bold'
                                                }
                                            }],
                                            xAxes: [{
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: 'Load Date',
                                                    fontStyle: 'bold'
                                                }
                                            }],
                                        }

                                    }
                                }
                            />
                        </div>
                        {/* BurnUpChart start by Naveen in version 1.0.11 start*/}
                        {/* Individual Workload Selection start by Naveen in version 1.0.9 start */}
                        <div className="mt-2 ml-2">
                            <h6>Individual Workload</h6>
                            {/* <h6 style={{ flexDirection: 'row' }}>
                            {state.employees.map((involved, index) => {
                                const input = involved.name;
                                const [name] = input.split('@');
                                return <Link style={{ textTransform: "capitalize", padding: '1px', textAlign: 'center', marginLeft: '10px' }}  onClick={()=>handleSelectChangeIndWorkload(involved.id)}>{name} </Link>
                            })} </h6> */}
                            <Select
                                className="form-control col-3"
                                style={{ width: 10, boarderRadius: 2 }}
                                placeholder="Select Squad Member"
                                onChange={(selectedOption) =>
                                    handleSelectChangeIndWorkload(selectedOption.value)
                                }
                                options={squadsList}
                            />
                            {/* add charts here */}
                            <Bar
                                data={individualWorkload}
                                width={"100%"}
                                height={"300"}
                                options={
                                    {
                                        responsive: true,
                                        maintainAspectRatio: false,

                                        plugins: {
                                            datalabels: {
                                                display: true,
                                                color: 'rgba(30, 130, 255, 4)'
                                            }
                                        },
                                        legend: {
                                            display: true
                                        },
                                        type: "bar",
                                        scales: {
                                            yAxes: [{
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: 'Story Points',
                                                    fontStyle: 'bold'
                                                }
                                            }],
                                            xAxes: [{
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: 'Sprint Cycle',
                                                    fontStyle: 'bold'
                                                }
                                            }],
                                        }

                                    }
                                }
                            />

                        </div>
                        {/* Individual Workload Selection start by Naveen in version 1.0.9 end*/}
                    </div>
                </div></div></div>
    )
}
