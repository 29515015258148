import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';

export const UserRoute = ({ component: Component, auth, ...rest }) => (
// eslint-disable-next-line
  auth = useSelector(state => state.auth),

  <Route
    {...rest}
    render={props =>

      (auth.isAuthenticated === true && (auth.user['role'] === "Emp" || auth.user['role'] === "Approver"|| auth.user['role'] === "Manager" || auth.user['role'] === "NA")) ? (
        <Component {...props} />
      ) : (
          <Redirect to="/login" />
        )
    }
  />

);
export const AdminRoute = ({ component: Component, auth, ...rest }) => (
  // eslint-disable-next-line
    auth = useSelector(state => state.auth),
  
    <Route
      {...rest}
      render={props =>
  
        (auth.isAuthenticated === true && (auth.user['role'] === "admin" || auth.user['role'] === "Admin")) ? (
          <Component {...props} />
        ) : (
            <Redirect to="/" />
          )
      }
    />
  
  );




