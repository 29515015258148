import React from 'react';
import '../../../Authentication/LandingPage/style.scss';
import { Link } from 'react-router-dom';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export default function ReleaseNote() {

    return(
        <div>
            <nav className="navbar sticky-top navbar-expand-lg navbar-light" style={{backgroundColor: "#47974b"}}>
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
      aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
    {/* eslint-disable-next-line */}
      <a className="navbar-brand"><Link to={{ pathname: "/" }}>
        {/* <img src="images/common/agile2.png" width="170" alt="" /></Link> */}
        <img className="agile-supportlogo" src="images/common/agile2.png" width="170" alt="" /></Link>

      </a>
      <form className="form-inline my-2 my-lg-0 ml-auto" style={{paddingTop: "16px"}}>
        {/* <!-- <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"> --> */}
        {/* eslint-disable-next-line */}
        <a className="btn my-2 my-sm-0 ml-auto" id="loginbtn" type="submit"><Link to={{ pathname: "/login" }}>Login</Link></a>
        {/* eslint-disable-next-line */}
        <a className="btn my-2 my-sm-0" id="signupbtn-support" type="submit"><Link style={{color:'white'}} to={{ pathname: "/" }}>Home </Link></a>
        {/* support page button */}
      </form>
    </div>
  </nav>

  <section id="section1">
      <div className="container">
          <div className="release-note mt-5">
              <h2>Agile24X7 release notes</h2>
          </div>
          {/* <div className="card releasenote-card mt-5 p-3">
              <h3>Agile24X7 version 1 release notes</h3>
              {/* <div className="releasenotes-versions mt-3">
                <ul>
                    <li>Agile24X7 version 1.0 release notes</li>
                    <li>Agile24X7 version 1.0.1 release notes</li>
                    <li>Agile24X7 version 1.0.2 release notes</li>
                    <li>Agile24X7 version 1.0.3 release notes</li>
                    <li>Agile24X7 version 1.0.4 release notes</li>
                    <li>Agile24X7 version 1.0.5 release notes</li>
                    <li>Agile24X7 version 1.0.6 release notes</li>
                    <li>Agile24X7 version 1.0.7 release notes</li>
                    <li>Agile24X7 version 1.0.8 release notes</li>
                </ul> 
              </div> 
          </div> */}
      </div>
  </section>


  {/* collapse view */}
  <div className="container mt-5">
  <div className="accordion" id="accordionExample">
  <div className="card">
    <div className="card-header" id="headingZero">
      <h2 className="mb-0">
        <button className="btn btn-link w-50 text-left" type="button" data-toggle="collapse" data-target="#collapseZero" aria-expanded="true" aria-controls="collapseZero">
            <h5 className="text-primary">Agile24X7 version 1.0.14 release notes</h5>      
        </button>
        <button className="btn btn-link w-50 text-right" type="button" data-toggle="collapse" data-target="#collapseZero" aria-expanded="true" aria-controls="collapseZero">
            <h5 className="text-primary">Date : 07-02-2021</h5>      
        </button>
      </h2>
    </div>

    <div id="collapseZero" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
      <div className="card-body">
          <div className="releasenote-cardbody">
            <div className="card-title text-capitalize mt-3 mb-3"><h6>Highlights</h6></div>
            <div className="list">
                <ul>
                    <li className="ml-1">The user Stories are marked as incomplete and given as a not finished on target time </li>
                    <li className="ml-1">A user story can be assigned smaller Tasks if user wants to subdivide the user stories </li>
                    <li className="ml-1">Change Status has been modified to 1 click </li>
                </ul>  
            </div>
          </div>
      </div>
    </div>
  </div>
  <div className="card">
    <div className="card-header" id="headingOne">
      <h2 className="mb-0">
        <button className="btn btn-link w-50 text-left" type="button" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="true" aria-controls="collapseOne">
            <h5 className="text-primary">Agile24X7 version 1.0.13 release notes</h5>      
        </button>
        <button className="btn btn-link w-50 text-right" type="button" data-toggle="collapse" data-target="#collapseTherteen" aria-expanded="true" aria-controls="collapseOne">
            <h5 className="text-primary">Date :  06-25-2021</h5>      
        </button>
      </h2>
    </div>
    <div id="collapseThirteen" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
      <div className="card-body">
          <div className="releasenote-cardbody">
            <div className="card-title text-capitalize mt-3 mb-3"><h6>Highlights</h6></div>
            <div className="list">
                <ul>
                <li className="ml-1">Email notification on all chat modules </li>
                    <li className="ml-1">Roadblocks can be assigned as a user story and in active sprint the story card will show the ID to the Roadblock User story </li>
                    <li className="ml-1">Changing user story Status in the backlog manually </li>
                </ul>  
            </div>
          </div>
      </div>
    </div>
  </div>

  <div className="card">
    <div className="card-header" id="headingOne">
      <h2 className="mb-0">
        <button className="btn btn-link w-50 text-left" type="button" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="true" aria-controls="collapseOne">
            <h5 className="text-primary">Agile24X7 version 1.0.12 release notes</h5>      
        </button>
        <button className="btn btn-link w-50 text-right" type="button" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="true" aria-controls="collapseOne">
            <h5 className="text-primary">Date :  06-11-2021</h5>      
        </button>
      </h2>
    </div>
    <div id="collapseTwelve" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
      <div className="card-body">
          <div className="releasenote-cardbody">
            <div className="card-title text-capitalize mt-3 mb-3"><h6>Highlights</h6></div>
            <div className="list">
                <ul>
                    <li className="ml-1">Mural Board </li>
                    <li className="ml-1">Chat notifications and uploading file </li>
                    <li className="ml-1">Add user story / Modify User story Browser compatibility issue -scrollable feature added in model </li>
                    <li className="ml-1">Backlogs Task Information model size is increased and (1.Active/ de active squad member, 2. Inactive member not showing in squad) </li>
                    <li className="ml-1">Epic : 1. Do not add empty epic while add/ modify the epic 2. Delete epic whenever epic doesn’t have user stories ( who ever added the epic that person able to delete it )</li>
                    <li className="ml-1">Bugs Fixed: 1. Delete option for Agile Squad member   2. Delete EPIC if it does not have user stories</li> 
                </ul>  
            </div>
          </div>
      </div>
    </div>
  </div>
  <div className="card">
    <div className="card-header" id="headingOne">
      <h2 className="mb-0">
        <button className="btn btn-link w-50 text-left" type="button" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="true" aria-controls="collapseOne">
            <h5 className="text-primary">Agile24X7 version 1.0.11 release notes</h5>      
        </button>
        <button className="btn btn-link w-50 text-right" type="button" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="true" aria-controls="collapseOne">
            <h5 className="text-primary">Date :  05-28-2021</h5>      
        </button>
      </h2>
    </div>

    <div id="collapseEleven" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
      <div className="card-body">
          <div className="releasenote-cardbody">
            <div className="card-title text-capitalize mt-3 mb-3"><h6>Highlights</h6></div>
            <div className="list">
                <ul>
                    <li className="ml-1">In Chat files and images upload/download </li>
                    <li className="ml-1">Loaders added while assign,modify and reassign user story </li>
                    <li className="ml-1">Bugs Fixed</li>  
                </ul>  
            </div>
          </div>
      </div>
    </div>
  </div>
  <div className="card">
    <div className="card-header" id="headingOne">
      <h2 className="mb-0">
        <button className="btn btn-link w-50 text-left" type="button" data-toggle="collapse" data-target="#collapseTen" aria-expanded="true" aria-controls="collapseOne">
            <h5 className="text-primary">Agile24X7 version 1.0.10 release notes</h5>      
        </button>
        <button className="btn btn-link w-50 text-right" type="button" data-toggle="collapse" data-target="#collapseTen" aria-expanded="true" aria-controls="collapseOne">
            <h5 className="text-primary">Date :  05-21-2021</h5>      
        </button>
      </h2>
    </div>

    <div id="collapseTen" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
      <div className="card-body">
          <div className="releasenote-cardbody">
            <div className="card-title text-capitalize mt-3 mb-3"><h6>Highlights</h6></div>
            <div className="list">
                <ul>
                  <li className="ml-1">Support page with version release notes</li>
                  <li className="ml-1">Sprint tab has removed from epics</li>
                  <li className="ml-1">In squad members dropdown list has been updated with all squad members</li>
                  <li className="ml-1">Bugs Fixed</li>
                </ul>  
            </div>
          </div>
      </div>
    </div>
  </div>
  <div className="card">
    <div className="card-header" id="headingOne">
      <h2 className="mb-0">
        <button className="btn btn-link w-50 text-left" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="true" aria-controls="collapseOne">
            <h5 className="text-primary">Agile24X7 version 1.0.9 release notes</h5>      
        </button>
        <button className="btn btn-link w-50 text-right" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="true" aria-controls="collapseOne">
            <h5 className="text-primary">Date :  05-07-2021</h5>      
        </button>
      </h2>
    </div>

    <div id="collapseNine" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
      <div className="card-body">
          <div className="releasenote-cardbody">
            <div className="card-title text-capitalize mt-3 mb-3"><h6>Highlights</h6></div>
            <div className="list">
                <ul>
                    <li className="ml-1">Backlog board with Drag and Drop</li>
                    <li className="ml-1">Reports for individual Workload</li>
                    <li className="ml-1">Reassign the Completed User Stories from Backlogs and Epic Level</li>
                    <li className="ml-1">Chat Bug solved in Active Sprint</li>
                    <li className="ml-1">Bugs Solved</li>
                </ul>  
            </div>
          </div>
      </div>
    </div>
  </div>
  
  <div className="card">
    <div className="card-header" id="headingOne">
      <h2 className="mb-0">
        <button className="btn btn-link w-50 text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            <h5 className="text-primary">Agile24X7 version 1.0.8 release notes</h5>      
        </button>
        <button className="btn btn-link w-50 text-right" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            <h5 className="text-primary">Date : 04-27-2021</h5>      
        </button>
      </h2>
    </div>

    <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
      <div className="card-body">
          <div className="releasenote-cardbody">
            <div className="card-title text-capitalize mt-3 mb-3"><h6>Highlights</h6></div>
            <div className="list">
                <ul>
                    <li className="ml-1">Modify Sprint in Backlogs</li>
                    <li className="ml-1">Reports</li>
                </ul>  
            </div>
          </div>
      </div>
    </div>
  </div>
  <div className="card">
    <div className="card-header" id="headingTwo">
      <h2 className="mb-0 w-100">
        <button className="btn btn-link collapsed w-50 text-left" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            <h5 className="text-primary">Agile24X7 version 1.0.7 release notes</h5>    
        </button>
        <button className="btn btn-link collapsed w-50 text-right" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            <h5 className="text-primary">Date : 04-23-2020</h5>    
        </button>
      </h2>
    </div>
    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
    <div className="card-body">
          <div className="releasenote-cardbody">
            <div className="card-title text-capitalize mt-3 mb-3"><h6>Highlights</h6></div>
            <div className="list">
                <ul>
                    <li className="ml-1">Agile Secure version with CA.</li>
                    <li className="ml-1">Epic completion without dependency of user story creation.</li>
                    <li className="ml-1">Adding a key for epic and project.</li>
                    <li className="ml-1">Sprint cycle time change to 11.59pm PST on the end date of Sprint.</li>
                    <li className="ml-1">Date format changed in archive to mm/dd/yy.</li>
                    <li className="ml-1">In blocked list,show only roadblocks of the current sprint(present sprint).</li>
                    <li className="ml-1">In comment box,the last message of comment box to be shown first.</li>
                    <li className="ml-1">Removed mobile validation in agile squad while add/modify squad member.</li>
                    <li className="ml-1">Check if all the user stories actions are done by user in sprint board ,sprint board cannot switch.</li>
                </ul>  
            </div>
          </div>
      </div>
    </div>
  </div>
  <div className="card">
    <div className="card-header" id="headingThree">
      <h2 className="mb-0">
        <button className="btn btn-link collapsed w-50 text-left" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        <h5 className="text-primary">Agile24X7 version 1.0.6 release notes</h5>      
        </button>
        <button className="btn btn-link collapsed w-50 text-right" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        <h5 className="text-primary">Date : 04-16-2021</h5>      
        </button>
      </h2>
    </div>
    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
    <div className="card-body">
          <div className="releasenote-cardbody">
            {/* <h6>Date : 04-16-2021</h6> */}
            <div className="card-title text-capitalize mt-3 mb-3"><h6>Highlights</h6></div>
            <div className="list">
                <ul>
                    <li className="ml-1">No Epic Dependency.</li>
                    <li className="ml-1">Search Bar added to backlog, and Archive.</li>
                    <li className="ml-1">Alphabetic order for Agile project Names.</li>
                    <li className="ml-1">Backlog part in two cases i.e. with epic and without epic.</li>
                    <li className="ml-1">Roadblocks on current sprint board.</li>
                    <li className="ml-1">In chatbox date format has changed.</li>
                    <li className="ml-1">Activate and deactivate Squad member.</li>
                    <li className="ml-1">Comment box changes.</li>
                    <li className="ml-1">Date for Version  has changed.</li>
                    <li className="ml-1">completion of epic.</li>
                    <li className="ml-1">Add validations.</li>
                    <li className="ml-1">Block list should be displayed on scrum board.</li>
                    <li className="ml-1">Give an option to assign epic name while adding userstory.</li>
                </ul>  
            </div>
          </div>
      </div>
    </div>
  </div>
  <div className="card">
    <div className="card-header" id="headingFour">
      <h2 className="mb-0">
        <button className="btn btn-link collapsed w-50 text-left" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
        <h5 className="text-primary">Agile24X7 version 1.0.5 release notes</h5>      
        </button>
        <button className="btn btn-link collapsed w-50 text-right" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        <h5 className="text-primary">Date : 04-09-2021</h5>      
        </button>
      </h2>
    </div>
    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
    <div className="card-body">
          <div className="releasenote-cardbody">
            {/* <h6>Date : 23 March 2021</h6> */}
            <div className="card-title text-capitalize mt-3 mb-3"><h6>Highlights</h6></div>
            <div className="list">
                <ul>
                    <li className="ml-1">Forgot Password</li>
                    <li className="ml-1">Landing page</li>
                    <li className="ml-1">Registration page</li>
                    <li className="ml-1">Backlog Board with active and feature sprints user stories and backlogs</li>
                    <li className="ml-1">Reset Password</li>
                    <li className="ml-1">New Squad member to be added can be picked from existing data</li>
                    <li className="ml-1">Sprint card details</li>
                    <li className="ml-1">Roles Displayed with Squad Name in the chatbox</li>
                </ul>  
            </div>
          </div>
      </div>
    </div>
  </div>
  <div className="card">
    <div className="card-header" id="headingFive">
      <h2 className="mb-0">
        <button className="btn btn-link collapsed w-50 text-left" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
        <h5 className="text-primary">Agile24X7 version 1.0.4 release notes</h5>      
        </button>
        <button className="btn btn-link collapsed w-50 text-right" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        <h5 className="text-primary">Date : 03-26-2021</h5>      
        </button>
      </h2>
    </div>
    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
    <div className="card-body">
          <div className="releasenote-cardbody">
            {/* <h6>Date : 23 March 2021</h6> */}
            <div className="card-title text-capitalize mt-3 mb-3"><h6>Highlights</h6></div>
            <div className="list">
                <ul>
                    <li className="ml-1">Active sprint board</li>
                    <li className="ml-1">Backlog Board</li>
                    <li className="ml-1">Archive Reports</li>
                    <li className="ml-1">Removed Dashboard</li>
                    <li className="ml-1">Display Release Date for every version/release</li>
                    <li className="ml-1">Sprint should have a display of Current Active Sprint user-stories</li>
                </ul>  
            </div>
          </div>
      </div>
    </div>
  </div>
  <div className="card">
    <div className="card-header" id="headingSix">
      <h2 className="mb-0">
        <button className="btn btn-link collapsed w-50 text-left" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
        <h5 className="text-primary">Agile24X7 version 1.0.3 release notes</h5>      
        </button>
        <button className="btn btn-link collapsed w-50 text-right" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
        <h5 className="text-primary">Date : 03-12-2021</h5>      
        </button>
      </h2>
    </div>
    <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
    <div className="card-body">
          <div className="releasenote-cardbody">
            {/* <h6>Date : 23 March 2021</h6> */}
            <div className="card-title text-capitalize mt-3 mb-3"><h6>Highlights</h6></div>
            <div className="list">
                <ul>
                    <li className="ml-1">Switching between the projects from inside the application. </li>
                    <li className="ml-1">Add project after login. </li>
                    <li className="ml-1">Remove the corp code and user type selection while login. </li>
                    <li className="ml-1">Email  notification while registered or add squad and add new project. </li>
                </ul>  
            </div>
          </div>
      </div>
    </div>
  </div>
  <div className="card">
    <div className="card-header" id="headingSeven">
      <h2 className="mb-0">
        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
        <h5 className="text-primary">Agile24X7 version 1.0.2 release notes</h5>      
        </button>
      </h2>
    </div>
    <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
    <div className="card-body">
          <div className="releasenote-cardbody">
            {/* <h6>Date : 23 March 2021</h6> */}
            <div className="card-title text-capitalize mt-3 mb-3"><h6>Highlights</h6></div>
            <div className="list">
                <ul>
                    <li className="ml-1">Removed admin role from application and added three roles(i.e.scrum master,contributor,product owner).</li>
                    <li className="ml-1">User stories page and Backlogs page are merged into single page as backlogs.</li>
                </ul>  
            </div>
          </div>
      </div>
    </div>
  </div>
  <div className="card">
    <div className="card-header" id="headingEight">
      <h2 className="mb-0">
        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
        <h5 className="text-primary">Agile24X7 version 1.0.1 release notes</h5>      
        </button>
      </h2>
    </div>
    <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
    <div className="card-body">
          <div className="releasenote-cardbody">
            {/* <h6>Date : 23 March 2021</h6> */}
            <div className="card-title text-capitalize mt-3 mb-3"><h6>Highlights</h6></div>
            <div className="list">
                <ul>
                    <li className="ml-1">PTMS core version with properties file modification for Agile </li>
                </ul>  
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
</div>


    {/* End collapse view */}



    <footer id="footer-releasenote">
        <div>
            © Copyright 2021. All Rights Reserved.
        </div>
    </footer>
</div>
    )
}