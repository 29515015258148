import React, { useEffect, useState, useReducer } from "react";
import Board, { moveCard } from "@lourenci/react-kanban";
import { getAllTaskMessages, getActiveSprints, getUnassigned, getCurrentSprint, getPending, getInvolvedEmployees, getSprints } from './network';
import { useSelector } from "react-redux";
import { tasksReducer, initialState } from './tasksReducer';
import SideBar from '../Utility/SideNav';
import RootLoader from '../../Common/Loader/RootLoader';
import TopNav from '../Utility/TopNav'
import AddMainTask from '../../Common/TasksModals/addMainTask';
import { NEWMAINTASK, EDIT, VIEW_DETAILS, ADD_TO_EPIC, REASSIGN, ADD_TO_SPRINT,VIEWSUBTASKS,NEWSUBTASK } from '../../Common/Headers';
import { Link } from 'react-router-dom';
import AddToSprint from '../Backlogs/addToSprint';
import MainTaskInfo from '../../Common/TasksModals/mainTaskInfo';
import ModifyMainTask from '../../Common/TasksModals/modifyMainTask';
import Moment from 'moment';
import Select from 'react-select';
import { activeSprint } from "./actions";
import AddToKanban from "./addToKanban";
import ModifyModule from '../../Common/Modules/modifyModule';
import MainTaskChatBox from '../../Common/ChatMainTask';
import ChangeStatus from "./changeStatus";
import AddSubTask from '../../Common/SubTaskModals/addSubTask';




export default function Kanbanboard() {
    const getUser = useSelector(state => state.auth)
    const [state, dispatch] = useReducer(tasksReducer, initialState)
    const [open, setOpen] = useState({ status: false, index: 0 })
    const [cardInfo, setCardInfo] = useState()
    const [searchWord, setSearchWord] = useState('')
    const [staticWord, setStaticWord] = useState('ACTIVE SPRINT')

    useEffect(() => {
        getActiveSprints(dispatch, getUser.user)
        getSprints(dispatch, getUser.user)
        getPending(dispatch, getUser.user)
        getUnassigned(dispatch, getUser.user)
        getAllTaskMessages(dispatch, getUser.user)
        // eslint-disable-next-line
    }, [])
    var sprintDetails = [];
    state.sprints.map((sprints) => {
        return (
            sprintDetails.push({ 'value': sprints.moduleId, 'moduleName': sprints.moduleDesc, 'startDate': sprints.startDate, 'targetDate': sprints.targetDate, 'label': (getUser.user.corp).substring(0, 3).toUpperCase().concat('-', sprints.moduleId, '   ', sprints.moduleDesc) })
        );
    })
    const backlogs = state.pendingTasks.concat(state.unassignedTasks)
    const handleOpen = (action, data, sno) => {
        var info
        setOpen({ status: true, action: action });
        if (action === "add") {
            var status = "backlog_addUser"

            info = { view: status }
        }
        else if (action === "taskInfo") {
            var view_status = "taskInfo"
            info = {
                view: view_status,
                projectName: data.projectitle,
                epicId: data.ideano,
                moduleName: data.moduletitle,
                moduleId: data.userstoryModuleId,
                title: data.tasktitle,
                description: data.taskdescription,
                taskId: (getUser.user.corp).substring(0, 3).toUpperCase().concat('-', data.id),
                createdDate: data.assignedon,
                taskProgress: data.taskStatus,
                storyPoints: data.storyPoints,
                acceptanceCriteria: data.acceptanceCriteria,
                assignedTo: data.assigntto,
                assignedBy: data.assignby,
                completedDate: data.modifiedDate,
                completedStatus: data.completeStatus,
                targetDate:data.targetDate
            }
        }
        else if (action === "addSubtask") {
            info = { mainTaskId: data.id, 
                action: action, 
                moduleId: data.userstoryModuleId, 
                title: data.tasktitle,
                ideaId: data.ideano, 
                assignedTo: data.assignedTo,
                targetDate:data.targetDate }
        }
        else if (action === "modify") {
            info = {
                projectName: data.projectitle,
                moduleId: data.userstoryModuleId,
                ideaId: data.ideano,
                title: data.tasktitle,
                description: data.taskdescription,
                taskId: data.id,
                acceptanceCriteria: data.acceptanceCriteria,
                storyPoints: data.storyPoints,
                userDetails: data.assigntto,
                id: data.assignedTo,
                priorityLevel: data.priorityLevel,
                targetDate:data.targetDate,
                action: action,
                sprintTargetDate: state.activeSprint.targetDate

            }
        }
        else if (action === "reassign") {
            info = {
                projectName: data.projectitle,
                moduleId: data.userstoryModuleId,
                ideaId: data.ideano,
                title: data.tasktitle,
                description: data.taskdescription,
                taskId: data.id,
                acceptanceCriteria: data.acceptanceCriteria,
                storyPoints: data.storyPoints,
                userDetails: data.assigntto,
                id: data.assignedTo,
                priorityLevel: data.priorityLevel,
                action: action

            }
        }
        else if (action === "add_to_epic") {
            var epic_status = "epic"
            info = {
                view: epic_status,
                projectid: data.ideano,
                taskId: data.id,
            }
        }
        else if (action === "add_to_kanban") {
            info = { action: action, taskId: data, sprintId: state.activeSprint.moduleId, sprintDesc: state.activeSprint.moduleDesc,targetDate: state.activeSprint.targetDate
            }
        }
        else if (action === "remove_from_sprint") {
            info = { action: action, taskId: data, sprintId: state.activeSprint.moduleId, sprintDesc: state.activeSprint.moduleDesc }
        }
        else if (action === "changeStatus") {
            info = { title: data.tasktitle,action: action, taskId: data.id, activeStatus: data.activeStatus, completeStatus: data.completeStatus, sprintId: state.activeSprint.moduleId }
        }
        else if (action === 'modify_sprint') {

            info = {
                id: state.activeSprint.moduleId,
                title: state.activeSprint.moduleDesc,
                targetDate: state.activeSprint.targetDate,
                startDate: state.activeSprint.startDate

            }

        }
        else if (action === 'maintask') {
            info = { action: action, id: data, sno: sno }
        }
        else {
            info = { action: action, taskId: data.id }
        }
        setCardInfo(info)
    };
    const handleClose = () => {
        setOpen({ status: false, index: 0 })
        getSprints(dispatch, getUser.user)
        getInvolvedEmployees(dispatch, getUser.user, state.activeSprint.moduleId);
        getCurrentSprint(dispatch, getUser.user, state.activeSprint.moduleId)
        getPending(dispatch, getUser.user)
        getUnassigned(dispatch, getUser.user)
        getAllTaskMessages(dispatch, getUser.user)
    };
    const handleModifySprint = (title) => {
        setOpen({ status: false, index: 0 })
        dispatch(activeSprint(title))
        getSprints(dispatch, getUser.user)
        getInvolvedEmployees(dispatch, getUser, state.activeSprint.moduleId);
        getCurrentSprint(dispatch, getUser.user, state.activeSprint.moduleId)
        getPending(dispatch, getUser.user);
        getUnassigned(dispatch, getUser.user)
        getAllTaskMessages(dispatch, getUser.user);

    }
    const handleModalClose = () => {
        setOpen({ status: false, index: 0 });

    }
    const board = {
        columns: [
            {
                id: 1,
                title: state.sprints.length > 0 ? <div style={{ flexDirection: 'row' }}>
                    <div className="col-12 row p-2">
                        <h4 className="card-title mt-2 col-8" style={{ overflowWrap: "break-word", color: 'blue', backgroundColor: 'transparent' }}> <b>{(getUser.user.corp).substring(0, 3).toUpperCase().concat('   ', state.activeSprint.moduleDesc, '  [', Moment(state.activeSprint.startDate).format('MM.DD.YYYY'), '-', Moment(state.activeSprint.targetDate).format('MM.DD.YYYY'), ']')}<span className="ml-3" data-toggle="tooltip" data-placement="bottom" title="Modify Sprint" onClick={(event) => handleOpen("modify_sprint")}><img src="images/common/edit.svg" style={{ width: '18px', height: '18px' }} alt="" /></span>{'  ' + staticWord}</b></h4>

                        <Select
                            className="form-control col-4"
                            placeholder={(getUser.user.corp).substring(0, 3).toUpperCase().concat('-', state.activeSprint.moduleId, '   ', state.activeSprint.moduleDesc)}
                            value={sprintDetails.value}
                            onChange={(selectedOption) => {
                                getCurrentSprint(dispatch, getUser.user, selectedOption.value)
                                var title = { 'moduleId': selectedOption.value, 'moduleDesc': selectedOption.moduleName, 'startDate': selectedOption.startDate, 'targetDate': selectedOption.targetDate }
                                dispatch(activeSprint(title))
                                setStaticWord('')


                            }}
                            options={sprintDetails}

                        />
                    </div>
                </div> : null,
                /* eslint-disable-next-line */
                cards: state.currentTasks.filter((val) => {
                    if (val.tasktitle.toLowerCase().includes(searchWord.toLowerCase()) || val.assigntto.toLowerCase().includes(searchWord.toLowerCase())) {
                        return val
                    }
                }
                )
            },
            {
                id: 2,
                title: backlogs.length > 0 ? <h4 className="card-title" style={{ overflowWrap: "break-word", color: 'blue', backgroundColor: 'transparent' }}> <b>Backlogs</b></h4> : null,

                /* eslint-disable-next-line */
                cards: backlogs.filter((val) => {
                    if (val.tasktitle.toLowerCase().includes(searchWord.toLowerCase()) || val.assigntto.toLowerCase().includes(searchWord.toLowerCase())) {
                        return val
                    }
                }
                )
            },
        ]
    };
    const getMessagesCount = (id, msg, empId) => {
        const msgCount = msg.filter(message => message.readBy.split(",").indexOf(empId) === -1 && message.messagedBy !== empId && message.groupId === id).map((messages, i) => {
            // eslint-disable-next-line
            return i, messages
        })
        return (
            <i>
                {/* {msgCount.length > 0 ? msgCount.length : null} */}
                {
                    msgCount.length > 0 ?
                        <div className="row">
                            <img src="images/common/chat.svg" alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-5px" }} onClick={(event) => handleOpen("maintask", id, msgCount)} />
                            <span style={{ color: 'red', fontWeight: "bold" }}>{msgCount.length}</span>
                        </div>
                        // <img src="images/common/chat.svg" alt="logo" style={{ width: '20px', height: '20px', backgroundColor: 'green' }} onClick={(event) => handleOpen("maintask", id, msgCount)} />
                        :
                        <div className="row">
                            <img src="images/common/chat.svg" alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-5px" }} onClick={(event) => handleOpen("maintask", id, msgCount)} />
                        </div>
                }
            </i>
        )
    }

    function ControlledBoard() {

        const [controlledBoard, setBoard] = useState(board);
        function handleCardMove(_card, source, destination) {
            console.log(JSON.stringify(source) + '' + JSON.stringify(destination))
            if (JSON.stringify(destination.toColumnId) === '1' && JSON.stringify(source.fromColumnId) === '2') {
                const updatedBoard = moveCard(controlledBoard, source, destination);
                setBoard(updatedBoard)
                handleOpen("add_to_kanban", _card.id)
            }

            else if (JSON.stringify(destination.toColumnId) === '2' && JSON.stringify(source.fromColumnId) === '1') {
                const updatedBoard = moveCard(controlledBoard, source, destination);
                setBoard(updatedBoard)
                handleOpen("remove_from_sprint", _card.id)

            }
        }
        return (
            <div className="col-12">
                <Board
                    onCardDragEnd={handleCardMove}
                    renderCard={({ targettime, currenttime, backlogs, activeStatus, assignedTo, tasktitle, moduletitle, taskdescription, userstoryModuleId, assignedon, taskStatus, assignby, acceptanceCriteria, modifiedDate, ideano, projectitle, storyPoints, id, priorityLevel, assigntto, completeStatus }) => {
                        const [name] = assigntto.split('@')
                        const data = { 'activeStatus': activeStatus, 'tasktitle': tasktitle, 'projectitle': projectitle, 'ideano': ideano, 'id': id, 'moduletitle': moduletitle, 'userstoryModuleId': userstoryModuleId, 'taskdescription': taskdescription, 'assignedon': assignedon, 'taskStatus': taskStatus, 'storyPoints': storyPoints, 'acceptanceCriteria': acceptanceCriteria, 'assigntto': assigntto, 'assignby': assignby, 'modifiedDate': modifiedDate, 'completeStatus': completeStatus, 'priorityLevel': priorityLevel, 'assignedTo': assignedTo,'targetDate':targettime,'currentDate':currenttime }
                        return (
                            <div className="card col-12 " style={(Moment(Date.now()).format("YYYY-MM-DD") > targettime && completeStatus === 'pending' && targettime !== '0000-00-00') ? { backgroundColor:'#f58484' } : null}>
                            {/* <div className="card col-12 " > */}

                                <div class="d-flex col-12"  >
                                    <div class="pt-2 " >
                                        <b style={{ width: '500px', cursor: 'pointer', paddingTop: 10 }} onClick={(event) => handleOpen("taskInfo", data)}> {completeStatus === 'pending' ? <p style={{ width: 750, color: 'black' }}>{(getUser.user.corp).substring(0, 3).toUpperCase()}{id}{'-'}{tasktitle}</p> : <del> <p style={{ width: 750 }}>{(getUser.user.corp).substring(0, 3).toUpperCase()}{id}{'-'}{tasktitle}</p></del>}</b>
                                    </div>
                                    <div style={{ backgroundColor: '#81B622', cursor: 'pointer', color: 'white', marginLeft: 10, padding: '3px', marginTop: 5, marginBottom: 5, borderRadius: '90px', fontSize: '15px', width: '25px', textAlign: 'center', }} data-toggle="tooltip" data-placement="bottom" title={name}>{name.substring(0, 1).toUpperCase()}</div>

                                    <p style={{ backgroundColor: '#5CDB95', cursor: 'pointer', color: 'white', marginLeft: 10, padding: '3px', marginTop: 5, marginBottom: 5, width: '100px', textAlign: 'center' }} data-toggle="tooltip" data-placement="bottom" title={ideano === '0' ? 'NA' : projectitle}>{ideano === '0' ? 'NA' : projectitle.substring(0, 11)}</p>
                                    <p style={{ backgroundColor: 'teal', color: 'white', marginLeft: 10, padding: '3px', marginTop: 5, marginBottom: 5, width: '30px' }}>P:{priorityLevel}</p>
                                    <p style={{ backgroundColor: '#6495ED', color: 'white', marginLeft: 10, marginTop: 5, marginBottom: 5, padding: '3px', borderRadius: '70px', width: '25px', textAlign: 'center' }}>{storyPoints}</p>
                                    <div className="dropdown show" style={{ cursor: 'pointer', marginLeft: 15, marginRight: 15, padding: '5px', marginTop: 5 }}>
                                        {/* eslint-disable-next-line */}
                                        <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false">
                                            <img src="images/common/actionmenu.png" alt="logo" style={{ width: '15px', height: '15px', borderRadius: '0' }} />
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0' }}>

                                            <div>
                                                {(completeStatus === 'pending') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#F4A896", color: 'white' }} onClick={(event) => handleOpen("add_to_epic", data)}>{ADD_TO_EPIC}</button> : null}

                                                <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#203B5A", color: 'white' }} onClick={(event) => handleOpen("taskInfo", data)}>{VIEW_DETAILS}</button>
                                                {(completeStatus === 'pending') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#76C54E", color: 'white' }} onClick={(event) => handleOpen("modify", data)}>{EDIT}</button> : null}
                                                <button className="dropdown-item badge badge-pill badge-primary text-center" style={{ backgroundColor: "#9a7b78", color: 'white' }}><Link to={{ pathname: '/viewSubTasks', state: { id: id, title: tasktitle, moduleId: userstoryModuleId, ideaId: ideano } }} style={{ color: 'white' }}>{VIEWSUBTASKS}</Link></button>
                                                {(completeStatus === 'pending') ? <button className="dropdown-item badge badge-pill badge-secondary text-center" style={{ backgroundColor: "#630436", color: 'white' }} onClick={(event) => handleOpen("addSubtask", data)}>{NEWSUBTASK}</button> : null}
                                                {(completeStatus === 'pending') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={(event) => handleOpen("add_to_sprint", data)}>{ADD_TO_SPRINT}</button> : null}
                                                {(completeStatus === 'pending' && backlogs === '1' && activeStatus !== '-1') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#5cb0bd", color: 'white' }} onClick={(event) => handleOpen("changeStatus", data)}>Change Status</button> : null}
                                                {(completeStatus === 'completed') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "orange", color: 'white' }} onClick={(event) => handleOpen("reassign", data)}>{REASSIGN}</button> : null}
                                            </div>

                                        </div>
                                    </div>
                                    <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '10px', padding: "0" }} >
                                        {
                                            getMessagesCount(id, state.allMessages, getUser.user.empId)
                                        }
                                    </button>
                                </div>


                            </div>
                        )
                    }}
                >
                    {controlledBoard}
                </Board>
            </div>
        );
    }



    return (
        <div className="container-scroller">
            <TopNav />
            <div className="container-fluid page-body-wrapper">
                <SideBar />
                <div className="main-panel">
                    <div className="mt-2">
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    {state.sprints.length > 0 ?
                                        <div>

                                            <h6>  {state.involvedEmployees.map((employee, index) => {
                                                const input = employee.assignedTo;
                                                const [name] = input.split('@');
                                                return <Link to={{ pathname: '/userMaintasks', state: { dashboard: 'backlogs', id: state.involvedEmployees[index].assignedToId, moduleid: state.activeSprint.moduleId } }} style={{ textTransform: "capitalize", padding: '1px', textAlign: 'center', marginLeft: '10px', color: 'green' }}>{name}-{employee.points}</Link>
                                            })}</h6>
                                        </div> : null}
                                    <div className="d-flex justify-content-end mb-2 " >
                                        <div style={{ flexDirection: 'column' }}>
                                            <div style={{ marginLeft: 380 }}>

                                                <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" onClick={() => handleOpen("add")}> <img src="images/common/add.png" alt="logo" style={{ width: '20px', height: '20px' }} /><span className="m-1">{NEWMAINTASK}</span></button>
                                                {
                                                    open.action === "add" ? <AddMainTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                                    /> : null
                                                }

                                            </div>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="text" style={{ color: 'black', marginTop: '3px', fontSize: '13px', paddingRight: 10 }}>Search:</span>
                                                </div>
                                                <input type="text" class="form-control" style={{ backgroundColor: 'transparent', borderBottom: '2px solid black', borderTop: '2px solid black', borderLeft: '12x solid black', borderRight: '2px solid black', marginTop: '-5px', width: '450px', height: '35px' }}
                                                    onChange={(event) => setSearchWord(event.target.value)}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    {state.isLoading ? <RootLoader /> :

                                        <ControlledBoard />

                                    }
                                    {
                                        open.action === "add_to_sprint" ? <AddToSprint open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {
                                        open.action === "taskInfo" ? <MainTaskInfo open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {
                                        (open.action === "modify" || open.action === "reassign") ? <ModifyMainTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {
                                        open.action === "add_to_epic" ? <AddToSprint open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {
                                        (open.action === "add_to_kanban" || open.action === "remove_from_sprint") ? <AddToKanban open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {
                                        open.action === "modify_sprint" ? <ModifyModule open={open.status} handleClose={handleModifySprint} data={cardInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {
                                        open.action === "maintask" ? <MainTaskChatBox open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {
                                        open.action === "addSubtask" ? <AddSubTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {
                                        open.action === "changeStatus" ? <ChangeStatus open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


