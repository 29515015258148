import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';
import { getRoleCount } from "../Common/LocalStorage";

const PublicRoute = ({ component: Component, auth, ...rest }) => (
  // eslint-disable-next-line
  auth = useSelector(state => state.auth),
  //  console.log("publicRoute" + auth.isAuthenticated + " hi  " + JSON.stringify(auth)+"Role"+auth.user['role']+getRoleCount('roleCount')),

  <Route
    {...rest}
    render={props =>

      (auth.isAuthenticated) === false ? (<Component {...props} />) : (
        // <Redirect to="/dashboard" />
        (auth.user['role'] === "Emp" || auth.user['role'] === "Approver"|| auth.user['role'] === "Manager") ? (<Redirect to="/sprints" />) : (getRoleCount('roleCount') >= 1) ? (<Redirect to="/sprints" />) : (<Redirect to='/instruction'/>))
    }
  />

);
export default PublicRoute;
