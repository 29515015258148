import React, { useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { tasksReducer, initialState } from './tasksReducer';
import { useSelector } from 'react-redux';
import { addToKanban, removeFromSprint } from './network';
import * as actions from './actions'





const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

//For Add New Project
export default function AddToKanban(props) {
    const getUser = useSelector(state => state.auth)

    const [state, dispatch] = useReducer(tasksReducer, initialState);
    console.log(props.data.currentDate)
    const classNames = useStyles();


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className="modal-content col-5 p-2" style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    <h5 class="modal-title p-2">MOVE ISSUE</h5>
                                </div>
                                <div className="modal-body">
                                    <b style={{ backgroundColor: '#FFFACD' }}>Sprint scope will be affected by this action</b>
                                    {(props.data.action === 'add_to_kanban') ? <p style={{ marginTop: '10px' }}>
                                        <b>{(getUser.user.corp).substring(0, 3).toUpperCase()}{'-'}{props.data.taskId}</b> will be moved from backlog to sprint <b>{(getUser.user.corp).substring(0, 3).toUpperCase()}{'-'}{props.data.sprintId}{' '}{props.data.sprintDesc}</b>
                                    </p> : <p style={{ marginTop: '10px' }}>
                                        <b>{(getUser.user.corp).substring(0, 3).toUpperCase()}{'-'}{props.data.taskId}</b> will be moved from  sprint <b>{(getUser.user.corp).substring(0, 3).toUpperCase()}{'-'}{props.data.sprintId}{' '}{props.data.sprintDesc}</b> to backlog
                                    </p>}




                                    {(props.data.action === 'add_to_kanban') ?
                                        <div> <label for="target_date" class="col-form-label pt-0" style={{ width: '150px' }}>Target Date<span style={{ color: "red" }} >*</span></label>
                                            <input type="date" class="form-control" id="target_date" name="target_date" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px', marginTop: '-10px' }}
                                                value={state.targetDate.value}
                                                onChange={(event) => dispatch(actions.targetDate(event.target.value))} />

                                        </div> : null
                                    }

                                    
                                </div>


                                <div class="modal-footer justify-space-between">
                                    <button type="button" class="btn btn-outline-danger" onClick={props.handleModalClose} style={{ borderRadius: '20px' }}>Cancel</button>
                                    {(props.data.action === 'add_to_kanban') ?

                                        <button type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={(event) => { addToKanban(props.data.targetDate,state.targetDate.value, props.data.taskId, props.data.sprintId, state, dispatch, getUser.user, props.handleClose) }}>Confirm</button> :
                                        <button type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={(event) => { removeFromSprint(props.data.taskId, props.data.sprintId, state, dispatch, getUser.user, props.handleClose) }}  >Confirm</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}
