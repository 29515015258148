import React, { useState, useReducer, useEffect } from 'react'
import { Redirect, Link } from 'react-router-dom';
import RootLoader from '../../Common/Loader/RootLoader'
import './register.scss';
import { updateEmail } from './actions';
import { reducer, initialState } from './reducer';
import { register } from './network';
import { updateNewPassword, updateConfirmPassword } from './actions';

export default function Register(props) {
    // console.log(props.location.data)
    const [enablePassword, setEnablePassword] = useState(false)
    const [enableConfirmPassword, setEnableConfirmPassword] = useState(false)
    const [state, dispatch] = useReducer(reducer, initialState)

    const handlePasswordView = () => {
        setEnablePassword(!enablePassword)
    }
    const handleConfirmPasswordView = () => {
        setEnableConfirmPassword(!enableConfirmPassword)
    }
    useEffect(() => {
            if(props.location.data){
            dispatch(updateEmail(props.location.data))
        }
    },[props])

    

    if (state.emailredirect.value === "emailredirect") {

        return <Redirect to="/login" />
    }

        return (
            <div className="bg">
     <div className="container">

        <div className="row" style={{marginTop: "-85px"}}>
            <div className="col-sm pt-4" id="signup">
              <img src="images/common/loginimg.jpg" width="700" alt="" />
            </div>
            <div className="col-sm" id="form">
              <center>  <img src="images/common/agile3.png" id="logo" alt="" />
             <h4 id="head2">Sign up for your account</h4>
             <form >
                <div className="form-group">
                    <input type="email" 
                    id="confirm-password"
                    value={state.email.value}
                    onChange={(event) => {
                               dispatch(updateEmail(event.target.value));
                           }}  
                     className="form-control" placeholder="Enter email"
                      required/>
                      <span style={{ color: "red", fontSize: '12px' }}>{state.email.errorStatus ? state.email.errormessage : ""}</span>
                </div>

                <div className="form-group">
                    <input type={enablePassword ? 'text' : 'password'}  
                    className="form-control" placeholder="Create password"
                    autoComplete="new-password"
                    id="new-password"
                    value={state.newpassword.value}
                    onChange={(event) => {
                        dispatch(updateNewPassword(event.target.value));
                    }}
                    required />
                    {!enablePassword && <img className="passwordimg" src="images/common/hide.svg" alt="logo" style={{ width: '20px', height: '20px' }} onClick={() => handlePasswordView()} />}
                    {enablePassword && <img className="passwordimg" src="images/common/show.svg" alt="logo" style={{ width: '20px', height: '20px' }} onClick={() => handlePasswordView()} />}
                    <span style={{ color: "red", fontSize: '12px' }}>{state.newpassword.errorStatus ? state.newpassword.errormessage : ""}</span>
                    <div id="error-pwd"></div>
  
                </div>
				
				 <div className="form-group">
                    <input type={enableConfirmPassword ? 'text' : 'password'} className="form-control" 
                    placeholder="Confirm Password*" 
                    id="confirm-password"
                    value={state.confirmpassword.value}
                     onChange={(event) => {
                       dispatch(updateConfirmPassword(event.target.value));
                       }} 
                    required/>
                    {!enableConfirmPassword && <img className="passwordimg" src="images/common/hide.svg" alt="logo" style={{ width: '20px', height: '20px' }} onClick={() => handleConfirmPasswordView()} />}
                    {enableConfirmPassword && <img className="passwordimg" src="images/common/show.svg" alt="logo" style={{ width: '20px', height: '20px' }} onClick={() => handleConfirmPasswordView()} />}
                    {state.confirmpassword.errorStatus ? <span style={{ color: "red", fontSize: '12px' }}>{state.confirmpassword.errormessage}</span> : (state.newpassword.value === state.confirmpassword.value) && state.newpassword.value !== "" && state.confirmpassword.value !== "" ? <span style={{ color: "green", fontSize: '12px' }}> password matched </span> : state.confirmpassword.value === "" ? " " : <span style={{ color: "red", fontSize: '12px' }}>password not matched</span>}
  <div className="error">
  </div>
                </div>
                    {state.isLoading ? <RootLoader /> :
			<button className="btn my-2 my-sm-0" id="continuebtn" type="submit"
            onClick={(event) => { register(state, dispatch) }}  >Sign Up</button>}
   </form>
   {/*  eslint-disable-next-line */}
            <a>Already have an account? </a><br/>
            {/*  eslint-disable-next-line */}
            <a type="submit"><Link to={{ pathname: "/login" }}>Login</Link></a>
        </center>
        <br/>
            </div>
          </div>

     </div>
 </div>      
           
        )
    }