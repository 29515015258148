import { PROFILE_INFO, IS_LOADED, IS_LOADING, USER_NAME, MOBILE_NUMBER, EMAIL, REDIRECT, DESIGNATION,TEAM,ROLE, STATUS, EMP_ID } from "./actions";

const initialValue = {
    value: "",
    errorStatus: false,
    errorMessage: "Please Enter valid ",
};
function validateMobileNumber(mobile) {
    var reg = /^[0]?[6789]\d{9}$/
    return (mobile.length === 10 && reg.test(mobile)) ? true : false
}
export const initialState = {
    empId: initialValue,
    username: initialValue,
    fullName: initialValue,
    mobile: initialValue,
    email: initialValue,
    designation: initialValue,
    team: initialValue,
    role: initialValue,
    reportingManager: initialValue,
    functionalManager: initialValue,
    status: initialValue,
    isLoading: false,
    profile: initialValue
};

export function reducer(state, action) {
    switch (action.type) {
        case EMP_ID:
            return {
                ...state,
                empId: {
                    ...state.empId,
                    value: action.payLoad,
                    errorStatus: false,
                },
            };
        case USER_NAME:
            return {
                ...state,
                username: {
                    ...state.username,
                    value: action.payLoad,
                    errorStatus: false,
                },
            };
        case MOBILE_NUMBER:
            if (validateMobileNumber(action.payLoad)) {
                return {
                    ...state,
                    mobile: {
                        ...state.mobile,
                        value: action.payLoad,
                        errorStatus: false,
                    },
                };
            } else {
                return {
                    ...state,
                    mobile: {
                        ...state.mobile,
                        value: action.payLoad,
                        errorStatus: true,
                        errorMessage: "Please Enter Valid Mobile Number"
                    },
                };
            }
        case EMAIL:
            return {
                ...state,
                email: {
                    ...state.email,
                    value: action.payLoad,
                    errorStatus: false,
                },
            };
        case DESIGNATION:
            return {
                ...state,
                designation: {
                    ...state.designation,
                    value: action.payLoad,
                    errorStatus: false,
                },
            };
        case TEAM:
            return {
                ...state,
                team: {
                    ...state.team,
                    value: action.payLoad,
                    errorStatus: false,
                },
            };
        case ROLE:
            return {
                ...state,
                role: {
                    ...state.role,
                    value: action.payLoad,
                    errorStatus: false,
                },
            };
        case STATUS:
            return {
                ...state,
                status: {
                    ...state.status,
                    value: action.payLoad,
                    errorStatus: false,
                },
            };

        case PROFILE_INFO:
            return {
                ...state,
                username: {
                    ...state.username,
                    value: action.payLoad.username,
                    errorStatus: false
                },
                fullName: {
                    ...state.fullName,
                    value: action.payLoad.fullName,
                    errorStatus: false
                },
                mobile: {
                    ...state.mobile,
                    value: action.payLoad.mobile,
                    errorStatus: false,
                },
                email: {
                    ...state.mobile,
                    value: action.payLoad.email,
                    errorStatus: false,
                },
                designation: {
                    ...state.designation,
                    value: action.payLoad.designation,
                    errorStatus: false
                },
                team: {
                    ...state.team,
                    value: action.payLoad.team,
                    errorStatus: false,
                },
                role: {
                    ...state.role,
                    value: action.payLoad.role,
                    errorStatus: false,
                },
                reportingManager: {
                    ...state.team,
                    value: action.payLoad.reportingManager,
                    errorStatus: false,
                },
                functionalManager: {
                    ...state.role,
                    value: action.payLoad.functionalManager,
                    errorStatus: false,
                },
                status: {
                    ...state.status,
                    value: action.payLoad.status,
                    errorStatus: false,
                },
                empId: {
                    ...state.empId,
                    value: action.payLoad.empId,
                    errorStatus: false
                },
            }
        case REDIRECT: //page navigation
            return {
                ...state,
                profile: {
                    ...state.profile,
                    value: action.payLoad,
                    errorStatus: false,
                },
            }
        case IS_LOADING:
            return { ...state, isLoading: true };
        case IS_LOADED:
            return {
                ...state,
                isLoading: false,
            };

        default:
            return state
    }
}