import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { logoutUser } from '../../../Common/Actions';
import { getRoleCount } from '../../../Common/LocalStorage';

export default function AdminTopNav() {
  const dispatch = useDispatch();
  const getUser = useSelector(state => state.auth)
  const [redirect, setRedirect] = useState(false);
  const onLogout = () => {
    dispatch(logoutUser);
    setRedirect(true)
  }
  if (redirect) {
    return <Redirect to="/" />
  }

  return (
    <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="navbar-brand-wrapper d-flex justify-content-center">
        <div className="navbar-brand-inner-wrapper d-flex justify-content-between align-items-center w-100">
          <a className="navbar-brand brand-logo" href="/"><img src="images/common/agile.png" alt="logo" /></a>
          <p className="navbar-brand brand-logo pt-3"  style={{fontSize:'10px',paddingRight:'0px',}}>Version 1.0.3</p>
          <a className="navbar-brand brand-logo-mini" href="/"><img src="images/common/agile.png" alt="logo" /></a>
          <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
            <span className="mdi mdi-sort-variant"></span>
          </button>
        </div>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">

        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile dropdown">{/* eslint-disable-next-line */}
            <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
              <img src="images/common/user1.svg" alt="profile" />
              <span className="nav-profile-name">{getUser.user.fullName}</span>
            </a>
            <div className="dropdown-menu dropdown-menu-center navbar-dropdown" aria-labelledby="profileDropdown">
              {/* eslint-disable-next-line */}
              {getRoleCount('roleCount')>=1 ? <Link className="dropdown-item" to={{ pathname: "/changePassword"}}>
                <i className="mdi mdi-security text-primary"></i>
                      Change Password
                    </Link> : null}
              {/* eslint-disable-next-line */}
               <Link className="dropdown-item" to={{ pathname: "/settings"}}>
                <i className="mdi mdi-settings text-primary"></i>
                      Settings
                    </Link>
              {/* eslint-disable-next-line */}
              <a className="dropdown-item" onClick={onLogout}>
                <i className="mdi mdi-logout text-primary"></i>
                      Logout
                    </a>
            </div>
          </li>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  )
}