import React, { useState,  useEffect } from 'react';
import './style.scss';
import { Redirect, Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'
import ChatBot from '../../Common/chatbot';

export default function LandingPage() {

    //eslint-disable-next-line
    const [redirect, setRedirect] = useState(false);
    const [data, setData] = useState({})
    useEffect(() => {
      const body = document.querySelector('#root');
      body.scrollIntoView({
          // behavior: 'smooth'
      }, 500)
  
  }, []);

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    // console.log(data.email)
    if (redirect) {
        return <Redirect to="/" />
      }

      const arrowStylesleft: CSSProperties = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 30,
        height: 30,
        // cursor: 'pointer',
        marginLeft: '-20px',
        borderColor: 'transparent',
        backgroundColor: 'transparent'
    };
    const arrowStylesright: CSSProperties = {
      position: 'absolute',
      zIndex: 2,
      top: 'calc(50% - 15px)',
      width: 30,
      height: 30,
      marginRight: '-17px',
      // cursor: 'pointer',
      borderColor: 'transparent',
      backgroundColor: 'transparent'
  };

    return(
        <div>
          <ChatBot />
            <nav className="navbar sticky-top navbar-expand-lg navbar-light" style={{backgroundColor: "white"}}>
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
      aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
    {/* eslint-disable-next-line */}
      <a className="navbar-brand"><Link to={{ pathname: "/home" }}>
        <img src="images/common/agile2.png" width="170" alt="" /></Link>
      </a>
      <form className="form-inline my-2 my-lg-0 ml-auto" style={{paddingTop: "16px"}}>
        {/* <!-- <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"> --> */}
        {/* eslint-disable-next-line */}
        <a className="btn my-2 my-sm-0 ml-auto" id="loginbtn" type="submit"><Link to={{ pathname: "/login" }}>Login</Link></a>
          {/* support page button */}
          <button className="btn my-2 my-sm-0" id="signupbtn" type="button"><Link style={{color:'white'}} to={{ pathname: "/releasenote" }}>Support</Link></button>
        {/* eslint-disable-next-line */}
        <a className="btn my-2 my-sm-0" id="signupbtn" type="submit"><Link style={{color:'white'}} to={{ pathname: "/register" }}>Get Started {">"} </Link></a>
      
      </form>
    </div>
  </nav>

  <section id="sec1">
    {/* <!-- 2 col main data --> */}
    <div className="container">
      <div className="row pt-3">
        <div className="col-sm">
          <h1 id="head1">Software teams that embrace agile project management methodologies increase their development
             speed, expand collaboration, and foster the ability to better respond to market trends.</h1>
         
        </div>
        <div className="col-sm">
         <center><img src="images/common/AGILEBG4.png" width="500" alt="Agile"/></center>
          <p id="con1">We help organize your work, so your team can strike a healthy balance between structure, 
            flexibility, and launching Products according to the client need on time.</p>
        </div>
      </div>

      <form method="get" className="form-inline pt-3 pb-3" style={{marginLeft: "25%"}}>
        <div className="input-group mb-2 mr-sm-1 inwid">
         
          <input type="text" className="form-control" name="email"  id="inlineFormInputGroupUsername" placeholder="Email" onChange={e => handleChange(e)} />
        </div>

        <button type="submit" className="btn mb-2" id="btn1"><Link style={{color:'white'}} to={{ pathname: "/register", data: data.email  }}>Get Started - It's Free</Link></button>
      </form>
    </div>
  </section>

  <section id="sec2">
    <div className="container">
      <div className="row pt-4">
        <div className="col-sm" id="imgtop">
          <img className="w-100" src="images/common/dashboard-new1.png" alt="Agile"/>
        </div>

        <div className="col-sm ml-4">
          <h2 id="head3">Agile 24X7 is the  software that handles any Sprint, User Story without any confusion.</h2>
          
          <p id="con3">Manage your sprint planning, User Stories, and tasks with Agile 24X7 and keep everyone 
            in-the-loop.</p>
            {/* eslint-disable-next-line */}
            <a className="btn my-2 my-sm-0" id="strtdngbtn" type="submit"><Link style={{color:'white',fontSize:'15px'}} to={{ pathname: "/register" }}>Start Doing</Link></a>
        </div>
      
      </div>
    </div>
  </section>

  <section id="sec3">
    <div className="container">
      <div className="row pt-3">
        <div className="col-sm">
          <h2 id="head2">Manage iterations and agile workflows all in one place <br />
            Release quality products faster, together
            </h2>
          <p id="con2">With all communication and updates in one place, work better together to push the 
            product forward. </p>
        </div>
        <div className="col-sm ml-4"  id="imgtop" >
          <img className="w-100" src="images/common/backlog.png" width="500" alt="agile" />
        </div>
        
      </div>
    </div>
  </section>

  <section id="sec4">
    <div className="container">
      <div className="row pt-3">
        <div className="col-sm" id="img3">
          <img src="images/common/sidenavpic3.png" width="180" style={{marginLeft: '150px'}} alt="agile"  />
        </div>
        <div className="col-sm ml-4">
          <h2 id="head2">Epics, Sprint, User Stories, Backlogs</h2>
          <p id="con2">These simple structures help agile teams gracefully manage scope and structure work. 
            With all communication and updates in one place, your team work better together to push the product forward.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section id="sec4">
    <div className="container">
      <div className="row pt-3">
      
        <div className="col-sm">
          <h2 id="head2"> 
            Better User experience with Easy To Use Dashboard <br />
            Stay on top of your sprints           
            </h2>
          <p id="con2">Use dashboards to easily track User stories and backlogs to see where your team stands
             against each EPIC.
          </p>
        </div>
        <div className="col-sm ml-4" id="imgtop">
          <img style={{width:'100%'}} src="images/common/dashboard-new2.png" alt="agile" />
        </div>
      </div>
    </div>
  </section>

  <section>
    <div className="container" style={{marginTop: "3%"}}>
      <h2 className="carousel-heading">How It Works</h2>  

      <Carousel autoFocus={true} showThumbs={false} showStatus={false} showArrows={true} useKeyboardArrows className="presentation-mode"
       renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
            <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStylesleft, left: 15, fontSize: '35px', fontWeight: 'bolder',  color: '#47974B' }}>
                {"<"}
            </button>
        )
    }
    renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
            <button  onClick={onClickHandler} title={label} style={{ ...arrowStylesright, right: 15, fontSize: '35px', fontWeight: 'bolder',  color: '#47974B' }}>
              {">"}  
            </button>
        )
    }
      >
        <div key="content-0" className="my-slide primary">
        <img id="imgcarousel" src="images/common/agc-1.png" alt=" not found" />
        </div>
        <div key="content-1" className="my-slide secondary">
        <img id="imgcarousel" src="images/common/agc-2.png" alt=" not found" />
        </div>
        <div key="content-2" className="my-slide content">
        <img id="imgcarousel" src="images/common/agc-3.png" alt=" not found" />
        </div>
        <div key="content-3" className="my-slide content">
        <img id="imgcarousel" src="images/common/agc-4.png" alt=" not found" />
        </div>
        <div key="content-4" className="my-slide content">
        <img id="imgcarousel" src="images/common/agc-5.png" alt=" not found" />
        </div>
        <div key="content-5" className="my-slide secondary complex">
        <img id="imgcarousel" src="images/common/agc-6.png" alt=" not found" />
        </div>
        <div key="content-6" className="my-slide secondary">
        <img id="imgcarousel" src="images/common/agc-7.png" alt="not found" />
        </div>
        <div key="content-7" className="my-slide content">
        <img id="imgcarousel" src="images/common/agc-8.png" alt=" not found" />
        </div>
        <div key="content-8" className="my-slide content">
        <img id="imgcarousel" src="images/common/agc-9.png" alt=" not found" />
        </div>
        <div key="content-9" className="my-slide content">
        <img id="imgcarousel" src="images/common/agc-10.png" alt="not found" />
        </div>
        <div key="content-10" className="my-slide secondary complex">
        <img id="imgcarousel" src="images/common/agc-11.png" alt="not found" />
        </div>
        <div key="content-11" className="my-slide secondary">
        <img id="imgcarousel" src="images/common/agc-12.png" alt="not found" />
        </div>
        <div key="content-12" className="my-slide content">
        <img id="imgcarousel" src="images/common/agc-13.png" alt="not found" />
        </div>
        <div key="content-13" className="my-slide content">
        <img id="imgcarousel" src="images/common/agc-14.png" alt="not found" />
        </div>
    </Carousel>
    {/* <div className="video-box align-self-baseline" data-aos="fade-up" data-aos-delay="100">
        <img src="images/common/landing-new.png" className="img-fluid" alt="certificate"/>
        <a href="https://www.youtube.com/watch?v=EfkTcFhMLiw" className="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true">.</a>
    </div> */}
    
    </div>
  </section>

  <section id="sec5">
    <div className="container">
      <h1>Try AGILE 24x7 for your team</h1>
      <p>Sign up and look who is using AGILE 24x7 to get their work done.</p>
       {/*  eslint-disable-next-line */}
      <a className="btn my-2 my-sm-0" id="getstarted" type="submit"><Link style={{color:'#47974B', fontSize:'15px'}} to={{ pathname: "/register" }}>Get Started</Link></a>
    </div>

  </section>

            <footer id="footer">
    <div>
      © Copyright 2021. All Rights Reserved.
    </div>
  </footer>
        </div>
    )
}