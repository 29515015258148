import Alert from "../Alert";

import API from "../Network/API";
import { isLoaded, isLoading, manageTasks, projects, roadBlocks, subTasks, tasksCount, roadBlockMessages, allMessages, mainTaskMessages } from "./action";

export async function getRoadBlockMessages(dispatch,getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("roadBlockChat.php", {
      corp_code: getUser.corp,
      action: "getAllTaskMessages",
    }, {}, false);
    if (response.status === 'True') {
       dispatch(roadBlockMessages(response.data));
    }else{
      dispatch(roadBlockMessages([]));
    }
  } catch (error) {
    Alert('error',error.message)
  }
  dispatch(isLoaded());
}

export async function getTaskMessages(dispatch,getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("taskChat.php", {
      corp_code: getUser.corp,
      action: "getAllTaskMessages",
    }, {}, false);
    if (response.status === 'True') {
       dispatch(allMessages(response.data));
    }else{
      dispatch(allMessages([]));
    }
  } catch (error) {
    Alert('error',error.message)
  }
  dispatch(isLoaded());
}

export async function getMainTaskMessages(dispatch,getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("mainTaskChat.php", {
      corp_code: getUser.corp,
      action: "getAllTaskMessages",
    }, {}, false);
    if (response.status === 'True') {
       dispatch(mainTaskMessages(response.data));
    }else{
      dispatch(mainTaskMessages([]));
    }
  } catch (error) {
    Alert('error',error.message)
  }
  dispatch(isLoaded());
}


//For Tasks Count (Pending Project,RoadBlock,SubTasks and MainTasks)
export async function getTasksCount(dispatch, getUser, empId) {
  dispatch(isLoading());
  try {
    var response = await API.post("getEmployeeInfo.php", {
      "crop": getUser.corp,
      "empId": empId,
    }, {}, false);
    dispatch(tasksCount(response));
  } catch (error) {
    console.log(error)
  }
  dispatch(isLoaded());
}
//Get Projects of Employee
export async function getProjects(dispatch, getUser, empId, role) {
  dispatch(isLoading());
  try {
    var response = await API.post("getIdeas.php", {
      "crop": getUser.corp,
      empId: empId,
      // userType: role, //Based For User level only showing 
      action: 'approved',
    }, {}, false);
    if (response.status === 'True') {
      dispatch(projects(response.data))
    }
    else {
      dispatch(projects([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}
//Get ManageTasks  of Employee
export async function getManageTasks(dispatch, getUser, empId, role) {
  dispatch(isLoading());
  try {
    var response = await API.post("getManageMaintasks.php", {
      "crop": getUser.corp,
      empId: empId,
      // userType: role, //Based For User level only showing 
      action: 'pending',
    }, {}, false);
    if (response.status === 'true') {
      dispatch(manageTasks(response.data))
    }
    else {
      dispatch(manageTasks([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}
//Get RoadBlocks of Employee
export async function getRoadBlocks(dispatch, getUser, empId, role) {
  dispatch(isLoading());
  try {
    var response = await API.post("userRoadblocks.php", {
      "crop": getUser.corp,
      "empId":empId,
      "action": "userRoadblocks"
    }, {}, false);
    if (response.status === 'True') {
      dispatch(roadBlocks(response.data))
    }
    else {
      dispatch(roadBlocks([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}
//Get Employee Subtask
export async function getSubTasks(dispatch, getUser, empId, role) {
  console.log(empId);
  dispatch(isLoading());
  try {
    var response = await API.post("getUserPendingSprints.php", {
      "crop": getUser.corp,
      "empId": empId,
     
    }, {}, false);
    if (response.status === 'true') {
      dispatch(subTasks(response.data))
    }
    else {
      dispatch(subTasks([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}