import React, { useEffect, useReducer, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useSelector } from 'react-redux';
import { chatReducer, initialState } from './reducer';
import { getMessages, groupMembersList, updateChat, groupMembersEmail } from './network';
import * as actions from './actions';
import Alert from '../Alert';
import { isLoaded, isLoading } from '../Actions/loading';
import API from '../Network/API';
import moment from 'moment'
import {BaseUrl} from '../Headers';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import RootLoader from '../Loader/RootLoad';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginFileValidateType)



const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));


export default function MainTaskChatBox(props) {
    const classNames = useStyles();
    const getUser = useSelector(state => state.auth)
    const [state, dispatch] = useReducer(chatReducer, initialState)
    // console.log(props.data)
    const [chat,setChat] = useState(0);
const handleChat = () => {
    setChat(1)
}
const handleChatClose = () => {
    setChat(0)
}
    useEffect(() => {
        updateChat(props.data.sno,dispatch,getUser.user)
        groupMembersList(dispatch, getUser.user, props.data);
        getMessages(dispatch, getUser.user, props.data);
        groupMembersEmail(dispatch, getUser.user, props.data);
        // eslint-disable-next-line
    }, [])
    const msgId = props.data.sno ? props.data.sno.map((msgId, index) => {
        return msgId.sno
    }):null

    // console.log(state.groupEmail)
    // console.log()
    // console.log(props.data.id)
    // console.log(msgId)
    const sendMessage = async () => {
        const emails = Array.from(new Set(state.groupEmail));
        if (state.comment !== "") {
            dispatch(isLoading());
            try {
                // eslint-disable-next-line
                var response = await API.post("mainTaskChat.php", {
                    action: "send",
                    corp_code: getUser.user.corp,
                    groupId: props.data.id,
                    message: state.comment,
                    messagedBy: getUser.user.empId, 
                    msgId: msgId ? msgId : " ",
                    groupName: state.groupName,
                    groupEmail: emails,
                }, {}, false);
                console.log(response)
                    getMessages(dispatch, getUser.user, props.data);
                    dispatch(actions.comment(""));
            } catch (error) {
                console.log(error)
            }
            dispatch(isLoaded());
        } else {
            Alert("warning", "Please type the message");
        }
    }

    const handleKeypress = e => {
        //it triggers by pressing the enter key
      if (e.which === 13) {
        sendMessage();
      }
    };
    
// eslint-disable-next-line
    const fileHandle = async (file) => {
        const emails = Array.from(new Set(state.groupEmail));
        if (file !== "") {
            dispatch(isLoading());
            const data = new FormData();
                    data.append('file', file);
                    data.append('message', file.name);
                    // required data for send message
                    data.append('action', 'media');
                    data.append('corp_code', getUser.user.corp);
                    data.append('groupId', props.data.id);
                    data.append('messagedBy', getUser.user.empId);
                    data.append('api', BaseUrl);
                    data.append('groupName', state.groupName);
                    data.append('groupEmail', emails);
            try {
                var response = await API.post("mainTaskChat1.php", data
                , {}, false);
                console.warn(response)
                    getMessages(dispatch, getUser.user, props.data);
                    dispatch(actions.comment(""));
                    setChat(0)
            } catch (error) {
                console.log(error)
            }
            dispatch(isLoaded());
        } else {
            Alert("warning", "Please type the message");
        }
    }
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className="modal-content col-6 p-2" style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    <div className="column">
                                        <h5 class="modal-title">User Story -{state.groupName}</h5>
                                        <h6 class="modal-title">Members - {state.groupList}</h6>
                                    </div>
                                    <button style={{backgroundColor:'transparent',border:'0'}} type="button" onClick={props.handleModalClose} className="d-flex align-items-right" data-dismiss="modal"><i class="mdi mdi-close text-black"></i></button>
                                </div>
                                <div className="modal-body">
                                    <div className="overflow-auto" style={{ height: '250px',display:'flex',flexDirection: 'column-reverse',overflow:'auto' }}>
                                        {state.taskComments !== '' ? state.taskComments.slice(0).reverse().map((comments) => {
                                            return (
                                                comments.messagedBy !== getUser.user.empId ?
                                            // <p className="text-left" ><p className="pt-2" style={{color:'blue'}}>{comments.username}&nbsp;<q style={{color:'grey',fontSize:"8px"}}>{moment(comments.messagedTime).format("MM-DD-YYYY hh:mm:ss")}</q></p>{comments.message}</p> :
                                            //         <p className="text-right pr-3" ><p className="pt-2" style={{color:'green'}}>{comments.username}&nbsp;<q style={{color:'grey',fontSize:"8px"}}>{moment(comments.messagedTime).format("MM-DD-YYYY hh:mm:ss")}</q></p>{comments.message}</p>
                                            <p className="text-left pr-3" ><p className="pt-2" style={{color:'green'}}>{comments.username}&nbsp;<q style={{color:'grey',fontSize:"8px"}}>{moment(comments.messagedTime).format("MM-DD-YYYY hh:mm:ss")}</q></p>
                                            {comments.path ?
                                            <div style={{height: "67%", backgroundColor: "#f3f3f3", paddingTop: "10px", width: "max-content", float: "left"}}>
                                            {/* <a href={comments.path} style={{color: 'black'}} download>{comments.message}</a> */}
                                            {!comments.path.match(/.(jpg|jpeg|png|gif)$/i) ? 
                                                 //  eslint-disable-next-line
                                              <a download={comments.message} className='text-primary' target="_blank" href={comments.path}>{comments.message}</a>
                                              : 
                                               //  eslint-disable-next-line
                                              <a download={comments.path} className='text-primary' target="_blank" href={comments.path}>
                                                  <img alt={comments.message}  src={comments.path} width="400" /></a>
                                                  }</div>
                                            : comments.message}</p>:
                                           
                                           <p className="text-right pr-3" ><p className="pt-2" style={{color:'green'}}>{comments.username}&nbsp;<q style={{color:'grey',fontSize:"8px"}}>{moment(comments.messagedTime).format("MM-DD-YYYY hh:mm:ss")}</q></p>
                                            {comments.path ?
                                            <div style={{height: "67%", backgroundColor: "#f3f3f3", paddingTop: "10px", width: "max-content", float: "right" }}>
                                              {/* <a href={comments.path} style={{color: 'black'}} download link>{comments.message}</a> */}
                                              {!comments.path.match(/.(jpg|jpeg|png|gif)$/i) ? 
                                                 //  eslint-disable-next-line
                                              <a download={comments.message} className='text-primary' target="_blank" href={comments.path}>{comments.message}</a>
                                              : 
                                              //  eslint-disable-next-line
                                              <a download={comments.path} className='text-primary' target="_blank" href={comments.path}>
                                                  <img alt={comments.message}  src={comments.path} width="400" /></a>
                                                  }
                                             
                                             </div>
                                              : comments.message}</p>
                                            )
                                        }) : null}
                                    </div>
                                </div>
                                <div class="modal-footer m-1 justify-content-center">
                                    <div className="form-sample col-12">
                                <form  encType="multipart/form-data" method="post" autoComplete="off">
                                                        {/* <div className="form-sample col-12"> */}
                                                            {/* <label>Resume*</label> */}
                                                            {chat === 1 ? 
                                                            <div><button style={{backgroundColor:'transparent',border:'0', float: "right"}} type="button" onClick={() => handleChatClose()} className="d-flex align-items-right" ><i class="mdi mdi-close text-black"></i></button>
                                                            <FilePond
                                                                allowFileTypeValidation={true}
                                                                // acceptedFileTypes={['allTypes']}
                                                                fileValidateTypeLabelExpectedTypes={['allTypes']}
                                                                allowMultiple={true}
                                                                // maxFiles={3}
                                                                maxFiles={1}
                                                                instantUpload={false}
                                                                server={{
                                                                    // fake server to simulate loading a 'local' server file and processing a file
                                                                    process: (fieldName, file, metadata, load) => {
                                                                        // simulates uploading a file
                                                                        setTimeout(() => {
                                                                            load(Date.now())
                                                                            fileHandle(file)
                                                                            // handleImageChange(file)
                                                                        }, 1000);
                                                                    },
                                                                    load: (source, load) => {
                                                                        // simulates loading a file from the server
                                                                        fetch(source).then(res => res.blob()).then(load);
                                                                    }
                                                                }}
                                                                 >

                                                            </FilePond>
                                                            </div>
                                                            : ""}
                                                            </form>
                                                        {/* </div> */}
                                                        <div className="row">
                                                            <div className="col-md-9">
                                                                <input type="text" class="form-control" id="recipient-name" style={{ borderColor: 'grey', borderRadius: '20px',height:'2.2rem' }} 
                                                                value={state.comment} onChange={(event) => dispatch(actions.comment(event.target.value))} onKeyPress={handleKeypress}/>
                                                            </div>
                                                            <div className="col-md-1" type="button"   onClick={() => handleChat()}>
                                                                <img src="images/common/paper-clip.png" alt="logo" 
                                                                style={{ width: '32px', marginLeft:'-9px', marginTop: "-5px" }} />
                                                            </div>
                                                            <div className="col-md-2">
                                                                {/* <button type="button" class="btn btn-outline-success w-100" style={{ borderRadius: '20px',height:'2.2rem', }} onClick={() => sendMessage()}>Send</button> */}
                                                                {state.isLoading ? <RootLoader/> :<button type="button" class="btn btn-outline-success w-100" style={{ borderRadius: '20px',height:'2.2rem', }} onClick={() => sendMessage()}>Send</button>}
                                                            </div>
                                                        </div>
                                                    
                                                    </div>
                                </div>

                                {/* <div class="modal-footer m-1">
                                
                                    <input type="text" class="form-control col-10 " id="recipient-name" style={{ borderColor: 'grey', borderRadius: '20px' }} 
                                     value={state.comment} onChange={(event) => dispatch(actions.comment(event.target.value))} onKeyPress={handleKeypress}/>
                                    <button type="submit" class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={() => sendMessage()}>Send</button>
                                  
                                </div> */}

                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}