/*
FileName: UserModule/TopNav/index.js
Purpose : Top Nav for Getting the Squads List, Status,User profile and Logout
Developers: Naveen Kumar Gade --> GNK
Changes : 01-->GNK
*/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { logoutUser } from '../../../Common/Actions';
import { getStatus, setStatus,getRoleCount } from '../../../Common/LocalStorage';
import API from '../../../Common/Network/API';


export default function TopNavWithOutProject() {
  const dispatch = useDispatch();
  const getUser = useSelector(state => state.auth)
  const [userStatus, UpdateUserStatus] = useState(getStatus('status'));
  const [statusList, updateStatusList] = useState([])
  const [redirect, setRedirect] = useState(false);

  const onLogout = () => {
    dispatch(logoutUser);
    setRedirect(true)
  }
  useEffect(() => {
    getStatusOfEmployee();
    // eslint-disable-next-line
  }, [])

  if (redirect) {
    if ((getRoleCount('roleCount') >= 1)) {
      window.location.reload();
    } else {
      return <Redirect to="/" />
    }
  }


  const getStatusOfEmployee = async () => {
    // console.log(getUser.user.corp)
    try {
      var response = await API.post("spinner.php", {
        "crop": getUser.user.corp,
        "action": 'status',
      }, {}, false);
      // console.log(response)
      if (response.status === 'True') {
        updateStatusList(response.data)
      } else {
        updateStatusList([])
      }
    } catch (error) {
      // Alert('error',error.message)
    }
  }
  //Update the Status of Employee
  const UserStatus = async (status) => {
    // dispatch(isLoading());
    try {
      const response = await API.post("getProfile.php", {
        crop: getUser.user.corp,
        action: 'updatestatus',
        status: status,
        empId: getUser.user.empId
      }, {}, false);
      if (response.status === 'True') {
        setStatus('status', status)
        UpdateUserStatus(status)
      } else {
        UpdateUserStatus(getStatus('status'))
      }
    } catch (error) {
      // Alert('error',error.message)
    }
    // dispatch(isLoaded());
  }


  return (
    <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="navbar-brand-wrapper d-flex justify-content-center">
        <div className="navbar-brand-inner-wrapper d-flex justify-content-between align-items-center w-100">
          <a className="navbar-brand brand-logo" href="/"><img src="images/common/agile.png" alt="logo" /></a>
          <p className="navbar-brand brand-logo pt-3" style={{ fontSize: '10px', paddingRight: '0px', }}>Version 1.0.14.01<br />07-02-2021</p>
          <a className="navbar-brand brand-logo-mini" href="/"><img src="images/common/agile.png" alt="logo" /></a>
          <button className="navbar-toggler align-self-center" type="button" data-toggle="minimize">
            <span className="mdi mdi-sort-variant"></span>
          </button>
        </div>
      </div>
      <div className="navbar-menu-wrapper d-flex  justify-content-end">

     
        
        <ul className="navbar-nav navbar-nav-right">



          <li className=" nav-item nav-profile dropdown show">
            <span className="text-black mr-2">Status</span>
            {/* eslint-disable-next-line */}
            <a style={{ border: '2px solid grey', borderRadius: '30px' }} className="btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="text-black">{userStatus}</span>
            </a>

            <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="dropdownMenuLink">
              {statusList !== [] ? statusList.map((list, index) => {
                return (
                  // eslint-disable-next-line 
                  <a className="dropdown-item" onClick={() => UserStatus(list.value)}>{list.value}</a>

                )
              }) : null}
            </div>
          </li>


          <li className="nav-item nav-profile dropdown">{/* eslint-disable-next-line */}
            <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
              <img src="images/common/user1.svg" alt="profile" />
              <span className="nav-profile-name">{getUser.user.fullName}</span>
            </a>
            <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
              {/* eslint-disable-next-line */}
              <Link className="dropdown-item" to={{ pathname: "/userProfile" }}>
                <i className="mdi mdi-account text-primary"></i>
                      Profile
                    </Link>
              <Link className="dropdown-item" to={{ pathname: "/changePasswordUser" }}>
                <i className="mdi mdi-security text-primary"></i>
                      Change Password
                    </Link>
              {/* eslint-disable-next-line */}
              <a className="dropdown-item" onClick={onLogout}>
                <i className="mdi mdi-logout text-primary"></i>
                      Logout
                    </a>
            </div>
          </li>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  )

}