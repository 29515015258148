/* 
FileName:index.js
purpose:To se all the archive data
Developers:Naveen Kumar Gade[NKG],Satya Sidda[SS]

 */
import React, { useEffect, useReducer, useState } from 'react';
import SideBar from '../Utility/SideNav';
import TopNav from '../Utility/TopNav';
// import { MDBTable } from 'mdbreact';
import { useSelector } from 'react-redux';
import { tasksReducer, initialState } from './tasksReducer';
import { getCompletedSprints } from './network';
import RootLoader from '../../Common/Loader/RootLoader';
import { ARCHIVE } from '../../Common/Headers';
import { Link } from 'react-router-dom';
import Moment from 'moment';




export default function Archive() {
    const getUser = useSelector(state => state.auth)

    const [state, dispatch] = useReducer(tasksReducer, initialState)
    const [searchWord, setSearchWord] = useState('')

    useEffect(() => {
        getCompletedSprints(dispatch, getUser.user)
        // eslint-disable-next-line
    }, [])

    return (
        <div className="container-scroller">
            <TopNav />
            <div className="container-fluid page-body-wrapper">
                <SideBar />
                <div className="main-panel">
                    <div className="mt-2">
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <div>
                                        <h2 className="card-title" style={{ overflowWrap: "break-word", color: 'blue', backgroundColor: 'transparent' }}> {ARCHIVE}</h2>
                                    </div>
                                    <div className="d-flex justify-content-end mb-3 mt-2">
                                   
                                                <div class="input-group-prepend">
                                                    <span class="text"  style={{color:'black', marginTop:'3px', fontSize:'13px' ,paddingRight:10}}>Search: </span>
                                                </div>
                                                <input type="text" class="form-control"  style={{ backgroundColor: 'transparent', borderBottom: '2px solid black', borderTop: '2px solid black', borderLeft: '12x solid black', borderRight: '2px solid black', marginTop: '-5px', width: '450px', height: '35px' }}
                                                    onChange={(event) => setSearchWord(event.target.value)}
                                                />
                                            
                                    </div>


                                    {state.isLoading ? <RootLoader /> :
                                        <div>
                                            {/* eslint-disable-next-line */}
                                            {state.completedSprints.length > 0 ? state.completedSprints.filter((val) =>{
                                                if (searchWord === "") {
                                                    return val
                                                } else if (val.moduleDesc.toLowerCase().includes(searchWord.toLowerCase()) ) {
                                                    return val
                                                }
                                            })
                                            .map((tasks, index) => {
                                                return (
                                                    <div className="col-12" key={tasks.moduleId}>
                                                        <div className="card col-12">
                                                            <div className="container-fluid col-12 row" >
                                                                <div class="d-flex col-12" style={{ padding: 0 }}>
                                                                    <div class="mr-auto p-2">
                                                                        <b style={{ cursor: 'pointer', paddingTop: 10, fontSize: '15px' }} > <Link style={{color:'green'}} to={{ pathname: '/completedUserStories', state: { id: state.completedSprints[index].moduleId, title: state.completedSprints[index].moduleDesc, ideaId: state.completedSprints[index].ideaId, startDate: state.completedSprints[index].startDate, targetDate: state.completedSprints[index].targetDate } }} >{(getUser.user.corp).substring(0, 3).toUpperCase()}{tasks.moduleId}{'-'}{tasks.moduleDesc.concat('   [', Moment(tasks.startDate).format('MM.DD.YYYY'), '-', Moment(tasks.targetDate).format('MM.DD.YYYY'), ']')}</Link></b>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                )
                                            }
                                            ) : null}
                                        </div>

                                    }





                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}