/* 
FileName:index.js
purpose:To se all the archive data
Developers:Naveen Kumar Gade[NKG],Satya Sidda[SS]

 */
import React, { useEffect, useReducer, useState } from 'react';
import SideBar from '../Utility/SideNav';
import TopNav from '../Utility/TopNav';
// import { MDBTable } from 'mdbreact';
import { useSelector } from 'react-redux';
import { tasksReducer, initialState } from './tasksReducer';
import { getCompletedUserStories } from './network';
import RootLoader from '../../Common/Loader/RootLoader';
import Moment from 'moment';
import MainTaskInfo from '../../Common/TasksModals/mainTaskInfo';





export default function CompletedUserStories(props) {

    var data = {
        id: props.location.state.id,
        title: props.location.state.title,
        ideaId: props.location.state.ideaId,
        startDate: props.location.state.startDate,
        targetDate: props.location.state.targetDate
    }
    const getUser = useSelector(state => state.auth)

    const [state, dispatch] = useReducer(tasksReducer, initialState)
    const [open, setOpen] = useState({ status: false, index: 0 })
    const [cardInfo, setCardInfo] = useState()
    const [searchWord, setSearchWord] = useState('')
    useEffect(() => {
        getCompletedUserStories(dispatch, getUser.user, data.id)
        // eslint-disable-next-line
    }, [])
    const handleOpen = (action, index, sno) => {
        var info
        setOpen({ status: true, index: index, action: action });

        if (action === "unassigned_taskInfo") {

            var view_status1 = "taskInfo"
            info = {
                view: view_status1,
                projectName: state.completedUserStories[index].projectitle,
                moduleName: state.completedUserStories[index].moduletitle,
                title: state.completedUserStories[index].tasktitle,
                description: state.completedUserStories[index].taskdescription,
                taskId: (getUser.user.corp).substring(0, 3).toUpperCase().concat('-', state.completedUserStories[index].taskid),
                targetDate: state.completedUserStories[index].targettime,
                timeLeft: state.completedUserStories[index].timeLeft,
                extraHours: state.completedUserStories[index].extraHours,
                status: state.completedUserStories[index].completeStatus,
                createdDate: state.completedUserStories[index].assignedon,
                taskProgress: state.completedUserStories[index].taskStatus,
                storyPoints: state.completedUserStories[index].storyPoints,
                acceptanceCriteria: state.completedUserStories[index].acceptanceCriteria,
                assignedTo: state.completedUserStories[index].assigntto,
                assignedBy: state.completedUserStories[index].assignby,
                completedDate: state.completedUserStories[index].modifiedDate,
                completedStatus: state.completedUserStories[index].completeStatus,



            }

        }
        else {
            info = { action: action, id: state.currentTasks[index].taskid, sno: sno }
        }
        setCardInfo(info)
    };
    const handleClose = () => {

        setOpen({ status: false, index: 0 });

    };
    const handleModalClose = () => {
        setOpen({ status: false, index: 0 });
    }

    return (
        <div className="container-scroller">
            <TopNav />
            <div className="container-fluid page-body-wrapper">
                <SideBar />
                <div className="main-panel">
                    <div className="mt-2">
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <div>
                                        <h2 className="card-title" style={{ overflowWrap: "break-word", color: 'blue', backgroundColor: 'transparent' }}> {data.title.concat('   [', Moment(data.startDate).format('MM.DD.YYYY'), '-', Moment(data.targetDate).format('MM.DD.YYYY'), ']')}</h2>
                                    </div>
                                    <div className="d-flex justify-content-end mb-2">

                                        <div class="input-group-prepend">
                                            <span class="text" style={{ color: 'black', paddingRight: 10 }}>Search</span>
                                        </div>
                                        <input type="text" class="form-control" style={{ backgroundColor: 'transparent', borderBottom: '2px solid black', borderTop: '2px solid black', borderLeft: '12x solid black', borderRight: '2px solid black', marginTop: '-5px', width: '450px', height: '35px' }}
                                            onChange={(event) => setSearchWord(event.target.value)}
                                        />

                                    </div>

                                    {state.isLoading ? <RootLoader /> :
                                        <div>
                                            {/* eslint-disable-next-line */}
                                            {state.completedUserStories.length > 0 ? state.completedUserStories.filter((val) => {
                                                if (searchWord === "") {
                                                    return val
                                                } else if (val.tasktitle.toLowerCase().includes(searchWord.toLowerCase())) {
                                                    return val
                                                }
                                            })
                                                .map((tasks, index) => {
                                                    return (
                                                        <div className="col-12" key={tasks.taskid}>
                                                            <div className="card col-12">
                                                                <div className="container-fluid col-12 row" >
                                                                    <div class="d-flex col-12" style={{ padding: 0 }}>
                                                                        <div class="mr-auto p-2">
                                                                            <b style={{ cursor: 'pointer', paddingTop: 10, fontSize: '12px' }} onClick={(event) => handleOpen("unassigned_taskInfo", index)}><del> {(getUser.user.corp).substring(0, 3).toUpperCase()}{tasks.taskid}{'-'}{tasks.tasktitle}</del></b>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    )
                                                }
                                                ) : null}
                                            {
                                                open.action === "unassigned_taskInfo" ? <MainTaskInfo open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                                /> : null
                                            }
                                        </div>




                                    }





                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}