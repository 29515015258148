import React, { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import SideBar from '../Utility/SideNav';
import TopNav from '../Utility/TopNav';
import { getProfile } from './network';
import { reducer, initialState } from './reducer'
// import * as actions from './actions'
import RootLoader from '../../Common/Loader/RootLoader';
// import { updateData } from './network';

export default function UserProfile() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const getUser = useSelector(state => state.auth)
    useEffect(() => {
        getProfile(dispatch, getUser.user);
        // eslint-disable-next-line
    }, [])
    return (
        <div className="container-scroller">
            <TopNav />
            <div className="container-fluid page-body-wrapper">
                <SideBar />
                <div className="main-panel">
                    <div className="mt-2">
                        <div className="col-md-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <h4 className="card-title mb-2">Profile</h4>
                                        {/* <div className=" col-md-12 d-flex justify-content-end" style={{ marginTop: '-40px' }}>
                                            {state.isLoading ? <RootLoader /> :
                                                <button type="submit" className="btn btn
                                            
                                            -success border mr-2"
                                                    onClick={(event) => { updateData(state, dispatch, getUser.user) }} style={{ borderRadius: '30px' }}>SAVE</button>}
                                        </div> */}
                                    </div>
                                    <hr></hr>
                                    {state.isLoading ? <RootLoader /> :<form className="forms-sample">
                                        <div className="form-group row" >
                                            <label className="col-sm-3 col-form-label"> Squad Member ID</label>
                                            <div className="col-sm-9">
                                                <label className="col-sm-6 col-form-label">{state.empId.value}</label>
                                            </div>
                                        </div>
                                        <div className="form-group row" >
                                            <label className="col-sm-3 col-form-label">Username</label>
                                            <div className="col-sm-9">
                                                <label className="col-sm-6 col-form-label">{state.username.value}</label>
                                            </div>
                                        </div>
                                        <div className="form-group row" >
                                            <label className="col-sm-3 col-form-label">Full Name</label>
                                            <div className="col-sm-9">
                                                <label className="col-sm-6 col-form-label">{state.fullName.value}</label>
                                            </div>
                                        </div>
                                        <div className="form-group row" >
                                            <label className="col-sm-3 col-form-label">Email</label>
                                            <div className="col-sm-9">
                                                <label className="col-sm-6 col-form-label">{state.email.value}</label>
                                            </div>
                                        </div>
                                        {/* <div className="form-group row" >
                                            <label className="col-sm-3 col-form-label">Designation</label>
                                            <div className="col-sm-9">
                                                <label className="col-sm-6 col-form-label">{state.designation.value}</label>
                                            </div>
                                        </div> */}
                                        {/* <div className="form-group row" >
                                            <label className="col-sm-3 col-form-label">Team</label>
                                            <div className="col-sm-9">
                                                <label className="col-sm-6 col-form-label">{state.team.value}</label>
                                            </div>
                                        </div> */}
                                        <div className="form-group row" >
                                            <label className="col-sm-3 col-form-label">Mobile</label>
                                            <div className="col-sm-9">
                                                {/* <input type="text"
                                         className={ state.mobile.errorStatus ? "col-sm-3 col-form-label errorstyle" : "col-sm-3 col-form-label" }
                                         style={{borderRadius:'30px',borderBlockColor:'grey',borderWidth:'0.2px'}}
                                         placeholder="Enter Mobile number"
                                         value={state.mobile.value}
                                         onChange={(event) => dispatch(actions.updateMobileNumber(event.target.value))}
                                         required
                                         data-toggle="tooltip" data-placement="right" title="Enter a Valid Mobile Number For Communication"/>
                                        <small style={{ color: "red", fontSize: '12px' }}>{state.mobile.errorStatus ? state.mobile.errorMessage : ""}</small> */}
                                            <label className="col-sm-6 col-form-label">{state.mobile.value}</label>
                                            </div>
                                        </div>
                                        <div className="form-group row" >
                                            <label className="col-sm-3 col-form-label">Role</label>
                                            <div className="col-sm-9">
                                                <label className="col-sm-6 col-form-label">{(state.role.value === 'Approver')?'Product Owner':(state.role.value === 'Emp')?'Contributor':'Scrum Master'}</label>
                                            </div>
                                        </div>
                                        {/* <div className="form-group row" >
                                            <label className="col-sm-3 col-form-label">Reporting Manager</label>
                                            <div className="col-sm-9">
                                                <label className="col-sm-6 col-form-label">{state.reportingManager.value}</label>
                                            </div>
                                        </div> */}
                                        {/* <div className="form-group row" >
                                            <label className="col-sm-3 col-form-label">Functional Manager</label>
                                            <div className="col-sm-9">
                                                <label className="col-sm-6 col-form-label">{state.functionalManager.value}</label>
                                            </div>
                                        </div> */}
                                        {/* <div className="form-group row" >
                                            <label  className="col-sm-3 col-form-label">Status</label>
                                            <div className="col-sm-9">
                                                <label  className="col-sm-6 col-form-label">{state.status.value}</label>
                                            </div>
                                        </div> */}
                                    </form>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}