import { combineReducers } from "redux";
import { loadingReducer } from "./loadingReducer";
import { authReducer } from './authReducer';// For Authentication
import { tasksReducer } from '../../UserModule/UserDashboard/tasksReducer'; //For Subtasks Store
import { moduleReducer } from "../../UserModule/Modules/moduleReducer";



export default combineReducers({
    loading: loadingReducer, auth: authReducer, subtask: tasksReducer ,sprint: moduleReducer
});