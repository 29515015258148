import { dependencyUser, isLoaded, isLoading, manageSubTasks, users, allMessages } from "./actions";
import Alert from "../../Common/Alert";
import API from "../../Common/Network/API";
import { SUBTASK_ADD, SUBTASK_MODIFY, SUBTASK_DELETE } from "../Headers";
import Moment from 'moment';


export async function getAllTaskMessages(dispatch,getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("taskChat.php", {
      corp_code: getUser.corp,
      action: "getAllTaskMessages",
    }, {}, false);
    if (response.status === 'True') {
       dispatch(allMessages(response.data));
    }else{
      dispatch(allMessages([]));
    }
  } catch (error) {
    Alert('error',error.message)
  }
  dispatch(isLoaded());
}

//For Getting Users List(Team)
export async function getUsers(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("getEmployees.php", {
      "crop": getUser.corp,
    }, {}, false);
    if (response.status === 'True') {
      dispatch(users(response.data))
    }
    else {
    }
  } catch (error) {
    Alert('error', error.message);
    dispatch(isLoaded());
  }
}

//For Getting Dependency Task List(Team)
export async function getDependency(dispatch, getUser, ideaId) {
  dispatch(isLoading());
  try {
    var response = await API.post("getSubtasks.php", {
      crop: getUser.corp,
      action: "setdependency",
      ideaId: ideaId
    }, {}, false);
    if (response.status === 'true') {
      dispatch(dependencyUser(response.data))
    }
    else {
    }
  } catch (error) {
    Alert('error', error.message);
    dispatch(isLoaded());
  }
}

//For Get SubTask List From ManageTasks
export async function getSubTasks(dispatch, getUser, taskId) {
  dispatch(isLoading());
  try {
    var response = await API.post("getSubtasks.php", {
      crop: getUser.corp,
      mainTaskId: taskId,
      action: "getsubtasks"
    }, {}, false);
    if (response.status === 'true') {
      dispatch(manageSubTasks(response.data))
    }
    else {
      dispatch(manageSubTasks([]))
    }
  } catch (error) {
    Alert('error', error.message);
    dispatch(isLoaded());
  }
  dispatch(isLoaded());
}
//For Add SubTask
export async function addSubTask(state, dispatch, getUser, mainTaskId, moduleId,assignedTo,targetDate, handleClose) {
  dispatch(isLoading());
  if (state.targetDate.value  > targetDate) {
    Alert("warning", "Target date should not exceed User story target date")
    dispatch(isLoaded());
  }
 else if (state.targetDate.value < Moment(Date.now()).format("YYYY-MM-DD")) {
    dispatch(isLoaded());
    Alert('warning', 'Select valid date');

  }
  else if (state.targetDate.value !== "" && state.taskTitle.value !== "" && state.taskDescription.value !== "" ) {
    // let EstHours = Number(state.days.value * 24) + Number(state.hours.value);
    try {
      var response = await API.post("manageSubtasks.php", {
        //EstimatedHours: EstHours,
        assignedBy: getUser.empId,
        assignedTo:assignedTo,
        //dependencyId: state.dependencySelected === "" ? "NA" : state.dependencySelected,
        //days: state.days.value,
        //hours: state.hours.value,
        maintaskId: mainTaskId,
        moduleId: moduleId,
        crop: getUser.corp,
        action: "add",
        title: state.taskTitle.value,
        description: state.taskDescription.value,
        targetDate:state.targetDate.value,
        //added_to: state.userSelected,
        //empId: getUser.empId,
      }, {}, false);
      if (response.status === 'true') {
        dispatch(getSubTasks(dispatch, getUser, mainTaskId))
        Alert("success", SUBTASK_ADD);
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  } else {
    Alert("warning", "please give task information")
  }
}
//For Modify SubTask
export async function modifySubTask(state, dispatch, getUser, mainTaskId, handleClose) {
  dispatch(isLoading());
  if (state.taskTitle.value !== "" && state.taskDescription.value !== "") {
    try {
      var response = await API.post("manageSubtasks.php", {
        assignedBy: getUser.empId,
        subtaskid: state.subTaskId.value,
        crop: getUser.corp,
        action: "modify",
        maintaskId: mainTaskId,
        title: state.taskTitle.value,
        description: state.taskDescription.value,
        empId: getUser.empId,
      }, {}, false);
      if (response.status === 'true') {
        // dispatch(getSubTasks(dispatch, getUser, mainTaskId))
        Alert("success", SUBTASK_MODIFY);
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  } else {
    Alert("warning", "please give task title and description")
  }
}
//delete subtask
export async function deleteSubTask(dispatch, getUser, subtaskId, mainTaskId, handleClose, handleModalClose) {
  dispatch(isLoading());
  try { //manageSubtasks.php
    var response = await API.post("tasksDelete.php", {
      "crop": getUser.corp,
      action: 'deletesubtask',
      subtaskId: subtaskId,
      maintaskId: mainTaskId
    }, {}, false);
    // console.log(response)
    if (response.status === 'True') {
      Alert("success", SUBTASK_DELETE)
      handleClose()
    } else if (response.status === "false") {
      handleModalClose()
      Alert("warning", response.message)
    }
  } catch (error) {
    Alert('error', error.message);
    handleClose()
  }
  dispatch(isLoaded());
}

