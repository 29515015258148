/*
FileName: UserModule/TopNav/index.js
Purpose : Top Nav for Getting the Squads List, Status,User profile and Logout
Developers: Naveen Kumar Gade --> GNK
Changes : 01-->GNK
*/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { logoutUser, setCurrentUser } from '../../../Common/Actions';
import { getStatus, setStatus, setToken, setRoleCount, getRoleCount } from '../../../Common/LocalStorage';
import API from '../../../Common/Network/API';
import jwt_decode from "jwt-decode";
import Alert from '../../../Common/Alert';
import store from '../../../Common/Store';
import { isLoading, isLoaded } from '../../../Common/Actions/loading';
import RootLoader from '../../../Common/Loader/RootLoader';

export default function TopNav() {
  const dispatch = useDispatch();
  const getUser = useSelector(state => state.auth)
  const loaderStatus = useSelector(state => state.loading.status)
  const [userStatus, UpdateUserStatus] = useState(getStatus('status'));
  const [userSquad, UpdateUserSquad] = useState(getUser.user.corp);//Show the Squad Name
  const [userSquadList, UpdateUserSquadList] = useState([]) //Show the list of Squad Names Based on username
  const [statusList, updateStatusList] = useState([])
  const [redirect, setRedirect] = useState(false);
  const [squadName, updateSquadName] = useState('')
  const [show, updateShow] = useState(false)
  const onLogout = () => {
    dispatch(logoutUser);
    setRedirect(true)
  }
  useEffect(() => {
    getStatusOfEmployee();
    getSquadsList(getUser.user.userName); // GNK --> 01
    // eslint-disable-next-line
  }, [])

  if (redirect) {
    if ((getRoleCount('roleCount') >= 1)) {
      window.location.reload();
    } else {
      return <Redirect to="/" />
    }
  }


  const getStatusOfEmployee = async () => {
    // console.log(getUser.user.corp)
    try {
      var response = await API.post("spinner.php", {
        "crop": getUser.user.corp,
        "action": 'status',
      }, {}, false);
      // console.log(response)
      if (response.status === 'True') {
        updateStatusList(response.data)
      } else {
        updateStatusList([])
      }
    } catch (error) {
      // Alert('error',error.message)
    }
  }
  //Update the Status of Employee
  const UserStatus = async (status) => {
    // console.log(status)
    // dispatch(isLoading());
    try {
      const response = await API.post("getProfile.php", {
        crop: getUser.user.corp,
        action: 'updatestatus',
        status: status,
        empId: getUser.user.empId
      }, {}, false);
      if (response.status === 'True') {
        setStatus('status', status)
        UpdateUserStatus(status)
      } else {
        UpdateUserStatus(getStatus('status'))
      }
    } catch (error) {
      // Alert('error',error.message)
    }
    // dispatch(isLoaded());
  }
  // GNK --> start 01
  // Squads List Getting Based on UserName
  const getSquadsList = async (username) => {
    try {
      var response = await API.post("squadsList.php", {
        "username": username,
      }, {}, false);
      if (response.status === 'True') {
        UpdateUserSquadList(response.data)
      } else {
        UpdateUserSquadList([])
      }
    } catch (error) {
      // Alert('error',error.message)
    }
  }
  //Squad Change (Shift the squad)
  const SquadChangeStatus = async (squadName) => {
    try {
      const response = await API.post("squadLogin.php", {
        username: getUser.user.userName,
        crop: squadName,
      }, {}, false);
      if (response.status === 'TRUE') {
        setToken('auth', response.jwt) //store the token information  with exp
        const tokenDetails = jwt_decode(response.jwt);
        console.log(tokenDetails.data)
        setRoleCount('roleCount', tokenDetails.data.roleCount)
        dispatch(setCurrentUser(tokenDetails.data)); //store the user information
        setStatus('status', tokenDetails.data.empStatus)
        setRedirect(true)
        UpdateUserSquad(squadName)
      } else {
        UpdateUserSquad(getUser.user.corp)
      }
    } catch (error) {
      // Alert('error',error.message)
      UpdateUserSquad(getUser.user.corp)
    }
    // dispatch(isLoaded());
  }
  // GNK --> End 01
  // Agile Project Validation GNK --->start 03
  const onValidate=()=>{
    var validationRule = /^\S{3,}$/;
    if (squadName === "") {
      Alert('warning', "please give the project name")
    } else if(!validationRule.test(squadName)){
      Alert('warning', " please do not use spaces, '.' and '-' in between words")
    }else if(squadName.length < 4 || squadName.length > 16){
      Alert('warning', " project name should having length 4 to 15 characters")
    }else{
      return true
    }
  }
  // GNK --> End 03
  // Agile Project Creation GNK --->start 02
  const onProjectCreate = async () => {
    store.dispatch(isLoading());
    if (onValidate()) { //Validate the project name
      try {
        const response = await API.post("addSquad.php", {
          username: getUser.user.userName,
          squadName: squadName,
          action: "addSquad",
        }, {
        });
        console.log(response)
        if (response.status === "True") {
          Alert('success', response.message)
          setRedirect(true)
        } else {
          Alert('warning', response.message)
        }
      }
      catch (error) {
        Alert('error', error.message)
      }
    } else {
      // Alert('warning', "please give the project name")
    }
    updateShow(!show)
    store.dispatch(isLoaded());
  }
  // Agile Project Creation GNK --->End 02
  return (
    <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="navbar-brand-wrapper d-flex justify-content-center">
        <div className="navbar-brand-inner-wrapper d-flex justify-content-between align-items-center w-100">
          <a className="navbar-brand brand-logo" href="/"><img src="images/common/agile.png" alt="logo" /></a>
          <p className="navbar-brand brand-logo pt-3" style={{ fontSize: '10px', paddingRight: '0px', }}>Version 1.0.14.01 <br />07-02-2021</p>
          <a className="navbar-brand brand-logo-mini" href="/"><img src="images/common/agile.png" alt="logo" /></a>
          <button className="navbar-toggler align-self-center" type="button" data-toggle="minimize">
            <span className="mdi mdi-sort-variant"></span>
          </button>
        </div>
      </div>
      <div className="navbar-menu-wrapper d-flex  justify-content-end">

        <ul className="navbar-nav navbar-nav-left">

          <li className=" nav-item nav-profile dropdown show">
            <span className="text-black mr-2">Agile Project Name</span>
            {/* eslint-disable-next-line */}
            <a style={{ border: '2px solid grey', borderRadius: '30px' }} className="btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="text-black">{userSquad}</span>
            </a>

            <div className="dropdown-menu dropdown-menu-left navbar-dropdown" aria-labelledby="dropdownMenuLink">
              {/* {!show ? 
            <a className="dropdown-item" onClick={() => updateShow(!show)}>Add Project</a> :
          
          <div><input style={{ borderRadius: '30px', backgroundColor: 'white',marginTop:'12px' ,borderColor:'black',marginRight:'-50px'}}  type="text" className="form-control-sm " id="projectName" name="projectName" placeholder="Agile Project Name*" onChange={(event) => { updateSquadName(event.target.value)}} /> 
          <button style={{ backgroundColor: 'green',border:'0px',padding:'3px',textAlign:"center",color:"white",borderRadius: '10px',fontSize:'12px'}}  onClick={() => onProjectCreate()}>ADD </button>
          </div>} */}
              {userSquadList !== [] ? userSquadList.map((list, index) => {
                return (
                  // eslint-disable-next-line 
                  <a className="dropdown-item" onClick={() => SquadChangeStatus(list.value)}>{list.value}</a>

                )
              }) : null}
            </div>
          </li>
        </ul>
        <div className="form-group row">
        {loaderStatus ? <RootLoader /> :
          show ?
            <div>
              <input style={{ borderRadius: '30px', backgroundColor: 'white', marginTop: '12px', borderColor: 'black' }} type="text" className="form-control-sm " id="projectName" name="projectName" placeholder="Enter Project Name*" onChange={(event) => { updateSquadName(event.target.value) }} />
              <button style={{ backgroundColor: 'green', border: '0px', padding: '5px', textAlign: "center", color: "white", borderRadius: '10px', fontSize: '12px',marginLeft: '5px' }} onClick={() => onProjectCreate()}>ADD </button>
            </div> : null}
          {!show && !loaderStatus ? <button data-toggle="tooltip" data-placement="bottom" title="Create New Agile Project" style={{ backgroundColor: 'transparent', border: '0px', height: '30px', width: '20px', marginLeft: '5px' }} onClick={() => updateShow(!show)} ><img style={{ width: '25px', height: '25px', marginTop: '12px' }} src="images/common/addproject.png" alt="profile" /></button> : null}
          {show && !loaderStatus ? <button style={{ backgroundColor: 'transparent', border: '0px', height: '30px', width: '20px', marginLeft: '5px' }} onClick={() => updateShow(!show)} ><img style={{ width: '25px', height: '25px', marginTop: '12px' }} src="images/common/addremove.png" alt="profile" /></button> : null}

        </div>
        <ul className="navbar-nav navbar-nav-right">



          <li className=" nav-item nav-profile dropdown show">
            <span className="text-black mr-2">Status</span>
            {/* eslint-disable-next-line */}
            <a style={{ border: '2px solid grey', borderRadius: '30px' }} className="btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="text-black">{userStatus}</span>
            </a>

            <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="dropdownMenuLink">
              {statusList !== [] ? statusList.map((list, index) => {
                return (
                  // eslint-disable-next-line 
                  <a className="dropdown-item" onClick={() => UserStatus(list.value)}>{list.value}</a>

                )
              }) : null}
            </div>
          </li>


          <li className="nav-item nav-profile dropdown">{/* eslint-disable-next-line */}
            <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
              <img src="images/common/user1.svg" alt="profile" />
              <span className="nav-profile-name">{getUser.user.fullName}</span>
            </a>
            <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
              {/* eslint-disable-next-line */}
              <Link className="dropdown-item" to={{ pathname: "/userProfile" }}>
                <i className="mdi mdi-account text-primary"></i>
                      Profile
                    </Link>
              <Link className="dropdown-item" to={{ pathname: "/changePasswordUser" }}>
                <i className="mdi mdi-security text-primary"></i>
                      Change Password
                    </Link>
              {/* eslint-disable-next-line */}
              <a className="dropdown-item" onClick={onLogout}>
                <i className="mdi mdi-logout text-primary"></i>
                      Logout
                    </a>
            </div>
          </li>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  )

}