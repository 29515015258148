import React, { Component } from 'react';
import {NavLink } from 'react-router-dom';
import { MODULES, MAINTASKS, MANAGEPROJECTS, ROADBLOCKS, COMPLETED_PROJECTS, EMPLOYEES } from '../../../Common/Headers';
export default class AdminSideBar extends Component {
  render() {

    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">

        <ul className="nav">

          {/* <li className="nav-item">
              <Link className="nav-link" to={{pathname:"/userDashboard"}}>
              <img src="images/common/dashboard.png" alt="logo" style={{width:'30px',height:'30px'}}/>
                <span className="menu-title pl-3">Dashboard</span>
              </Link>
            </li> */}


          <li className="nav-item">
            <NavLink
              activeStyle={{
                fontWeight: "bold",
                color: "#007bff"
              }}
              className="nav-link" to={{ pathname: "/projects" }}>
              <img src="images/common/projects.svg" alt="logo" style={{ width: '30px', height: '30px' }} />
              <span className="menu-title pl-3">{MANAGEPROJECTS}</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink 
              activeStyle={{
                fontWeight: "bold",
                color: "#007bff"
              }}
            className="nav-link" to={{ pathname: "/adminModules" }}>
              <img src="images/common/module.svg" alt="logo" style={{ width: '30px', height: '30px' }} />
              <span className="menu-title pl-3">{MODULES}</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink 
              activeStyle={{
                fontWeight: "bold",
                color: "#007bff"
              }}
            className="nav-link" to={{ pathname: "/adminManageTasks" }}>
              <img src="images/common/managetasks.svg" alt="logo" style={{ width: '30px', height: '30px' }} />
              <span className="menu-title pl-3">{MAINTASKS}</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink 
              activeStyle={{
                fontWeight: "bold",
                color: "#007bff"
              }}
            className="nav-link" to={{ pathname: "/roadBlock" }}>
              <img src="images/common/roadside.svg" alt="logo" style={{ width: '30px', height: '30px' }} />
              <span className="menu-title pl-3">{ROADBLOCKS}</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink 
              activeStyle={{
                fontWeight: "bold",
                color: "#007bff"
              }}
            className="nav-link" to={{ pathname: "/completedProjects" }}>
              <img src="images/common/completed.png" alt="logo" style={{ width: '30px', height: '30px' }} />
              <span className="menu-title pl-3">{COMPLETED_PROJECTS}</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink 
              activeStyle={{
                fontWeight: "bold",
                color: "#007bff"
              }}
            className="nav-link" to={{ pathname: "/employees" }}>
              <img src="images/common/teams.png" alt="logo" style={{ width: '30px', height: '30px' }} />
              <span className="menu-title pl-3">{EMPLOYEES}</span>
            </NavLink>
          </li>


        </ul>
      </nav>

    )
  }
}