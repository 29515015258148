export const PROJECTS = "PROJECTS";
export const MANAGE_TASKS = "MANAGE_TASKS";
export const SUB_TASKS = "SUB_TASKS";
export const ROAD_BLOCKS = "ROAD_BLOCKS";
export const IS_LOADING = 'IS_LOADING'
export const IS_LOADED = 'IS_LOADED'
export const TASKS_COUNT = "TASKS_COUNT";
export const ALLMESSAGES = 'ALLMESSAGES'
export const MAINTASKMESSAGES = 'MAINTASKMESSAGES'
export const ROADBLOCKMESSAGES = 'ROADBLOCKMESSAGES'

export const allMessages = (allMessages) => {
    return {
        type: ALLMESSAGES,
        payload: allMessages
    };
  };

  export const mainTaskMessages = (mainTaskMessages) => {
    return {
        type: MAINTASKMESSAGES,
        payload: mainTaskMessages
    };
  };

  export const roadBlockMessages = (roadBlockMessages) => {
    return {
        type: ROADBLOCKMESSAGES,
        payload: roadBlockMessages
    };
  };

//for tasks count
export const tasksCount = (tasksCount) => {
    return {
        type: TASKS_COUNT,
        payload: tasksCount
    };
};
export const projects = (projects) => {
    return {
        type: PROJECTS,
        payload: projects
    };
};
export const subTasks = (subTasks) => {
    return {
        type: SUB_TASKS,
        payload: subTasks
    };
};
export const manageTasks = (manageTasks) => {
    return {
        type: MANAGE_TASKS,
        payload: manageTasks
    };
};
export const roadBlocks = (roadBlocks) => {
    return {
        type: ROAD_BLOCKS,
        payload: roadBlocks
    };
};
export function isLoading() {
    return { type: IS_LOADING };
}
export function isLoaded() {
    return { type: IS_LOADED }
}