import { involvedEmployees, isLoaded, isLoading, allMessages, todo, doing, done, roadblock, userRoadblock, activeSprint, todoFilter, doingFilter, doneFilter, roadblockFilter } from "./actions";
import Alert from "../../Common/Alert";
import API from "../../Common/Network/API";
import { modules } from "./actions";
import { PROJECT_SUCCESS } from "../../Common/Headers";
import store from "../../Common/Store";

//For ToDo and Doing Subtask List based on task active status
export async function getModules(dispatch, getUser) {
  dispatch(isLoading());
  try {
    // Future Sprints Name list with epic by -->GNK -->01 on version 1.0.4 start

    // var response = await API.post("getModules.php", {
    //   "crop": getUser.corp,
    //   "userType": getUser.role,
    //   "empId": getUser.empId,
    //   "action": "getModules"
    // }, {}, false);
     // Future Sprints Name list with epic by -->GNK -->01 on version 1.0.4 end

     // Future Sprints Name list witout epic by -->SS -->02 on version 1.0.6 start
     var response = await API.post("getUpdateSprint.php", {
      "crop": getUser.corp, 
      "action": "getModules"
    }, {}, false);
      // Future Sprints Name list witout epic by -->SS -->02 on version 1.0.6 end
    if (response.status === 'True') {
      dispatch(modules(response.data))
    }
    else {
      store.dispatch(modules([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}
export async function getActiveSprints(dispatch, getUser) {
  dispatch(isLoading());
  try {
    // Current Active Sprint Name with epic by -->GNK -->01 on version 1.0.4 start
    // var response = await API.post("getSprints.php", {
    //   "crop": getUser.corp,
    //   "userType": getUser.role,
    //   "empId": getUser.empId,
    //   "action": "getActiveSprint"
    // }, {}, false);
    // Current Active Sprint Name with epic by -->GNK -->01 on version 1.0.4 end

    // Current Active Sprint witout epic by -->SS -->01 on version 1.0.6 start
     var response = await API.post("getUpdateSprint.php", {
      "crop": getUser.corp, 
      "action": "get_sprints"
    }, {}, false);
      // Current Active Sprint witout epic by -->SS -->01 on version 1.0.6 end
    if (response.status === 'True') {
      const moduleId = response.data[0].moduleId
      console.log( response.data[0])
      store.dispatch(activeSprint(response.data[0]))
      getToDo(dispatch, getUser, moduleId);
      getRoadBlock(dispatch, getUser, moduleId);
      getDone(dispatch, getUser, moduleId);
      getInvolvedEmployees(dispatch, getUser, moduleId);

    }
    else {
      store.dispatch(todo([]));
      store.dispatch(doing([]));
      store.dispatch(todoFilter([]));
      store.dispatch(doingFilter([]))
      store.dispatch(roadblock([]));
      store.dispatch(roadblockFilter([]));
      store.dispatch(activeSprint([]))
      store.dispatch(done([]));
      store.dispatch(doneFilter([]));
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}
export async function getInvolvedEmployees(dispatch, getUser, moduleId) {
  // console.log(moduleId)
  dispatch(isLoading());
  try {
    var response = await API.post("getModules.php", {
      "crop": getUser.corp,
      "moduleId": moduleId,
      "action": "getInvolvedEmployees"
    }, {}, false);
    if (response.status === 'True') {
      dispatch(involvedEmployees(response.data))
      // console.log(response.data)
    }
    else {
      dispatch(involvedEmployees([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}
//Add New Module
export async function addModule(state, dispatch, getUser, handleClose) {
  dispatch(isLoading());
  if ((state.moduleTitle.value !== "")) {
    try {
      const data = {
        proj_title: state.projectTitle.value,
        proj_desc: state.projectDescription.value,
        empId: getUser.empId, //Async
        action: "add",
        crop: getUser.corp
      };
      const response = await API.post("manageIdeas.php", data, {}, false);
      if (response.status === 'True') {
        dispatch(getModules(dispatch, getUser))
        Alert("success", PROJECT_SUCCESS);
      }
    } catch (error) {
      Alert("error", error.message);
    }
    handleClose()
  } else {
    Alert("warning", "please fill project title and project")
  }
  dispatch(isLoaded());
}

export async function updateChat(sno, dispatch, getUser) {
  const msgId = sno ? sno.map((msgId, index) => {
    return msgId.sno
  }) : null
  dispatch(isLoading());
  try {
    // eslint-disable-next-line
    var response = await API.post("taskChat.php", {
      action: "updateChat",
      corp_code: getUser.corp,
      messagedBy: getUser.empId,
      msgId: msgId ? msgId : " "
    }, {}, false);
  } catch (error) {
    Alert('error', error.message)
  }
  dispatch(isLoaded());
}

export async function getAllTaskMessages(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("mainTaskChat.php", {
      corp_code: getUser.corp,
      action: "getAllTaskMessages",
    }, {}, false);
    // console.log(response.data)
    if (response.status === 'True') {
      dispatch(allMessages(response.data));
      store.dispatch(allMessages(response.data));
    } else {
      dispatch(allMessages([]));
    }
  } catch (error) {
    Alert('error', error.message)
  }
  dispatch(isLoaded());
}

//For ToDo and Doing Subtask List based on task active status
export async function getToDo(dispatch, getUser, moduleId) {
  // console.log(moduleId,getUser.corp)
  dispatch(isLoading());
  try {
    var response = await API.post("getSprints.php", {
      "crop": getUser.corp,
      "moduleId": moduleId,
      "action": "pending"
    }, {}, false);
    var toDoList = [];
    var doingList = [];
    // console.log(response)
    response.data.map((pending) => {
      return (
        (pending.activeStatus === "0") ? toDoList.push(pending) :(pending.activeStatus === "1")? doingList.push(pending):null
      );
    })
    store.dispatch(todo(toDoList));
    store.dispatch(doing(doingList));
    store.dispatch(todoFilter(toDoList));
    store.dispatch(doingFilter(doingList))
  } catch (error) {
    console.log(error)
  }

  dispatch(isLoaded());
}
//For Doing Subtask List
export async function getDoing(dispatch, getUser, moduleId) {
  dispatch(isLoading());
  try {
    var response = await API.post("getSprints.php", {
      "crop": getUser.corp,
      "userType": getUser.role,
      "empId": getUser.empId,
      "moduleId": moduleId,
      "action": "completed"
    }, {}, false);

    store.dispatch(doing(response.data));
    store.dispatch(doingFilter(response.data));

  } catch (error) {
    console.log(error)
  }

  dispatch(isLoaded());
}
//For Completed Tasks List
export async function getDone(dispatch, getUser, moduleId) {
  dispatch(isLoading());
  try {
    var response = await API.post("getSprints.php", {
      "crop": getUser.corp,
      "userType": getUser.role,
      "empId": getUser.empId,
      "moduleId": moduleId,
      "action": "completed"
    }, {}, false);
    // console.log(response)
    if (response.status === 'True') {
      store.dispatch(done(response.data));
      store.dispatch(doneFilter(response.data));
    } else {
      store.dispatch(done([]));
      store.dispatch(doneFilter([]));
    }
  } catch (error) {
    console.log(error)
  }

  dispatch(isLoaded());
}
//For RoadBlock List
export async function getRoadBlock(dispatch, getUser,moduleId) {
  dispatch(isLoading());
  try {
    var response = await API.post("roadBlock.php", {
      "crop": getUser.corp,
      "action": "roadBlock",
      "moduleId": moduleId,
    }, {}, false);
    // console.log(response)
    if (response.message === "No Data Available") {
      store.dispatch(roadblock([]));
      store.dispatch(roadblockFilter([]));
    } else {
      store.dispatch(roadblock(response.data));
      store.dispatch(roadblockFilter(response.data));
    }
  } catch (error) {
    console.log(error)
  }

  dispatch(isLoaded());
}
//RoadBlock List
export async function getUserRoadblocks(dispatch, getUser, subTaskId) {
  dispatch(isLoading());
  try {
    var response = await API.post("userRoadblocks.php", {
      "crop": getUser.corp,
      action: "getting",
      subTaskId: subTaskId
    }, {}, false);
    if (response.status === 'True') {
      dispatch(userRoadblock(response.data))
    }
    else {
      dispatch(userRoadblock([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}
export async function getActive(dispatch, getUser, subtaskId, moduleId,handleClose) {

  dispatch(isLoading());
  try {
    var response = await API.post("manageSubtasks.php", {
      "crop": getUser.corp,
      "subtaskid": subtaskId,
      "empId": getUser.empId,
      "action": "activetask"
    }, {}, false);
    if (response.status === 'true') {
      getCurrentSprintUserStories(dispatch, getUser, moduleId)
      // Alert("success", SUBTASK_ACTIVE);
    } else {
      Alert("warning", response.message)
    }
  } catch (error) {
    console.log(error)
  }

  dispatch(isLoaded());
  handleClose()
}
//Update the Task Status
export async function updateUserStory(dispatch, getUser, userStoryId,sprintId,handleClose) {
  dispatch(isLoading());
  try {
    const response = await API.post("getSubtasks.php", {
      action: "update",
      task_id: userStoryId,
      crop: getUser.corp,
      task_status: 100,
      task_status_desc: "completed",
      task_complete_status: 1,
      empId: getUser.empId,
    }, {}, false);
    if (response.status === "True") {
      getCurrentSprintUserStories(dispatch, getUser,sprintId)
      // Alert('success', "")
    } else {
      Alert('warning', response.message)
    }
  }
  catch (error) {
    Alert('error', error.message)
  }
  dispatch(isLoaded());
  handleClose()
}

//Add Comments for UserStory  Where We Have and Who can Add comments for that
export async function addCommentUserStory(dispatch, getUser, userStoryId, message,cardId) {
  dispatch(isLoading());
  try {
    const response = await API.post("storyComments.php", {
      action: "addStoryComment",
      storyId: userStoryId,
      corp: getUser.corp,
      userId: getUser.empId,
      message: message,
      cardId: cardId
    }, {}, false);
    console.log(response)
    if (response.status === "True") {
      
    } else {
      Alert('warning', response.message)
    }
  }
  catch (error) {
    Alert('error', error.message)
  }
  dispatch(isLoaded());
}
//Get Sprint Cycle User stories if any action performed in sprint cycle only User stories of that sprint can modified and it doesn't goes to active sprint -->GNK --> version 1.0.6.03
export async function getCurrentSprintUserStories(dispatch, getUser,sprintId) {
  dispatch(isLoading());
  try {
      getToDo(dispatch, getUser, sprintId);
      getRoadBlock(dispatch, getUser, sprintId);
      getDone(dispatch, getUser, sprintId);
      getInvolvedEmployees(dispatch, getUser, sprintId);

  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}