/* 
filename:network.js
purpose:Api calls for archive  page
developers:Satya Siddha[S.S]

*/
import {completedSprints,completedUserStories,isLoaded, isLoading, } from "./actions";
import Alert from "../../Common/Alert";
import API from "../../Common/Network/API";


//for getting completed sprints 
export async function getCompletedSprints(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("archive.php", {
      "crop": getUser.corp,
      "action": "getCompletedSprints"
    }, {}, false);
    if (response.status === 'True') {
      dispatch(completedSprints(response.data))
    }
    else {
      dispatch(completedSprints([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}
export async function getCompletedUserStories(dispatch, getUser,sprintId) {
  console.log(sprintId)
  dispatch(isLoading());
  try {
    var response = await API.post("archive.php", {
      "crop": getUser.corp,
      "action": "getCompletedUserStories",
      "sprintId": sprintId,
    }, {}, false);
    if (response.status === 'true') {
      dispatch(completedUserStories(response.data))
    }
    else {
      dispatch(completedUserStories([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}

