import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Moment from 'moment';
import { MAINTASKID, SUBTASK_DESCRIPTION, ASSIGNED_TO, ASSIGNED_BY, SUBTASKTITLE } from '../Headers';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

// user document model and immigration admin model also
export default function MainTaskInfo(props) {
    const classNames = useStyles();

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className="modal-content col-lg-6 p-2" style={{ borderRadius: '10px', overflowWrap: "break-word" }}>
                                <div className="modal-body">
                                    {(props.data.view === "taskInfo") ?
                                        <div >
                                            
                                            <p><b >{MAINTASKID}</b> :{props.data.taskId}</p>
                                            <p><b>Epic Name</b> : {props.data.epicId ==='0' ?'NA':props.data.projectName} </p>
                                            <p><b>Sprint Name</b> : {props.data.moduleId ==='0'? 'NA':props.data.moduleName} </p>

                                            <p><b>User Story Name</b> : {props.data.title} </p>
                                            <p><b>Story Points</b> : {props.data.storyPoints} </p>
                                            <p><b>Acceptance Criteria </b>: {props.data.acceptanceCriteria} </p>
                                            <p ><b>Description</b> : {props.data.description} </p>
                                            <p ><b>Assigned To</b> : {props.data.assignedTo} </p>
                                            <p ><b>Assigned By</b> : {props.data.assignedBy} </p>
                                            <p ><b>Assigned Date</b> : {Moment(props.data.createdDate).format('MM/DD/YYYY hh:mm:ss')} </p>
                                            <p ><b>Task Progress</b> : {props.data.taskProgress}% </p>
                                            {props.data.completedStatus === "completed" ? <p ><b>Completed Date</b> : {Moment(props.data.completedDate).format('MM/DD/YYYY hh:mm:ss')} </p>
                                            
                                            : null}
                                            <p><b>Target Date</b> : {props.data.targetDate}</p>
                                        </div> : (props.data.view === "sprintInfo") ?
                                            <div>
                                                <p><b>Sprint Id</b> : {props.data.id} </p>
                                                {(props.data.epic != null) ?
                                                    <p><b>Epic Name</b> : {props.data.epic} </p> : null}
                                                <p><b>Sprint Name</b> : {props.data.title} </p>
                                                <p><b>Start Date </b>: {Moment(props.data.startDate).format('MM/DD/YYYY hh:mm:ss')} </p>
                                                <p><b>Target Date</b> : {Moment(props.data.targetDate).format('MM/DD/YYYY hh:mm:ss')} </p>
                                                <p><b>Created By</b> : {props.data.createdBy} </p>
                                                <p><b>Created On</b> : {Moment(props.data.created_on).format('MM/DD/YYYY hh:mm:ss')} </p>


                                            </div> :
                                            <div>
                                                <p ><b>UserStory Name</b>:{props.data.taskTitle} </p>
                                                <p ><b>{SUBTASKTITLE}</b>:{props.data.task} </p>

                                                <p ><b>{SUBTASK_DESCRIPTION}</b>: {props.data.subTaskDesc}</p>
                                                <p ><b>{ASSIGNED_TO}</b>: {props.data.assignedTo}</p>
                                                <p ><b>{ASSIGNED_BY}</b>: {props.data.assignedBy}</p>
                                                <p ><b>Target Date</b>: {props.data.targetDate}</p>

                                            </div>
                                    }
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-success" onClick={props.handleModalClose} style={{ borderRadius: '20px' }}>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}