import Alert from "../../Common/Alert";
import API from "../../Common/Network/API";
import { isLoaded, isLoading, setUserInfo } from "./actions";

export async function getProfile(dispatch, getUser) {
    dispatch(isLoading());
    try {
        var response = await API.post("getProfile.php", {
            "crop": getUser.corp,
            "empId": getUser.empId,
            "action": "get"
        }, {}, false);
        if (response.status === 'True') {
            dispatch(
                setUserInfo(
                    response.data[0].empid, //Employee ID
                    response.data[0].username,
                    response.data[0].fullname,
                    response.data[0].mobile,
                    response.data[0].email,
                    response.data[0].designation,
                    response.data[0].team,
                    response.data[0].role,
                    response.data[0].reportingManager,
                    response.data[0].functionalManager,
                    response.data[0].empStatus, 
                )
            );
        }
        else {
        }
    } catch (error) {
        Alert('error', error.message);
    }
    dispatch(isLoaded());
}

export async function updateData(state, dispatch,getUser) {
    dispatch(isLoading());
    if ((state.mobile.value !== "") && !(state.mobile.errorStatus)) {
        try {
            const data = {
                action: 'update',
                crop: getUser.corp,
                number: state.mobile.value,
                empId: state.empId.value
            };
            const response = await API.post("getProfile.php", data, {}, false);
            Alert("success", response.message);
        } catch (error) {
            Alert("error", error.message);
        }
    } else {
        Alert("warning", "please update with valid mobile number")
    }
    dispatch(isLoaded());
}