import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MODULES,  MANAGEPROJECTS, COMPLETED_PROJECTS, USERS_ROADBLOCKS, PENDING_SUBTASKS, EMPLOYEES,ARCHIVE } from '../../../Common/Headers';

export default function SideBar() {
  const getUser = useSelector(state => state.auth)
    // render(){
        return (
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
          <ul className="nav">
            {/* <li className="nav-item">
              <NavLink 
                 activeStyle={{
                  fontWeight: "bold",
                  color: "#007bff"
                }}
              className="nav-link"  to={{ pathname: '/userDashboard'}}>
              <img src="images/common/dashboard.png" alt="logo" style={{width:'30px',height:'30px'}}/>
                <span className="menu-title pl-3">Dashboard</span>
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink 
                 activeStyle={{
                  fontWeight: "bold",
                  color: "#007bff"
                }}
              className="nav-link" to={{ pathname :"/sprints"}}>
              <img src="images/common/module.svg" alt="logo" style={{width:'30px',height:'30px'}}/>
                <span className="menu-title pl-3">Active {MODULES}</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink 
                 activeStyle={{
                  fontWeight: "bold",
                  color: "#007bff"
                }}
              className="nav-link" to={{ pathname: "/manageProjects"}}>
              <img src="images/common/projects.svg" alt="logo" style={{width:'30px',height:'30px'}}/>
                <span className="menu-title pl-3">{MANAGEPROJECTS}</span>
              </NavLink>
            </li>
            
            {/* <li className="nav-item">
              <NavLink 
                 activeStyle={{
                  fontWeight: "bold",
                  color: "#007bff"
                }}
              className="nav-link" to={{pathname : "/managetasks"}}>
              <img src="images/common/managetasks.svg" alt="logo" style={{width:'30px',height:'30px'}}/>
                <span className="menu-title pl-3">{MAINTASKS}</span>
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink 
                 activeStyle={{
                  fontWeight: "bold",
                  color: "#007bff"
                }}
              className="nav-link" to={{pathname : "/backlogs"}}>
              <img src="images/common/sidesubtask.svg" alt="logo" style={{width:'30px',height:'30px'}}/>
                <span className="menu-title pl-3">{PENDING_SUBTASKS}</span>
              </NavLink>
            </li> 
            <li className="nav-item">
              <NavLink 
                 activeStyle={{
                  fontWeight: "bold",
                  color: "#007bff"
                }}
              className="nav-link" to={{pathname : "/archive"}}>
              <img src="images/common/managetasks.svg" alt="logo" style={{width:'30px',height:'30px'}}/>
                <span className="menu-title pl-3">{ARCHIVE}</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink 
                 activeStyle={{
                  fontWeight: "bold",
                  color: "#007bff"
                }}
              className="nav-link" to={{pathname : "/muralBoard"}}>
              <img src="images/common/mural_icon.png" alt="logo" style={{width:'30px',height:'30px'}}/>
                <span className="menu-title pl-3">Mural</span>
              </NavLink>
            </li>
         
            {getUser.user.role !== "Emp" ? <li className="nav-item">
              <NavLink 
                 activeStyle={{
                  fontWeight: "bold",
                  color: "#007bff"
                }}
              className="nav-link" to={{pathname : "/empRoadBlocks"}}>
              <img src="images/common/roadside.svg" alt="logo" style={{width:'30px',height:'30px'}}/>
                <span className="menu-title pl-3">{USERS_ROADBLOCKS}</span>
              </NavLink>
            </li> : null}
            <li className="nav-item">
              <NavLink 
                 activeStyle={{
                  fontWeight: "bold",
                  color: "#007bff"
                }}
              className="nav-link" to={{pathname : "/reports"}}>
              <img src="images/common/reports.svg" alt="logo" style={{width:'30px',height:'30px'}}/>
                <span className="menu-title pl-3">Reports</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink 
                 activeStyle={{
                  fontWeight: "bold",
                  color: "#007bff"
                }}
              className="nav-link" to={{pathname : "/completeProjects"}}>
              <img src="images/common/completed.png" alt="logo" style={{width:'30px',height:'30px'}}/>
                <span className="menu-title pl-3">{COMPLETED_PROJECTS}</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink 
                 activeStyle={{
                  fontWeight: "bold",
                  color: "#007bff"
                }}
              className="nav-link" to={{pathname : "/teams"}}>
              <img src="images/common/teams.png" alt="logo" style={{width:'30px',height:'30px'}}/>
                <span className="menu-title pl-3">{EMPLOYEES}</span>
              </NavLink>
            </li>
        
            
          </ul>
        </nav>
       
       )
    // }
}