import axios from 'axios'
import { getToken } from '../LocalStorage';

export const axiosInstance = axios.create({
    headers: {
        'Accept': 'application/json',
        // 'Access-Control-Allow-Origin': '*',//comment
        'Content-Type': 'application/json;charset=UTF-8',
        'content-type': 'multipart/form-data',
        // eslint-disable-next-line
        'content-type': 'application/x-www-form-urlencoded',
    },
    // baseURL: "http://apilogintest.agile24x7.com", //testing
    //  baseURL: "https:///backend.agile24x7.com", //Production

    // baseURL: "http://192.168.0.24/agile_backend",
    // baseURL: "https://71.40.116.146:93",
    baseURL: "http://preprod.agile24x7.com", //pre production 
    responseType: "json"
});

const requestHandler = (request) => {
    if (request.headers.isTokenRequired) {
        request.headers.common['x-access-token'] = getToken('auth')
    }
    delete request.headers.isTokenRequired
    return request
}

const errorHandler = (err) => {
    if (err.response) {
        switch (err.response.status) {
            case 400:
            case 401:
            case 406:
            case 409:
                throw Error(err.response.data.message);
            case 404:
                throw Error("Page Not Found");
            case 500:
                throw Error("Service Not Available");
            default:
                throw Error("Sorry Something Went Wrong");
        }
    }
    throw err
}
axiosInstance.interceptors.request.use((request) => requestHandler(request))
axiosInstance.interceptors.response.use(
    response => response
    , error => errorHandler(error)
)

