import { SPRINTS,IS_LOADING,IS_LOADED,USER_STORIES } from "./actions";


export const initialState = {
    completedSprints: [],
    completedUserStories:[]
   };

export function tasksReducer(state = initialState, action) {

    switch (action.type) {
        case SPRINTS:
            return {
                ...state,
            completedSprints: action.payload
            };
            case USER_STORIES:
            return {
                ...state,
            completedUserStories: action.payload
            };
            case IS_LOADING:
                return { ...state, isLoading: true };
            case IS_LOADED:
                return {
                    ...state,
                    isLoading: false,
                };
            default:
                return state;
   
    }
}