import React from 'react';
import { BrowserRouter, Switch, Route} from 'react-router-dom';
import Login from './Components/Authentication/Login';
import PublicRoute from './Components/Authorization/PublicRoutes';
import { setCurrentUser } from './Components/Common/Actions';
import jwt_decode from "jwt-decode";
import axios from 'axios';
import { getToken } from './Components/Common/LocalStorage';
import store from './Components/Common/Store';
import UserDashboard from './Components/UserModule/UserDashboard';
import ManageProjects from './Components/UserModule/ManageProjects';
import Modules from './Components/UserModule/Modules';
import ManageTasks from './Components/UserModule/ManageTasks';
import Teams from './Components/UserModule/Team';
import UserProfile from './Components/UserModule/UserProfile';
import UpdateProfile from './Components/UserModule/UserProfile/updateProfile';
import Projects from './Components/AdminModule/ManageProjects';
import { AdminRoute, UserRoute } from './Components/Authorization/PrivateRoutes';
import CompletedProjects from './Components/UserModule/CompletedProjects';
import AddModules from './Components/Common/Modules';
import ViewMainTasks from './Components/Common/TasksModals';
import ViewSubTasks from './Components/Common/SubTaskModals';
import AdminModules from './Components/AdminModule/Modules';
import AdminManageTasks from './Components/AdminModule/ManageTasks';
import Employees from './Components/AdminModule/Employees';
import AdminRoadBlocks from './Components/AdminModule/RoadBlocks';
import RoadBlockList from './Components/AdminModule/RoadBlocks/roadBlockList';
import EmployeeInfo from './Components/Common/EmployeeInfo';
import ProjectInfo from './Components/Common/ProjectInfo';
import UserProjects from './Components/Common/UserTasksInfo/userProjects';
import UserMainTasks from './Components/Common/UserTasksInfo/userMainTasks';
import UserSubTasks from './Components/Common/UserTasksInfo/userSubTasks';
import UserRoadBlocks from './Components/Common/UserTasksInfo/userRoadBlocks';
import ChangePassword from './Components/Authentication/ChangePassword';
import UserPendingSubTasks from './Components/UserModule/UserSubTasks';
import EmployeesRoadBlocks from './Components/UserModule/RoadBlocks';
import UsersRoadBlockList from './Components/UserModule/RoadBlocks/roadBlockList';
import ChangePasswordUser from './Components/UserModule/ChangePassword';
import AdminSettings from './Components/AdminModule/Settings';
import Instruction from './Components/AdminModule/Employees/instruction';
import forgetPassword from './Components/Authentication/ForgotPassword';
import Register from './Components/Authentication/Register';
import SquadRegister from './Components/Authentication/Register/squadRegister';
import UserSprints from './Components/UserModule/Modules/sprints';
import Home from './Components/Authentication/LandingPage';
import ReleaseNote from './Components/Common/Support/ReleaseNote';
import Forget from './Components/Authentication/ForgetPassword';
import Verify from './Components/Authentication/Register/verify';
import ResetPassword from './Components/Authentication/ForgetPassword/resetPassword';
import Archive from './Components/UserModule/Archive';
import CompletedUserStories from './Components/UserModule/Archive/completedUserStories';
import Reports from './Components/UserModule/Reports';
import Kanbanboard from './Components/UserModule/Backlogs/kanbanBoard';
import Mural from './Components/UserModule/MuralBoard/';
import WhiteBoard from './Components/UserModule/MuralBoard/drawingBoard';


if (getToken('auth')) {
  const tokenDetails = jwt_decode(getToken('auth'));
  // console.log(tokenDetails.jwt)
  store.dispatch(setCurrentUser(tokenDetails.data)); //store the user information
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (tokenDetails.exp < currentTime) {
    localStorage.removeItem('auth');
    // Remove auth header for future requests
    delete axios.defaults.headers.common['x-access-token'];
    // Set current user to empty object {} which will set isAuthenticated to false
    store.dispatch(setCurrentUser({}));
    window.location.href = "/";
  }
}

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute path="/" component={Home} exact />
        <Route path="/releasenote" component={ReleaseNote} exact />
        <PublicRoute path="/login" component={Login} exact/>
        <AdminRoute path="/changePassword" component={ChangePassword} exact />
        <PublicRoute path="/register" component={Register} exact />
        <PublicRoute path="/verify" component={Verify} exact />
        <PublicRoute path="/squadRegister" component={SquadRegister} exact />
        <UserRoute path="/userDashboard" component={UserDashboard} exact />
        <PublicRoute path="/forgetPassword" component={forgetPassword} exact />
        <PublicRoute path="/resetPassword" component={ResetPassword} exact />
        <PublicRoute path="/forget" component={Forget} exact />
        <UserRoute path="/sprints" component={UserSprints} exact />

        <UserRoute path="/userProjects" component={UserProjects} exact />
        <UserRoute path="/userMaintasks" component={UserMainTasks} exact />
        <UserRoute path="/usersTodo" component={UserPendingSubTasks} exact />
        <UserRoute path="/userSubtasks" component={UserSubTasks} exact />
        <UserRoute path="/userRoadblocks" component={UserRoadBlocks} exact />
        <UserRoute path="/manageProjects" component={ManageProjects} exact />
        <UserRoute path="/empRoadBlocks" component={EmployeesRoadBlocks} exact />
        <UserRoute path="/empRoadblockLists" component={UsersRoadBlockList} exact />
        <UserRoute path="/userProjectInfo" component={ProjectInfo} exact />
        <UserRoute path="/modules" component={Modules} exact />
        <UserRoute path="/addModules" component={AddModules} exact />
        <UserRoute path="/viewMainTasks" component={ViewMainTasks} exact />
        <UserRoute path="/viewSubTasks" component={ViewSubTasks} exact />
        <UserRoute path="/manageTasks" component={ManageTasks} exact />
        <UserRoute path="/backlogs" component={Kanbanboard} exact />
        <UserRoute path="/muralBoard" component={Mural} exact />
        <UserRoute path="/whiteBoard" component={WhiteBoard} exact />


        <UserRoute path="/archive" component={Archive} exact />
        <UserRoute path="/completedUserStories" component={CompletedUserStories} exact />
        <UserRoute path="/reports" component={Reports} exact />


        <UserRoute path="/teams" component={Teams} exact />
        <UserRoute path="/empTrack" component={EmployeeInfo} exact />
        <UserRoute path="/manageTasks" component={ManageTasks} exact />
        <UserRoute path="/completeProjects" component={CompletedProjects} exact />
        <UserRoute path="/userProfile" component={UserProfile} exact />
        <UserRoute path="/updateProfile" component={UpdateProfile} exact />
        <UserRoute path="/roadblocks" component={RoadBlockList} exact />
        <UserRoute path="/changePasswordUser" component={ChangePasswordUser} exact />
        <AdminRoute path="/projects" component={Projects} exact />
        <AdminRoute path="/projectInfo" component={ProjectInfo} exact />
        <AdminRoute path="/adminModules" component={AdminModules} exact />
        <AdminRoute path="/adminManageTasks" component={AdminManageTasks} exact />
        <AdminRoute path="/roadBlock" component={AdminRoadBlocks} exact />
        <AdminRoute path="/roadblockList" component={RoadBlockList} exact />
        <AdminRoute path="/completedProjects" component={CompletedProjects} exact />
        <AdminRoute path="/employees" component={Employees} exact />
        <AdminRoute path="/empInfo" component={EmployeeInfo} exact />
        <AdminRoute path="/viewModules" component={AddModules} exact />
        <AdminRoute path="/viewManageTasks" component={ViewMainTasks} exact />
        <AdminRoute path="/subTasks" component={ViewSubTasks} exact />
        <AdminRoute path="/settings" component={AdminSettings} exact />
        <UserRoute path="/instruction" component={Instruction} exact />
        
        {/* <Route path="/*" component={"Error404"} exact /> */}
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;